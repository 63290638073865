import clsx from 'clsx';
import React, { FC, useState } from 'react';

import { yiluTracking } from 'utils/index';

import { getLoungeGoogleWalletLink } from 'components/BookingConfirmation/components/SaveQRToWalletButton/utils/wallet.endpoints';

import GoogleWalletDeSVG from './assets/google-de.svg';
import GoogleWalletEnSVG from './assets/google-en.svg';
import './styles.scss';

export type LangType = 'EN' | 'DE';

export type WalletButtonProps = {
  lang: LangType;
  bookingId: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

const ButtonImages = {
  EN: GoogleWalletEnSVG,
  DE: GoogleWalletDeSVG,
};

export type GoogleWalletButtonAnchorProps = {
  lang: LangType;
  onClick: () => void;
  disabled?: boolean;
};
export const GoogleWalletButtonAnchor: FC<GoogleWalletButtonAnchorProps> = ({
  lang,
  onClick,
  disabled,
}) => {
  const WalletButton: string = ButtonImages?.[lang as LangType] || ButtonImages.EN;

  return (
    <div className={clsx('yilu-reservation-bcp__google-wallet-button-wrap')}>
      <button
        type={'button'}
        className={clsx('yilu-reservation-bcp__google-wallet-button')}
        tabIndex={0}
        onClick={() => !disabled && onClick()}
        data-testid="yilu-reservation-bcp__google-wallet-button"
      >
        <img src={WalletButton} alt="Google Wallet Button" />
      </button>
    </div>
  );
};

const GoogleWalletButton = ({ lang = 'EN', bookingId, ...rest }: WalletButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onClick = () => {
    if (isDownloading) return;
    setIsDownloading(true);

    getLoungeGoogleWalletLink({ bookingId, isAmp: true })
      .then((response) => {
        if (response?.data?.saveLink) {
          const link = document.createElement('a');
          link.href = response?.data?.saveLink;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);

          link.click();
          link.remove();

          yiluTracking.sendGAEvent({
            event: 'bcp-google-wallet-add',
            category: `lounge-bcp`,
            label: `lounge-bcp-Gwallet-add`,
          });
        } else {
          throw new Error('No google wallet link found in response.');
        }
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  if (hasError) {
    return null;
  }

  const props = {
    ...rest,
    onClick,
    disabled: isDownloading,
    lang,
  };

  return <GoogleWalletButtonAnchor {...props} />;
};

export default GoogleWalletButton;
