import { BookingIntent, BookingIntentField } from '@yiluhub/network-sdk';
import { CreateBookingIntentResponse as BookingIntentAmp } from '@yiluhub/yilu-amp-types';

/**
 *
 * @param type -
 * @param bookingIntent  -
 */
export function getFieldsDefinitions(
  bookingIntent: BookingIntent | BookingIntentAmp | null,
  isAmpEnabled: boolean,
) {
  if (!bookingIntent) {
    return [];
  }
  const bookingIntentFields = isAmpEnabled
    ? (bookingIntent as BookingIntentAmp).travellerInfo
    : (bookingIntent as BookingIntent).fields;

  return bookingIntentFields as BookingIntentField[];
}
