import { UiUtilities } from '@yiluhub/ui-sdk-react';
import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { yiluEnv } from 'utils';

const yiluConfig = UiUtilities.getYiluConfig();

export enum Languages {
  EN = 'en',
  DE = 'de',
}

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: Languages.EN,
    fallbackLng: Languages.EN,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: () => {
        const yiluEnvVariables = yiluEnv.getVariables();
        return `${yiluEnvVariables.LOCALES_HOST_URL || ''}/locales/{{lng}}/translation.json`;
      },
      crossDomain: true,
      parse: (data) => {
        // specify translations for the SDK
        const translations = JSON.parse(data);
        // optimization to use only updated translation values
        const updatedValuesKeys = Object.keys(translations).filter((key) => {
          const newValue = translations[key];
          const oldValue = (yiluConfig.translations as Record<string, string>)[key];
          return newValue !== oldValue;
        });
        const updatedTranslations = updatedValuesKeys.reduce<Record<string, string>>(
          (updatedTranslations, updatedValueKey) => {
            updatedTranslations[updatedValueKey] = translations[updatedValueKey];
            return updatedTranslations;
          },
          {},
        );
        Object.assign(yiluConfig.translations!, updatedTranslations);
        return translations;
      },
    } as HttpBackendOptions,
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
