import { ElementsSDK } from '@yiluhub/ui-sdk-react';

import { TravellerSelector } from 'modules/lounges/components/LoungeSearchForm/useTravellersSelector';

import { ProductSummaryProps } from '../../../../components/ProductSummary';
import type { OpeningHours } from '../../types';

export type PassengerQuantityFields = {
  adults: number;
  children?: number;
  infants?: number;
};

export type LoungeInteractiveProductSummaryProps = {
  price: number;
  currency: string;
  isLoading?: boolean;
  isEditingDisabled?: boolean;
  isSubmitButtonDisabled?: boolean;
  submitBtnLabel?: string;
  isEditable?: boolean;
  hasFooter?: boolean;
  entryDate: string;
  name: string;
  maxTravellersCount: number;
  travellersFields?: TravellerSelector[];
  travellersCount: PassengerQuantityFields;
  onDateInputChange?: (value: string | null) => void;
  dateInputMaxDate?: string;
  dateInputMinDate?: string;
  onSubmit: (passengerQuantity: PassengerQuantityFields, entryDate: string) => void;
  isEstimatedPriceActive: boolean;
  location: string;
  dateTimeInputProps?: ElementsSDK.DateTimeInputProps;
  travellerSelectorProps?: ElementsSDK.TravellerSelectorProps;
  openingHours?: OpeningHours[];
  openingTimesOptions?: ElementsSDK.SelectOption[];
  termsDetails?: ProductSummaryProps['termsDetails'];
  showBacklinkNavigator?: boolean;
  airportCoordinates?: ElementsSDK.Coordinates;
};
export type OpeningHoursRange = {
  minTime: string;
  maxTime: string;
};
export const DEFAULT_LOUNGE_MIN_TIME = '00:00';
export const DEFAULT_LOUNGE_MAX_TIME = '23:59';
