import { BookingIntent, BookingIntentField, BookingIntentFieldType } from '@yiluhub/network-sdk';
import { CreateBookingIntentResponse, ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { PHONE_NUMBER_NAME, PHONE_NUMBER_PREFIX_NAME, PHONE_NUMBER_SUFFIX_NAME } from './constants';

export const getBookingIntentFieldsPayload = (
  customerFields: Record<string, string | any>,
  bookingIntent: BookingIntent | CreateBookingIntentResponse,
  isAmpEnabled: boolean,
) => {
  const bookingIntentFields =
    (bookingIntent as CreateBookingIntentResponse).travellerInfo ||
    (bookingIntent as BookingIntent).fields;

  const formFieldNames = Object.keys(customerFields);

  let fieldsToUpdate: BookingIntentField[] | ProductConsumerAttributeDto[];
  if (isAmpEnabled) {
    fieldsToUpdate = (bookingIntentFields as ProductConsumerAttributeDto[]).map((field) => {
      let fieldValue = customerFields[field.name];

      if (field.type === BookingIntentFieldType.PHONE_NUMBER) {
        fieldValue =
          !customerFields[field.name].code || !customerFields[field.name].number
            ? null
            : `+${customerFields[field.name].code}${customerFields[field.name].number}`;
      }

      // NOTE: Api expects YYYY-MM-DD date format.
      if (field.type === BookingIntentFieldType.DATE) {
        dayjs.extend(customParseFormat);
        // replace all white spaces and the THH:mm part that might be in the input
        fieldValue = fieldValue ? fieldValue.replace(/\s/g, '').split('T')[0] : null;
        // check if the incoming input has a different format and change it
        if (dayjs(fieldValue, 'DD/MM/YYYY', true).isValid()) {
          fieldValue = dayjs(fieldValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
      }

      return {
        ...field,
        value: fieldValue,
      };
    });
  } else {
    fieldsToUpdate = (bookingIntentFields as unknown as BookingIntentField[])
      .filter(
        (field) =>
          formFieldNames.includes(field.name) ||
          field.name === PHONE_NUMBER_SUFFIX_NAME ||
          field.name === PHONE_NUMBER_PREFIX_NAME ||
          (field.type === BookingIntentFieldType.COUNTRY &&
            (bookingIntent as BookingIntent).serviceProvider === 'PARK_AERO'),
      )
      .map((field) => {
        let fieldValue = customerFields[field.name];

        // NOTE: Taxitender requires prefix + suffix as a seperate fields
        if (field.name === PHONE_NUMBER_PREFIX_NAME) {
          fieldValue = customerFields[PHONE_NUMBER_NAME].code.trim();
        }

        if (field.name === PHONE_NUMBER_SUFFIX_NAME) {
          fieldValue = customerFields[PHONE_NUMBER_NAME].number.trim();
        }

        if (field.type === BookingIntentFieldType.EMAIL) {
          fieldValue = customerFields[field.name].trim();
        }

        if (
          field.type === BookingIntentFieldType.PHONE_NUMBER &&
          (bookingIntent as BookingIntent).serviceProvider !== 'TAXITENDER'
        ) {
          fieldValue =
            !customerFields[field.name].code || !customerFields[field.name].number
              ? null
              : `+${customerFields[field.name].code}${customerFields[field.name].number}`;
        }

        // NOTE: Api expects YYYY-MM-DD date format.
        if (field.type === BookingIntentFieldType.DATE) {
          dayjs.extend(customParseFormat);
          // replace all white spaces and the THH:mm part that might be in the input
          fieldValue = fieldValue ? fieldValue.replace(/\s/g, '').split('T')[0] : null;
          // check if the incoming input has a different format and change it
          if (dayjs(fieldValue, 'DD/MM/YYYY', true).isValid()) {
            fieldValue = dayjs(fieldValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
          }
        }

        return {
          type: field.type,
          requirement: field.requirement,
          name: field.name,
          value: fieldValue,
        };
      });
  }
  // clean up null values before return
  const fieldsToUpdateDefined = (fieldsToUpdate as any).filter(
    (field: BookingIntentField | ProductConsumerAttributeDto) => field.value,
  );
  return fieldsToUpdateDefined;
};
