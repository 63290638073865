import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat } from '@yiluhub/ui-utilities';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import tzlookup from 'tz-lookup';

import { extractAmpProductAttribute } from 'utils/ampUtils';

import { TravellersCount } from 'modules/lounges/components/LoungeSearchForm/useTravellersSelector';
import { isLoungeBookable, isLoungeLocked } from 'modules/lounges/utils/availability';
import {
  AMP_LOUNGE_PRICING_OPTION,
  DEFAULT_LOUNGE_AMENITIES,
} from 'modules/lounges/utils/constants';
import { filterLoungeAmenities } from 'modules/lounges/utils/filterAmenities';

import type { Amenities, OpeningHours } from '../types';

type ProductTypes = SearchItem | undefined | null;

export enum LoungeServiceProvider {
  HOLIDAY_EXTRAS = 'HolidayExtras Lounges',
  LH = 'LH',
  SN = 'SN',
  OS = 'OS',
  LX = 'LX',
}

export const LHGroupServiceProviders = [
  LoungeServiceProvider.LH,
  LoungeServiceProvider.SN,
  LoungeServiceProvider.OS,
  LoungeServiceProvider.LX,
];

const convertDateToLocalDate = (date: string, coordinates: ElementsSDK.Coordinates) => {
  const timezone =
    coordinates && coordinates.latitude && coordinates.longitude
      ? tzlookup(coordinates.latitude, coordinates.longitude)
      : undefined;
  return timezone
    ? dayjs.utc(date).tz(timezone).format(DateFormat.SHORT_DATE_WITH_FULL_TIME_ZULU)
    : date;
};

export type ProductDetails = {
  searchResultId: string;
  optionId: string;
  catalogId: string;
  iata: string;
  price: number;
  currency: string;
  name: string;
  thumbnailUrl: string;
  imageUrls: string[];
  location: string;
  terminal: string;
  airside: boolean;
  passengerCount: number;
  travellersCount: {
    adults: number;
    children?: number;
    infants?: number;
  };
  // date in UTC format as it comes from the BE
  date: string;
  // date converted from UTC to local time
  convertedLocalDate: string;
  amenities: Amenities[];
  openingHours: OpeningHours[];
  openingTime: string;
  closingTime: string;
  defaultPriceId: string;
  economyPremiumPriceId: string;
  economyPremiumPriceAmount: number;
  economyPremiumPriceCurrency: string;
  otherAirlinePriceId: string;
  otherAirlinePriceAmount: number;
  otherAirlinePriceCurrency: string;
  marketingText: string;
  importantInformation: string;
  providerId: LoungeServiceProvider | string;
  providerName?: string;
  termsUrl: string;
  description?: string;
  directions?: string;
  foodAndBeverage?: string;
  accessible?: string;
  entertainmentFacilities?: string;
  childrenInfo?: string;
  dressCode?: string;
  groups?: string;
  checkInTime?: string;
  airportCoordinates?: ElementsSDK.Coordinates;

  isBookable?: boolean;
  isLocked?: boolean;
};

const ERROR_PRICE = 999999;
const DEFAULT_CURRENCY = 'EUR';

function generateOpeningHours(openingTime: string, closingTime: string) {
  const defaultOpeningHours: OpeningHours[] = [
    { day: 'MONDAY', intervals: [] },
    { day: 'TUESDAY', intervals: [] },
    { day: 'WEDNESDAY', intervals: [] },
    { day: 'THURSDAY', intervals: [] },
    { day: 'FRIDAY', intervals: [] },
    { day: 'SATURDAY', intervals: [] },
    { day: 'SUNDAY', intervals: [] },
  ];

  return defaultOpeningHours.map((day) => {
    day.intervals = [{ startTime: openingTime, endTime: closingTime }];

    return day;
  });
}

export const getServiceProvider = (data: ProductTypes) => {
  if (!data) {
    return LoungeServiceProvider.LH;
  }

  const providerId = extractAmpProductAttribute(data, 'provider') as LoungeServiceProvider;
  const serviceProvider = extractAmpProductAttribute(
    data,
    'serviceProvider',
  ) as LoungeServiceProvider;

  return providerId || serviceProvider || LoungeServiceProvider.LH;
};

type LoungeSearchItem = SearchItem & {
  item: {
    adults: number;
    children: number;
    infants: number;
  };
};

export const getProductDetails = (data: ProductTypes): ProductDetails => {
  if (!data) {
    return getDefaultProductDetails();
  }

  const loungeData = data as LoungeSearchItem;

  const extractAmpAttr = (key: string) => {
    try {
      return extractAmpProductAttribute(loungeData, key);
    } catch (error) {
      console.error(`Extracting AMP lounge ${key}`, error);
    }
  };

  const {
    catalogId,
    productDetails: _productDetails,
    startDate,
    quantity,
    defaultPrice,
    priceOptions,
    productId,
  } = loungeData.item;

  const defaultAmenities = [...DEFAULT_LOUNGE_AMENITIES];
  filterLoungeAmenities(productId, defaultAmenities);

  const amenities = JSON.parse(
    extractAmpProductAttribute(loungeData, 'amenities') || '[]',
  ) as Amenities[];

  const allAmenities: Amenities[] = [...defaultAmenities, ...amenities];

  const initialOpeningHours = JSON.parse(
    extractAmpProductAttribute(loungeData, 'openingHours') || '[]',
  ) as OpeningHours[];

  const openingTime = extractAmpAttr('openingTime') || '';
  const closingTime = extractAmpAttr('closingTime') || '';

  const openingHours =
    !initialOpeningHours.length && openingTime && closingTime
      ? generateOpeningHours(openingTime, closingTime)
      : initialOpeningHours;

  const getPriceOption = (name: string) =>
    priceOptions?.find((priceOption) => priceOption.name === name);

  const economyPremium = getPriceOption(AMP_LOUNGE_PRICING_OPTION.ECONOMY_PREMIUM);
  const otherAirline = getPriceOption(AMP_LOUNGE_PRICING_OPTION.OTHER_AIRLINE);

  const productDetails = _productDetails[0];

  const adults = extractAmpAttr('adults');
  const children = extractAmpAttr('children');
  const infants = extractAmpAttr('infants');

  const providerName = extractAmpAttr('providerName');

  const airportLatitude = extractAmpAttr('airportLatitude');
  const airportLongitude = extractAmpAttr('airportLongitude');
  const airportCoordinates = {
    longitude: airportLongitude ? parseInt(airportLongitude) : 0,
    latitude: airportLatitude ? parseInt(airportLatitude) : 0,
  };

  //visible is a LHG specific attribute, it is not available in Holiday Extras
  const isLocked = isLoungeLocked(loungeData);

  //isBookable is a LHG specific attribute, it is not available in Holiday Extras
  const isBookable = isLoungeBookable(loungeData);

  const convertedLocalDate = startDate ? convertDateToLocalDate(startDate, airportCoordinates) : '';

  return {
    searchResultId: loungeData.id,
    optionId: productId,
    catalogId: catalogId,
    providerId: getServiceProvider(loungeData),

    isBookable,
    isLocked,

    price: defaultPrice.price.amount ? defaultPrice.price.amount / 100 : ERROR_PRICE,
    currency: defaultPrice.price.currency,
    name: productDetails?.productDescription.name || '',
    imageUrls: productDetails?.productDescription.imageUrls || [],
    description: productDetails?.productDescription.description || '',
    iata: extractAmpAttr('iata') || '',
    thumbnailUrl: extractAmpAttr('thumbnailUrl') || '',
    location: extractAmpAttr('location') || '',
    terminal: extractAmpAttr('terminal') || '',
    airside: extractAmpAttr('airside') === 'true',
    providerName: providerName || '',

    marketingText: extractAmpAttr('marketingText') || '',
    directions: extractAmpAttr('directions') || '',
    importantInformation: extractAmpAttr('importantInformation') || '',
    passengerCount: quantity,
    travellersCount: {
      adults: adults ? parseInt(adults) : quantity,
      children: children ? parseInt(children) : 0,
      infants: infants ? parseInt(infants) : 0,
    },
    airportCoordinates,
    date: startDate,
    convertedLocalDate,
    amenities: allAmenities,
    openingHours: openingHours,
    openingTime,
    closingTime,

    defaultPriceId: getPriceOption(AMP_LOUNGE_PRICING_OPTION.DEFAULT)?.id || '',
    economyPremiumPriceId: economyPremium?.id || '',
    economyPremiumPriceAmount: economyPremium?.price.amount || ERROR_PRICE,
    economyPremiumPriceCurrency: economyPremium?.price.currency || DEFAULT_CURRENCY,

    otherAirlinePriceId: otherAirline?.id || '',
    otherAirlinePriceAmount: otherAirline?.price.amount || ERROR_PRICE,
    otherAirlinePriceCurrency: otherAirline?.price.currency || DEFAULT_CURRENCY,

    termsUrl: extractAmpAttr('termsUrl') || '',
    accessible: extractAmpAttr('facilitiesDisabled') || '',
    entertainmentFacilities: extractAmpAttr('entertainmentFacilities') || '',
    foodAndBeverage: extractAmpAttr('foodAndBeverage') || '',
    childrenInfo: extractAmpAttr('childrenInfo') || '',
    dressCode: extractAmpAttr('dressCode') || '',
    checkInTime: extractAmpAttr('checkInTime') || '',
    groups: extractAmpAttr('groups') || '',
  };
};

const getDefaultProductDetails = (): ProductDetails => {
  return {
    searchResultId: '',
    optionId: '',
    catalogId: '',
    iata: '',
    price: ERROR_PRICE,
    currency: '',
    name: '',
    thumbnailUrl: '',
    imageUrls: [],
    location: '',
    terminal: '',
    airside: false,
    isBookable: true,
    isLocked: false,
    amenities: [],
    openingHours: [],
    openingTime: '',
    closingTime: '',
    defaultPriceId: '',
    economyPremiumPriceId: '',
    economyPremiumPriceAmount: ERROR_PRICE,
    economyPremiumPriceCurrency: DEFAULT_CURRENCY,
    otherAirlinePriceId: '',
    otherAirlinePriceAmount: ERROR_PRICE,
    otherAirlinePriceCurrency: DEFAULT_CURRENCY,
    marketingText: '',
    importantInformation: '',
    passengerCount: 1,
    date: '',
    convertedLocalDate: '',
    providerId: '',
    termsUrl: '',
    travellersCount: {
      adults: 1,
      children: 0,
      infants: 0,
    },
    airportCoordinates: {
      latitude: 0,
      longitude: 0,
    },
  };
};

//Is service provider Holiday Extra
export function isSpHolidayExtras(serviceProviderType: LoungeServiceProvider | string) {
  return serviceProviderType === LoungeServiceProvider.HOLIDAY_EXTRAS;
}

//Is service provider Lufthansa group
export function isSpLHG(serviceProviderType: LoungeServiceProvider | string) {
  return LHGroupServiceProviders.includes(serviceProviderType as LoungeServiceProvider);
}

export function isSearchItemHolidayExtras(searchItem: SearchItem) {
  const serviceProvider = getServiceProvider(searchItem);

  return isSpHolidayExtras(serviceProvider);
}

export function generateGuestsCountText(travellersCount: TravellersCount, t: TFunction) {
  return (
    travellersCount &&
    Object.entries(travellersCount)
      .filter(([_, value]) => value > 0)
      ?.map(([key, value]) => {
        const quantity = value > 1 ? 'plural' : 'singular';
        const label = t(`TravellerIncrement.${key}.${quantity}`);
        return `${value} ${label}`;
      })
      .join(', ')
  );
}
