import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { yiluEnv } from 'utils/index';
import { getCurrentLanguage } from 'utils/yiluSdk';

import { SearchData } from 'modules/highpass/components/HighPassWidget/types';

export function useHighPassPage() {
  const location = useLocation();

  const [isPageReady, setIsPageReady] = useState(false);

  const envs = yiluEnv.getVariables();
  const HIGHPASS_API_KEY = envs.HIGHPASS_API_KEY;

  useEffect(() => {
    if (isPageReady) {
      return;
    }

    if (!HIGHPASS_API_KEY) {
      console.error('HIGHPASS_API_KEY is not defined');
      return;
    }

    setIsPageReady(true);
  }, [isPageReady, HIGHPASS_API_KEY]);

  const language = useMemo(() => {
    //get search data from the url
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get('hp-locale') || getCurrentLanguage() || 'en';
  }, [location]);

  const populateSearchData = useMemo(() => {
    //get search data from the url
    const searchParams = new URLSearchParams(location.search);

    const departureIata = searchParams.get('departureIata');
    const departureTime = searchParams.get('departureTime');
    const flightNumber = searchParams.get('flightNumber');
    const arrivalIata = searchParams.get('arrivalIata');
    const arrivalTime = searchParams.get('arrivalTime');

    const data = {
      DepartureIata: departureIata,
      DepartureTime: departureTime,
      FlightNumber: flightNumber,
      ArrivalIata: arrivalIata,
      ArrivalTime: arrivalTime,
    };

    let isDataValid = true;

    //validate search data
    for (const [key, value] of Object.entries(data)) {
      if (!value) {
        console.error(`${key} is required`);
        isDataValid = false;
        break;
      }

      //validate IATA codes
      if (key.includes('flightNumber') && (value.length < 3 || value.length > 10)) {
        console.error(`flightNumber is invalid`);
        isDataValid = false;
        break;
      }

      //validate IATA codes
      if (key.includes('Iata') && value.length !== 3) {
        console.error(`${key} must be a 3 letter IATA code`);
        isDataValid = false;
        break;
      }

      //validate date
      if (key.includes('Time') && !Date.parse(value)) {
        console.error(`${key} must be a valid date`);
        isDataValid = false;
        break;
      }
    }

    if (isDataValid) {
      return [data] as SearchData[];
    }

    return undefined;
  }, [location]);
  return { isPageReady, HIGHPASS_API_KEY, language, populateSearchData };
}
