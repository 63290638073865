/**
 * # SBB Booking Confirmation Hooks
 *
 *
 */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import { AirportTransferBookingConfirmationProps } from 'components/BookingConfirmation';

/**
 * Retrieve data for the airport transfer booking confirmation (page).
 */
export const useBookingConfirmation = () => {
  const location = useLocation();

  const bookingConfirmationPageQueryParams = useMemo(() => {
    return paramConverters.getBookingConfirmationPageQueryParams(location);
  }, [location]);

  const bookingConfirmationPageProps = useMemo(() => {
    const bookingConfirmationPageProps: AirportTransferBookingConfirmationProps = {
      bookingId: bookingConfirmationPageQueryParams.bookingId,
      onError(error) {
        throw error;
      },
      isAmpEnabled: true,
      onResponse() {
        yiluTracking.sendGAEvent({
          event: 'page_loaded',
          category: 'airport-transfer-bcp',
          label: 'airport_transfer_bcp_loaded',
        });
      },
    };
    return bookingConfirmationPageProps;
  }, [bookingConfirmationPageQueryParams]);

  return {
    bookingConfirmationPageProps,
  };
};
