import React from 'react';
import { Provider } from 'react-redux';
import { YiluAssetPathOverrides, YiluFeatures, YiluOverrides } from 'types';

import Router from 'router';

import { store } from 'store';

import {
  ComponentOverridesProvider,
  EnterprisePartnerConfigurationContextProvider,
  UserProfileContextProvider,
} from 'context';

import ErrorBoundary from './ErrorBoundary';

type AppProps = {
  features: Partial<YiluFeatures>;
  overrides: Partial<YiluOverrides>;
  defaultPath?: string;
  assetPathOverrides?: Partial<YiluAssetPathOverrides>;
};

const App: React.FC<AppProps> = ({ features, overrides, defaultPath, assetPathOverrides }) => (
  <ErrorBoundary>
    <Provider store={store}>
      <EnterprisePartnerConfigurationContextProvider
        features={features}
        assetPathOverrides={assetPathOverrides}
      >
        <UserProfileContextProvider>
          <ComponentOverridesProvider overrides={overrides}>
            <Router initialPath={defaultPath} />
          </ComponentOverridesProvider>
        </UserProfileContextProvider>
      </EnterprisePartnerConfigurationContextProvider>
    </Provider>
  </ErrorBoundary>
);

export default App;
