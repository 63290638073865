import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { useAmpRidesResultData } from 'modules/rides/utils/useAmpRidesResultData';

import { RidesTravellerInputPageQueryParams } from '../../../utils/query-params';

const useReservationInformationFormProps = (
  currentProduct: SearchItem | null | undefined,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  travellerInputPageQueryParams: RidesTravellerInputPageQueryParams,
) => {
  const navigate = useNavigate();
  const yiluEnv = getVariables();
  const { departureDateTime, travellersCount, searchResultId, priceId, catalogId, providerId } =
    useAmpRidesResultData(currentProduct);

  const travellerInformationFormProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const _props: TravellerInformationFormProps<AMP_VERTICALS.RIDES> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.RIDES,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId,
        locale: travellerInputPageQueryParams.locale as string,
        quantity: 1,
        selectedPriceId: priceId,
        searchResultId,
      },
      createBookingIntentRequestBody: {
        product: {
          optionId: currentProduct.id,
          transferStartTime: departureDateTime,
          numberOfPassengers: travellersCount || 1,
        },
        serviceProvider: providerId,
      },
      onUpdateBookingIntentSuccess(data: any) {
        const paymentInformationPageQueryParams = {
          searchResultID: searchResultId,
          bookingIntentId: data.bookingIntentId,
          originAddressName: travellerInputPageQueryParams.originAddressName,
          destinationAddressName: travellerInputPageQueryParams.destinationAddressName,
          transferTime: travellerInputPageQueryParams.transferTime,
          transferDate: travellerInputPageQueryParams.transferDate,
          itemId: travellerInputPageQueryParams.itemId,
          shoppingCartId: data.shoppingCartId,
        };

        navigate({
          pathname: routes.RIDES_PIP,
          search: getURLSearchQuery(paymentInformationPageQueryParams),
        });
      },
      onInitBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentRequestStateChange() {},
    };
    return _props;
  }, [
    currentProduct,
    reservationFormRef,
    travellerInputPageQueryParams,
    navigate,
    searchResultId,
    catalogId,
    departureDateTime,
    travellersCount,
    providerId,
    yiluEnv.YILU_AMP_STORE_ID,
    priceId,
  ]);

  return travellerInformationFormProps;
};

export default useReservationInformationFormProps;
