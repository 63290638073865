import { BookingIntentDto, ProductDetailsDto, SearchItem } from '@yiluhub/yilu-amp-types';

/**
 * Converts a string from snake_case to camelCase.
 *
 * This function takes a string formatted in snake_case and converts it
 * to camelCase.
 *
 * @param str - The snake_case string to convert.
 * @returns The converted camelCase string.
 */
export const snakeCaseToCamelCase = (str: string): string => {
  return str
    .split('_')
    .map((part, index) => (index === 0 ? part : part.charAt(0).toUpperCase() + part.slice(1)))
    .join('');
};

export const extractAmpProductAttribute = (data: SearchItem, key: string) => {
  const productDetails = data.item.productDetails;
  return extractAttributeFromProductDetails(productDetails, key);
};

export const extractAttributeFromBookingIntent = (
  products: BookingIntentDto,
  attribute: string,
) => {
  const productDetails = products?.items[0].product?.productDetails;
  return extractAttributeFromProductDetails(productDetails, attribute);
};

const extractAttributeFromProductDetails = (productDetails: ProductDetailsDto[], key: string) => {
  const camelCaseKey = snakeCaseToCamelCase(key);
  return productDetails[0]?.productDescription.productAttributes.find(
    (attribute) => attribute.key === key || attribute.key === camelCaseKey,
  )?.value;
};
