if (!window.yilu) {
  window.yilu = {} as any;
}

if (!window.yilu.env) {
  window.yilu.env = {
    YILU_API_TOKEN: '',
    YILU_AMP_BACKEND_URL: '',
    YILU_AMP_LOUNGE_VERTICAL_ID: '',
    YILU_AMP_PARKING_VERTICAL_ID: '',
    YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID: '',
    YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID: '',
    YILU_AMP_RIDES_VERTICAL_ID: '',
    YILU_AMP_CAR_RENTAL_VERTICAL_ID: '',
    YILU_AMP_LOUNGE_CATALOG_ID: '',
    YILU_AMP_MEILI_CATALOG_ID: '',
    YILU_AMP_STORE_ID: '',
  };
}

/**
 *  Get global Yilu ENV variables.
 */
export function getVariables() {
  return window.yilu.env;
}

/**
 *  Set global Yilu ENV variables.
 */
export function setVariables(variables: {
  [key: string]: string | undefined | Record<string, string>;
}) {
  const yiluEnv = getVariables();
  Object.assign(yiluEnv, { ...variables });
}
