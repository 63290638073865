import { BookingState } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

type BookingStateHeaderProps = {
  bookingState?: BookingState;
  headerStateText: {
    loading: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
    failed: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
    completed: {
      title: string | React.ReactNode;
      subtitle: string | React.ReactNode;
    };
  };
};

const BookingStateHeader: React.FC<BookingStateHeaderProps> = ({
  bookingState,
  headerStateText,
}) => {
  let headerProps;

  switch (bookingState) {
    case BookingState.PENDING:
    case BookingState.STARTED:
      headerProps = {
        icon: <ElementsSDK.Icon.Time />,
        title: headerStateText.loading.title,
        subtitle: headerStateText.loading.subtitle,
        isLoading: false,
      };
      break;
    case BookingState.FAILED:
    case BookingState.PAYMENT_FAILED:
    case BookingState.ERRORED:
      headerProps = {
        icon: <ElementsSDK.Icon.Close />,
        title: headerStateText.failed.title,
        subtitle: headerStateText.failed.subtitle,
        isLoading: false,
      };
      break;
    case BookingState.COMPLETION_IN_FUTURE:
    case BookingState.COMPLETED:
      headerProps = {
        icon: <ElementsSDK.Icon.Check />,
        title: headerStateText.completed.title,
        subtitle: headerStateText.completed.subtitle,
        isLoading: false,
      };
      break;
    default:
      headerProps = {
        isLoading: true,
      };
      break;
  }

  return (
    <ElementsSDK.Header
      {...headerProps}
      className={clsx(!!bookingState && `yilu-payment-bcp-header--${bookingState.toLowerCase()}`)}
    />
  );
};

export default BookingStateHeader;
