import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { getProductDetails } from 'modules/lounges/utils/getProductDetails';

import { LoungePaymentInputPageQueryParams } from '../../../utils/query-params';
import { getActualLoungePrice } from './index';

const useLoungePaymentInformationFormProps = (
  currentLounge: SearchItem | undefined,
  paymentFormRef: React.RefObject<HTMLFormElement>,
  loungePaymentInputPageQueryParams: LoungePaymentInputPageQueryParams,
  pricingOption: string,
) => {
  const navigate = useNavigate();
  const paymentInformationFormProps = useMemo(() => {
    if (!currentLounge) {
      return null;
    }

    const { providerId, price, currency } = getProductDetails(currentLounge);

    const actualPrice = getActualLoungePrice(currentLounge, pricingOption);

    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      productDetails: {
        trackingId: 'lounge',
        providerId,
        price,
        actualPrice: actualPrice.price,
        currency,
        providerName: 'Yilu Travel Services GmbH - Lounge Service',
        shoppingCartId: loungePaymentInputPageQueryParams.shoppingCartId,
      },
      paymentDetails: {
        bookingIntentId: loungePaymentInputPageQueryParams.bookingIntentId,
        userId: loungePaymentInputPageQueryParams.userId as string,
        touchPointId: loungePaymentInputPageQueryParams.touchpoint as string,
      },
      isAmpEnabled: true,
      isUsingPaymentIntentApi: false,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        yiluTracking.sendPaymentSuccessfulEvent(loungePaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.LOUNGES_BCP,
          search: paramConverters.getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [currentLounge, loungePaymentInputPageQueryParams, paymentFormRef, navigate, pricingOption]);

  return paymentInformationFormProps;
};

export default useLoungePaymentInformationFormProps;
