import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import classnames from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { usePaymentInputContext } from 'context/PaymentInput';

import { getErrorMessage } from 'components/PaymentInformation/utils/errors';

import ErrorMessage from '../ErrorMessage';
import { useCreatePaymentFormBooking } from '../hooks/useCreatePaymentFormBooking';
import { useCreditCardForm } from '../hooks/useCreditCardForm';
import { CustomErrorCodes, StripePaymentMethodProps } from '../types';
import './styles.scss';

const getCssVariable = (variableName: string): string => {
  const yiluEl = document.querySelector(':root');
  if (yiluEl) {
    return getComputedStyle(yiluEl).getPropertyValue(variableName);
  }

  return '';
};

const getStripeFormStyle = () => ({
  base: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: getCssVariable('--input-field-activated-text-color'),
    //fontFamily: getCssVariable('--font-family'), // TODO: pass font url to Stripe
    '::placeholder': {
      color: getCssVariable('--input-field-inactive-text-color'),
      fontWeight: 'normal',
    },
  },
  invalid: {
    color: getCssVariable('--input-field-error-text-color'),
  },
});

export interface CreditCardFormProps extends StripePaymentMethodProps {
  productDetails: {
    providerId: string;
    price: number;
    actualPrice?: number;
    currency: string;
    providerName?: string;
    locationName?: string;
  };
  isAmpEnabled?: boolean;
  setIsFormValid?: (validityState: boolean) => void;
  setIsCreateBookingLoading: (isLoading: boolean) => void;
}

export const CreditCardForm: FC<CreditCardFormProps> = (props) => {
  const {
    productDetails,
    paymentDetails,
    isUsingPaymentIntentApi = false,
    onPayment,
    setIsFormValid,
    stripe,
    clientSecret,
    isAmpEnabled,
    setIsCreateBookingLoading,
    onError,
  } = props;

  const { t } = useTranslation();
  const { isTermsAccepted, hasFormTriggered, setHasFormTriggered, setPaymentMethodOnSubmit } =
    usePaymentInputContext();

  const [cardHolderName, setCardHolderName] = useState('');
  const cardNumberElement = useRef<HTMLDivElement>(null);
  const cardExpiryElement = useRef<HTMLDivElement>(null);
  const cardCvcElement = useRef<HTMLDivElement>(null);
  const expressElement = useRef<HTMLDivElement>(null);

  const cardNumberInputIdRef = useRef(`card-number-${uuidv4()}`);
  const cardNumberInputId = cardNumberInputIdRef.current;
  const cardExpiryInputIdRef = useRef(`card-expiry-${uuidv4()}`);
  const cardExpiryInputId = cardExpiryInputIdRef.current;
  const cardCvcInputIdRef = useRef(`card-cvc-${uuidv4()}`);
  const cardCvcInputId = cardCvcInputIdRef.current;
  const cardNameElement = useRef<HTMLDivElement>();

  const [isFormLoading, setIsFormLoading] = useState(true);

  const [cardHolderDirty, setCardHolderDirty] = useState(false);
  const [areStripeElementsValid, setAreStripeElementsValid] = useState(false);
  const [isCardHolderElementValid, setIsCardHolderElementValid] = useState(false);

  const { cardBrand, cardNumberStripeElement, errors } = useCreditCardForm({
    stripeFormStyle: getStripeFormStyle(),
    t,
    stripe,
    setIsFormLoading,
    cardNameElement,
    cardNumberElement,
    cardExpiryElement,
    cardCvcElement,
    setAreStripeElementsValid,
    hasFormTriggered,
  });

  const { bookingIntentId, userId } = paymentDetails;

  const { handleFormSubmit } = useCreatePaymentFormBooking({
    stripe,
    cardNumberStripeElement,
    clientSecret: clientSecret ? clientSecret.client_secret : null,
    providerId: productDetails.providerId,
    bookingIntentId,
    paymentProviderId: 'STRIPE',
    userId,
    isTermsAccepted,
    isAmpEnabled,
    setIsLoading: setIsCreateBookingLoading,
    onPaymentResults: onPayment,
    onError,
    setHasFormTriggered,
    setCardHolderDirty,
    isFormValid:
      areStripeElementsValid && cardHolderDirty && isCardHolderElementValid && isTermsAccepted,
    isUsingPaymentIntentApi,
    touchPointId: paymentDetails.touchPointId,
  });

  const checkCardHolderInputValidity = (e: React.ChangeEvent) => {
    const cardHolderInputValue = (e.target as HTMLInputElement).value;
    setCardHolderName(cardHolderInputValue);
    setCardHolderDirty(true);
    setIsCardHolderElementValid(cardHolderInputValue !== '' && cardHolderInputValue !== undefined);
  };

  useEffect(() => {
    if (setIsFormValid) {
      setIsFormValid(
        areStripeElementsValid && cardHolderDirty && isCardHolderElementValid && isTermsAccepted,
      );
    }
  }, [
    areStripeElementsValid,
    cardHolderDirty,
    isCardHolderElementValid,
    isTermsAccepted,
    setIsFormValid,
  ]);

  //set payment method on submit to be called from parent
  useEffect(() => {
    setPaymentMethodOnSubmit(() => () => handleFormSubmit());
  }, [setPaymentMethodOnSubmit, handleFormSubmit]);

  return (
    <div className="yilu-payment-payment-form">
      {isFormLoading && <ElementsSDK.FormLoadingIndicator />}
      <div
        className={classnames({
          'yilu-payment-payment-form__row--loading': isFormLoading,
        })}
      >
        <div
          className={classnames(
            'yilu-payment-payment-form__row',
            'yilu-payment-payment-form__row--column-wrap',
          )}
        >
          <div className="yilu-payment-payment-form__input-wrapper">
            <ElementsSDK.Typography
              variant="l1"
              className="yilu-payment-payment-form__label"
              htmlFor={cardNumberInputId}
            >
              {t('Credit Card Number')}
            </ElementsSDK.Typography>

            <div className="yilu-payment-payment-form__cardno-wrapper">
              <div
                id={cardNumberInputId}
                className={classnames(
                  `yilu-payment-payment-form__input
                  yilu-payment-payment-form__input--with-card-icon`,
                  `${errors && errors.cardNumber && 'yilu-payment-payment-form__input--has-error'}`,
                )}
                ref={cardNumberElement}
              />
              <div className="yilu-payment-payment-form__cardno-icon">
                <ElementsSDK.BrandIcon brand={cardBrand} />
              </div>
            </div>

            {errors && errors.cardNumber && (
              <ErrorMessage errorMessage={getErrorMessage(errors.cardNumber, t)} />
            )}
          </div>

          <div className="yilu-payment-payment-form__input-wrapper">
            <ElementsSDK.Typography
              variant="l1"
              className="yilu-payment-payment-form__label"
              htmlFor="cardHolderName"
            >
              {t('Name on Card')}
            </ElementsSDK.Typography>
            <ElementsSDK.InputField.TextInput
              name="cardHolderName"
              value={cardHolderName}
              placeholder={t('Name on Card Placeholder')}
              className="yilu-payment-payment-form__name-input"
              inputRef={cardNameElement}
              onChange={checkCardHolderInputValidity}
              isErrored={hasFormTriggered && !isCardHolderElementValid}
            />
            {hasFormTriggered && !isCardHolderElementValid && (
              <ErrorMessage errorMessage={getErrorMessage(CustomErrorCodes.INPUT_REQUIRED, t)} />
            )}
          </div>
        </div>

        <div
          className={classnames(
            'yilu-payment-payment-form__row',
            'yilu-payment-payment-form__row--column-wrap',
          )}
        >
          <div
            className={classnames(
              'yilu-payment-payment-form__input-wrapper',
              'yilu-payment-payment-form__input-wrapper--small',
            )}
          >
            <div ref={expressElement}></div>
          </div>
        </div>

        <div
          className={classnames(
            'yilu-payment-payment-form__row',
            'yilu-payment-payment-form__row--column',
          )}
        >
          <div
            className={classnames(
              'yilu-payment-payment-form__input-wrapper',
              'yilu-payment-payment-form__input-wrapper--small',
            )}
          >
            <ElementsSDK.Typography
              variant="l1"
              className="yilu-payment-payment-form__label"
              htmlFor={cardExpiryInputId}
            >
              {t('Expiration Date')}
            </ElementsSDK.Typography>
            <div
              id={cardExpiryInputId}
              className={classnames('yilu-payment-payment-form__input', {
                'yilu-payment-payment-form__input--has-error': errors && errors.cardCvc,
              })}
              ref={cardExpiryElement}
            />
            {errors && errors.cardCvc && (
              <ErrorMessage errorMessage={getErrorMessage(errors.cardCvc, t)} />
            )}
          </div>
          <div
            className={classnames(
              'yilu-payment-payment-form__input-wrapper',
              'yilu-payment-payment-form__input-wrapper--small',
            )}
          >
            <ElementsSDK.Typography
              variant="l1"
              className="yilu-payment-payment-form__label"
              htmlFor={cardCvcInputId}
            >
              <span className="yilu-payment-payment-form__label-text">{t('CVC Code')}</span>
              <ElementsSDK.Tooltip tooltipText={t('CVC Description')}>
                <div className="yilu-payment-payment-form__button">
                  <ElementsSDK.Icon.Help className="yilu-payment-payment-form__icon-help" />
                </div>
              </ElementsSDK.Tooltip>
            </ElementsSDK.Typography>
            <div
              id={cardCvcInputId}
              className={classnames('yilu-payment-payment-form__input', {
                'yilu-payment-payment-form__input--has-error': errors && errors.cardCvc,
              })}
              ref={cardCvcElement}
            />
            {errors && errors.cardCvc && (
              <ErrorMessage errorMessage={getErrorMessage(errors.cardCvc, t)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
