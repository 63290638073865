import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';

import { SummaryLayout } from 'components/Layout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import PageHeading from 'components/PageHeading';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { ParkingProductSummary } from 'modules/parking/components/ParkingProductSummary';

import { PaymentInputContextProvider } from '../../../../context';
import { useParkingPaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { isPageReady, paymentInformationFormProps, parkingProductSummaryProps } =
    useParkingPaymentInputPage();

  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');

  return (
    <div className="parking-pip">
      {isPageReady ? (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar />
                {parkingProductSummaryProps && !isDesktop && (
                  <ParkingProductSummary {...parkingProductSummaryProps} />
                )}
                <PageHeading />
                {paymentInformationFormProps && (
                  <PaymentInformation {...paymentInformationFormProps} />
                )}
              </div>
              {parkingProductSummaryProps && isDesktop && (
                <SummaryLayout>
                  <ParkingProductSummary {...parkingProductSummaryProps} />
                </SummaryLayout>
              )}
            </div>
          </div>
        </PaymentInputContextProvider>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default PaymentInputPage;
