/**
 * # Lounge Booking Confirmation
 *
 *
 */
import { LoungeBooking } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguage } from 'utils/yiluSdk';

import { BookingFAQSupport, BookingStateHeader, Container, Terms } from '../components';
import BookingEmailSupport from '../components/BookingEmailSupport';
import { useGetBooking } from '../hooks/useGetBooking';
import '../styles.scss';
import LoungesBookingInfo from './LoungesBookingInfo';
import { useFaqLink } from './hooks';
import { HolidayExtraServiceProvider, LoungeBookingConfirmationProps } from './types';

export const LoungeBookingConfirmation: React.FC<LoungeBookingConfirmationProps> = ({
  bookingId,
  onError,
  onResponse,
}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
    isAmpEnabled: true,
  });

  const loungeBooking = booking as LoungeBooking | null;

  const isHolidayExtras = loungeBooking?.serviceProvider === HolidayExtraServiceProvider;

  const faqLink = useFaqLink(loungeBooking, currentLanguage);

  return (
    <>
      <BookingStateHeader
        bookingState={loungeBooking ? loungeBooking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.lounges.headerTitle'),
            subtitle: t('bcp.lounges.headerSubtitle'),
          },
        }}
      />
      <Container>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-payment-bcp-formLoadingIndicator__loader" />
        ) : (
          <>
            {loungeBooking && loungeBooking.product.data && (
              <>
                <ElementsSDK.Message
                  className="yilu-payment-bcp-email-confirmation"
                  type={ElementsSDK.MessageType.SUCCESS}
                  icon={ElementsSDK.Icon.Email}
                  content={t('bcp.lounges.emailConfirmation', {
                    email: loungeBooking.product.data.customerDetails.email,
                  })}
                />
                <div className="yilu-payment-bcp-booking-info">
                  <LoungesBookingInfo booking={loungeBooking} />
                </div>
                {loungeBooking.product.data?.termsUrl && (
                  <Terms
                    termsUrl={loungeBooking.product.data.termsUrl}
                    opensNewtab={isHolidayExtras}
                  />
                )}
                {faqLink && !isHolidayExtras && <BookingFAQSupport faqLink={faqLink} />}
                {isHolidayExtras && (
                  <BookingEmailSupport supportEmailAddress="lounge-support@yiluhub.com" />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
export { useDetermineQrWalletVisibility } from 'components/BookingConfirmation/Lounge/hooks';
