import { BookingIntentField } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PersonalInfoCategory } from 'components/TravellerInformationForm/Lounge/PersonalInfoCategory';
import TravelInfo from 'components/TravellerInformationForm/Lounge/TravelInfo';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import CategoryBox from 'components/TravellerInformationForm/components/CategoryBox';
import { ReservationInformationFormContentProps } from 'components/TravellerInformationForm/components/types';

const LHG_PRIMARY_GUEST_FIELDS = [LoungeFieldName.LAST_NAME, LoungeFieldName.EMAIL];

export enum DestinationType {
  INTERNATIONAL = 'international',
  NATIONAL = 'national',
}

export enum AirlineGroup {
  LUFTHANSA = 'Lufthansa',
  OTHER = 'Other',
}

const DEFAULT_DESTINATION_TYPE = DestinationType.INTERNATIONAL;

export const LHGForm = ({
  control,
  trigger,
  watch,
  setValue,
  iata,
  fields,
}: ReservationInformationFormContentProps) => {
  const { t } = useTranslation();
  const isFcle = sessionStorage.getItem('fcle') === 'true';

  const getSessionStorageValues = (key: LoungeFieldName | string) => {
    const savedItem = sessionStorage.getItem(key);
    if (savedItem) {
      setValue(key, JSON.parse(savedItem));
    }
  };

  useEffect(() => {
    Object.entries(LoungeFieldName).forEach(([, value]) => {
      getSessionStorageValues(value);
    });

    if (isFcle) {
      setValue(LoungeFieldName.AIRLINE_GROUP, AirlineGroup.LUFTHANSA);
      setValue(LoungeFieldName.DESTINATION_TYPE, DEFAULT_DESTINATION_TYPE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPersonalInfoCategory = () => {
    const fieldsToRender = (fields as BookingIntentField[]).filter(
      (field: BookingIntentField | ProductConsumerAttributeDto) =>
        LHG_PRIMARY_GUEST_FIELDS.includes(field.name as LoungeFieldName),
    );

    return (
      <PersonalInfoCategory
        fields={fieldsToRender}
        control={control}
        trigger={trigger}
        watch={watch}
        setValue={setValue}
        showDivider={!isFcle}
      />
    );
  };

  if (isFcle) {
    return renderPersonalInfoCategory();
  }

  const isEconomyPremium = watch(LoungeFieldName.IS_ECONOMY_PREMIUM);
  const flightCode = watch(LoungeFieldName.FLIGHT_CODE) || '';
  const destinationType = watch(LoungeFieldName.DESTINATION_TYPE) || DEFAULT_DESTINATION_TYPE;

  return (
    <>
      {renderPersonalInfoCategory()}
      <TravelInfo
        control={control}
        trigger={trigger}
        setValue={setValue}
        watch={watch}
        flightCode={flightCode}
        isEconomyPremium={!!isEconomyPremium}
        iata={iata}
      />
      <CategoryBox category={t('tip.loungeForm.destination.type.label')}>
        <Controller
          name={LoungeFieldName.DESTINATION_TYPE}
          control={control}
          defaultValue={DEFAULT_DESTINATION_TYPE}
          render={({ field: { onChange, name } }) => {
            const onChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
              sessionStorage.setItem(name, JSON.stringify(e.target.value));
              setValue(name, e.target.value);
              onChange(e);
            };

            return (
              <>
                <ElementsSDK.RadioButton
                  id={'destinationType1'}
                  name={name}
                  value={DestinationType.INTERNATIONAL}
                  label={t('tip.loungeForm.destinationTypeInternationalLabel')}
                  checked={destinationType === DestinationType.INTERNATIONAL}
                  onChange={onChangeCallback}
                />
                <ElementsSDK.RadioButton
                  id={'destinationType2'}
                  name={name}
                  value={DestinationType.NATIONAL}
                  label={t('tip.loungeForm.destinationTypeNationalLabel')}
                  checked={destinationType === DestinationType.NATIONAL}
                  onChange={onChangeCallback}
                />
              </>
            );
          }}
        />
      </CategoryBox>
    </>
  );
};
