import { yiluTracking } from 'utils/index';

import { PaymentMethods } from 'context/PaymentInput/context';

type useTrackingProps = {
  paymentMethod: PaymentMethods;
  trackingId: string;
};

const PAYMENT_EVENT_LOAD: Record<PaymentMethods | string, string> = {
  [PaymentMethods.APPLE_PAY]: 'Apay-load',
  [PaymentMethods.GOOGLE_PAY]: 'Gpay-load',
};

export const onLoadTracking = ({ trackingId, paymentMethod }: useTrackingProps) => {
  if (paymentMethod === PaymentMethods.GOOGLE_PAY || paymentMethod === PaymentMethods.APPLE_PAY) {
    yiluTracking.sendGAEvent({
      event: PAYMENT_EVENT_LOAD[paymentMethod],
      category: `${trackingId}-pip`,
      label: `${trackingId}-pip-wallet-load`,
    });
  }
};
export const onPaymentMethodClickTracking = (paymentMethod: PaymentMethods, trackingId: string) => {
  switch (paymentMethod) {
    case PaymentMethods.CREDIT_CARD:
      yiluTracking.sendGAEvent({
        event: 'card-click',
        category: `${trackingId}-pip`,
        label: `${trackingId}-pip-card-click`,
      });
      break;
    case PaymentMethods.GOOGLE_PAY:
      yiluTracking.sendGAEvent({
        event: 'Gpay-click',
        category: `${trackingId}-pip`,
        label: `${trackingId}-pip-wallet-click`,
      });
      break;
    case PaymentMethods.APPLE_PAY:
      yiluTracking.sendGAEvent({
        event: 'Apay-click',
        category: `${trackingId}-pip`,
        label: `${trackingId}-pip-wallet-click`,
      });
      break;
  }
};
