import querystring from 'qs';
import { Location } from 'react-router-dom';

import { getCommonQueryParams } from 'utils/paramConverters';

/**
 * Returns the query parameters for the Experience Search page.
 *
 * @param location - location descriptor
 */
export const getExperienceSearchPageQueryParams = (location: Location = window.location as any) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const experienceSearchPageParams = {
    destinationIata: queryParams.destinationIata,
    startDate: queryParams.startDate || new Date().toISOString(),
    endDate: queryParams.endDate,
    currency: queryParams.currency,
  };

  return {
    ...commonQueryParams,
    ...experienceSearchPageParams,
  };
};

/**
 * Returns the query parameters for the Experience Traveller Input/Information page.
 *
 * @param location - location descriptor
 */
export const getExperienceTravellerInputPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const experienceTravellerInputPageParams = {
    product: {
      ...queryParams.product,
      totalPrice: Number(queryParams.product.totalPrice),
      totalTicketsNumber: Number(queryParams.product.totalTicketsNumber),
      fullDay: queryParams.product.fullDay === 'true',
      ticketDeliveryMethod: Number(queryParams.product.ticketDeliveryMethod),
    },
  };

  return {
    ...commonQueryParams,
    ...experienceTravellerInputPageParams,
  };
};

/**
 * Returns the query parameters for the Experience Payment Input page.
 *
 * @param location - location descriptor
 */
export const getExperiencePaymentInputPageQueryParams = (
  location: Location = window.location as any,
) => {
  const queryParams: any = querystring.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const commonQueryParams = getCommonQueryParams();
  const experiencePaymentnputPageParams = {
    product: {
      ...queryParams.product,
      totalPrice: Number(queryParams.product.totalPrice),
      totalTicketsNumber: Number(queryParams.product.totalTicketsNumber),
      fullDay: queryParams.product.fullDay === 'true',
      ticketDeliveryMethod: Number(queryParams.product.ticketDeliveryMethod),
    },
    reservation: {
      ...queryParams.reservation,
      price: Number(queryParams.reservation.price),
    },
    direction: queryParams.direction,
  };

  return {
    ...commonQueryParams,
    ...experiencePaymentnputPageParams,
  };
};
