import { CreateBookingResponse, CreatePaymentIntentResponse } from '@yiluhub/network-sdk';

export enum StripeErrorCodes {
  EXPIRED_CARD = 'expired_card',
  INCOMPLETE_CARD = 'incomplete_card',
  INCOMPLETE_NUMBER = 'incomplete_number',
  INCORRECT_NUMBER = 'incorrect_number',
  INCOMPLETE_EXPIRY = 'incomplete_expiry',
  INVALID_EXPIRY_MONTH = 'invalid_expiry_month',
  INVALID_EXPIRY_MONTH_PAST = 'invalid_expiry_month_past',
  INVALID_EXPIRY_YEAR = 'invalid_expiry_year',
  INVALID_EXPIRY_YEAR_PAST = 'invalid_expiry_year_past',
  INVALID_NUMBER = 'invalid_number',
  INCORRECT_CVC = 'incorrect_cvc',
  INCOMPLETE_CVC = 'incomplete_cvc',
  INVALID_CVC = 'invalid_cvc',
  API_CONNECTION = 'api_connection_error',
  CARD_DECLINED = 'card_declined',
  PROCESSING_ERROR = 'processing_error',
  GENERIC_DECLINE = 'generic_decline',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  LOST_CARD = 'lost_card',
  STOLEN_CARD = 'stolen_card',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export enum CustomErrorCodes {
  ACCEPT_TERMS = 'accept_terms',
  INPUT_REQUIRED = 'input_required',
}

export type StripePaymentMethodProps = {
  stripe: stripe.Stripe;
  clientSecret?: CreatePaymentIntentResponse;
  productDetails: {
    providerId: string;
  };
  paymentDetails: {
    bookingIntentId: string;
    userId: string;
    touchPointId?: string;
  };
  onPayment: (response: CreateBookingResponse) => void;
  isUsingPaymentIntentApi: boolean;
  onError: (error: Error) => void;
  isAmpEnabled?: boolean;
};
