/**
 * Simpletrip Travel Integration
 *
 * Entry point of rendering the application
 */
import routes from 'router/routes';

import { YILU_OVERRIDE_KEY, renderYiluApp, yiluEnv } from './index.wrapper';

yiluEnv.setVariables({
  YILU_API_PRODUCTION_ENVIRONMENT: process.env.REACT_APP_YILU_API_PRODUCTION_ENVIRONMENT,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  GOOGLE_ANALYTICS_MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,

  YILU_AMP_BACKEND_URL: process.env.REACT_APP_YILU_AMP_BACKEND_URL,
  YILU_AMP_STORE_ID: process.env.REACT_APP_YILU_AMP_STORE_ID,
  YILU_AMP_LOUNGE_VERTICAL_ID: process.env.REACT_APP_YILU_AMP_LOUNGE_VERTICAL_ID,
  YILU_AMP_PARKING_VERTICAL_ID: process.env.REACT_APP_YILU_AMP_PARKING_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID:
    process.env.REACT_APP_YILU_AMP_AIRPORT_TRANSFER_VERTICAL_ID,
  YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID:
    process.env.REACT_APP_YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
  YILU_AMP_RIDES_VERTICAL_ID: process.env.REACT_APP_YILU_AMP_RIDES_VERTICAL_ID,
  YILU_AMP_CAR_RENTAL_VERTICAL_ID: process.env.REACT_APP_YILU_AMP_CAR_RENTAL_VERTICAL_ID,
  YILU_AMP_LOUNGE_CATALOG_ID: process.env.REACT_APP_YILU_AMP_LOUNGE_CATALOG_ID,
  YILU_AMP_MEILI_CATALOG_ID: process.env.REACT_APP_YILU_AMP_MEILI_CATALOG_ID,

  // NOTE: Can be removed after Coras deco
  YILU_BACKEND_URL: process.env.REACT_APP_YILU_BACKEND_URL,
  YILU_API_TOKEN: process.env.REACT_APP_YILU_API_TOKEN,
  CORAS_PARTNER_ID: process.env.REACT_APP_CORAS_PARTNER_ID,
  YILU_SHOW_PARK_AERO_BCP_PRINT_BUTTON: process.env.REACT_APP_YILU_SHOW_PARK_AERO_BCP_PRINT_BUTTON,

  LOCALES_HOST_URL: process.env.REACT_APP_LOCALES_HOST_URL,
  PHONE_COUNTRY_CODE: process.env.REACT_APP_PHONE_COUNTRY_CODE,
  SBB_CURRENCY: process.env.REACT_APP_SBB_CURRENCY,
  YILU_ENTERPRISE_PARTNER: process.env.REACT_APP_YILU_ENTERPRISE_PARTNER,
  ABTEST_ENABLED: process.env.REACT_APP_ABTEST_ENABLED,
  YILU_EMBEDDED_TI: process.env.REACT_APP_YILU_EMBEDDED_TI,
  YILU_MEILI_PTID: process.env.REACT_APP_YILU_MEILI_PTID,
  YILU_LANDING_PAGE_ENABLED: process.env.REACT_APP_YILU_LANDING_PAGE_ENABLED,

  HIGHPASS_API_KEY: process.env.REACT_APP_HIGHPASS_API_KEY,
  HIGHPASS_STYLES: {
    backgroundColor: '#ffffff',
    buttonColor: '#FFAD00',
    inputColor: '#F8F9FD',
    linkColor: '#ac1804',
    headingColor: '#05164D',
    defaultTextColor: '#05164D',
    errorColor: '#FF0000',
    serviceDescriptionColor: '#05164D',
    labelColor: '#333333',
    importantColor: '#05164D',
    headerTextColor: '#05164D',
    descriptionTextColor: '#05164D',
  },
});

renderYiluApp({
  overrides: {
    // example of how it can be used
    [YILU_OVERRIDE_KEY.YILU_HEADER_LOGO]: (originalProps, OriginalComponent) => {
      return <OriginalComponent {...originalProps} />;
    },
  },
  defaultPath: routes.LOUNGES_SEARCH,
  assetPathOverrides: {
    parking: {
      sipImagePaths: ['https://placehold.co/290x180?text=Parking+1'],
    },
    lounges: {
      sipImagePaths: [null, null, 'https://placehold.co/290x180?text=Lounge+3'],
    },
    airportTransfer: {
      sipImagePaths: [null, 'https://placehold.co/290x180?text=Bus/Train+2', null],
    },
    rides: {
      sipImagePaths: [
        'https://placehold.co/290x180?text=Rides+1',
        'https://placehold.co/290x180?text=Rides+2',
        'https://placehold.co/290x180?text=Rides+3',
      ],
    },
  },
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./index.wrapper', renderYiluApp);
}
