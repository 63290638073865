import { GetProductsRequest, GetProductsResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { paramConverters, yiluEnv } from 'utils';
import { sendGAEvent } from 'utils/tracking';
import { getCurrentLanguage } from 'utils/yiluSdk';

import routes from 'router/routes';

import { MAX_PASSENGER_NUMBER, TAB_TYPES } from '../../../types/SearchForm';
import { getSwissTravelSearchInputPageQueryParams } from '../../../utils/query-params';

export const useSwissTravelSearch = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TAB_TYPES.SWISS_TRAVEL_PASS);
  const {
    YILU_AMP_BACKEND_URL,
    YILU_AMP_STORE_ID,
    YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
    SBB_CURRENCY,
  } = yiluEnv.getVariables();
  const durationOptions = [3, 4, 6, 8, 15].map((duration) => {
    return {
      value: `${duration} ${t('sbb.travel_pass_days')}`,
      label: `${duration} ${t('sbb.travel_pass_days')}`,
    };
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classFareOptions = [
    { value: 1, label: 'First Class' },
    { value: 2, label: 'Second Class' },
  ];
  const passengerOptions = Array(MAX_PASSENGER_NUMBER)
    .fill(1)
    .map((value, index) => {
      const passengerOptionValue = value + index;
      return { value: passengerOptionValue.toString(), label: passengerOptionValue.toString() };
    });
  const [startDate, setStartDate] = useState<string | null>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [searchResultId, setSearchResultId] = useState<string | null>();
  const [priceLabel, setPriceLabel] = useState<string | null>();
  const location = useLocation();

  const swissTravelSearchInputPageQueryParams = getSwissTravelSearchInputPageQueryParams(location);

  const [form, setForm] = useState({
    duration: swissTravelSearchInputPageQueryParams?.duration
      ? `${swissTravelSearchInputPageQueryParams?.duration} ${t('sbb.travel_pass_days')}`
      : `${durationOptions[0].value}`,
    classFare: swissTravelSearchInputPageQueryParams?.classfare || classFareOptions[1].value,
    numberOfPassengers: swissTravelSearchInputPageQueryParams?.passengercount || 1,
  });

  const getDuration = (duration: string): number => {
    return parseInt(duration.split(' ')[0]);
  };

  const setDateTimeCallback = useCallback(
    (setter: (d: string | null) => void, dateTime: string | null) => {
      const date = swissTravelSearchInputPageQueryParams?.date || dateTime;
      if (date) {
        setter(date.replace(/T.*/, ''));
      }
    },
    [swissTravelSearchInputPageQueryParams?.date],
  );

  const resetFormError = useCallback(() => {
    setForm({
      duration: `${durationOptions[0].value} ${t('sbb.travel_pass_days')}`,
      classFare: classFareOptions[1].value,
      numberOfPassengers: 1,
    });
    setStartDate(null);
    setFormError(false);
  }, [classFareOptions, t, durationOptions]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (startDate) {
        setIsLoading(true);
        try {
          let price;
          let currency;

          const request: GetProductsRequest['query'] = {
            storeId: YILU_AMP_STORE_ID,
            verticalId: YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
            numberOfTravelers: form.numberOfPassengers,
            startAt: `${startDate}T23:59:59Z`,
            duration: getDuration(form.duration),
            locale: getCurrentLanguage(),
            fareClass: form.classFare,
            currency: SBB_CURRENCY || 'EUR',
          };
          const { data } = await axios.get<GetProductsResponse>(
            `${YILU_AMP_BACKEND_URL}/product/v1/products`,
            {
              params: request,
            },
          );
          setSearchResultId(data.items[0].id);
          currency = data.items[0].item.priceOptions[0].price.currency;
          price = data.items[0].item.priceOptions[0].price.amount / 100;

          if (currency && price) {
            setPriceLabel(t('sbb.priceLabel', { currency, price: Math.round(price) }));
          }
          setIsLoading(false);
        } catch (e) {
          setFormError(true);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [
    form,
    YILU_AMP_BACKEND_URL,
    SBB_CURRENCY,
    YILU_AMP_STORE_ID,
    YILU_AMP_AIRPORT_TRANSFER_PASSES_VERTICAL_ID,
    startDate,
    t,
  ]);

  const swissTravelSearchProps = useMemo(() => {
    const _props = {
      form,
      formError,
      resetFormError,
      startDate,
      currentTab,
      classFareOptions,
      durationOptions,
      passengerOptions,
      priceLabel,
      onFormChange: (form: any) => {
        setForm(form);
      },
      loading,
      setCurrentTab,
      changeStartDate: setDateTimeCallback.bind(null, setStartDate),
      onSearchResultClick: () => {
        const airportTransferProductDetailsPageQueryParams = {
          searchResultID: searchResultId,
        };
        navigate({
          pathname: routes.SWISS_TRAVEL_PASS_TIP,
          search: paramConverters.getURLSearchQuery(airportTransferProductDetailsPageQueryParams),
        });
      },
      onPublicTransportButtonClick: () => {
        sendGAEvent({
          event: 'sbb_link_clicked',
          category: 'sbb-sip',
          label: 'sbb_search_sip_cta_clicked',
        });
        window.open(t('sbb.cta_href'), '_blank');
      },
    };
    return _props;
  }, [
    form,
    formError,
    resetFormError,
    startDate,
    currentTab,
    classFareOptions,
    durationOptions,
    passengerOptions,
    loading,
    setDateTimeCallback,
    searchResultId,
    navigate,
    priceLabel,
    t,
  ]);

  return swissTravelSearchProps;
};

export default useSwissTravelSearch;
