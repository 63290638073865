/**
 * # Rides Booking Confirmation
 *
 *
 */
import { RideBooking, RideBookingData } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStateHeader, Container, ProviderInfo, Terms } from '../components';
import { useGetBooking } from '../hooks/useGetBooking';
import '../styles.scss';
import RidesBookingInfo from './RidesBookingInfo';
import { RidesBookingConfirmationProps } from './types';

export const RidesBookingConfirmation: React.FC<RidesBookingConfirmationProps> = ({
  bookingId,
  onError,
  onResponse,
}) => {
  const { t } = useTranslation();
  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
    isAmpEnabled: true,
  });

  return (
    <>
      <BookingStateHeader
        bookingState={booking ? booking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.headerTitle'),
            subtitle: t('bcp.headerSubtitle'),
          },
        }}
      />
      <Container>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-payment-bcp-formLoadingIndicator__loader" />
        ) : (
          <>
            {booking && booking.product.data && (
              <>
                <ProviderInfo
                  logoUrl={(booking.product.data as RideBookingData).rideDetails.logoUrl}
                  name={booking.serviceProvider}
                  vehicleClass={(booking.product.data as RideBookingData).rideDetails.vehicleClass}
                />
                <ElementsSDK.Divider />
                <div className="yilu-payment-bcp-booking-info">
                  <RidesBookingInfo booking={booking as RideBooking} />
                </div>
                <Terms termsUrl={booking.product.data.termsUrl} />
                <div className="yilu-payment-bcp-contactForm-link">
                  <ElementsSDK.Typography variant="p2">
                    {t('rides.bcp.contact_form.text')}
                    <ElementsSDK.Link href={t(`rides.bcp.contact_form.link`)} target="_blank">
                      {t('rides.bcp.contact_form_link.text')}
                    </ElementsSDK.Link>
                  </ElementsSDK.Typography>
                </div>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
