import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { dateToWeekIndex } from '@yiluhub/ui-utilities';
import { useEffect, useMemo } from 'react';

import {
  DEFAULT_LOUNGE_MAX_TIME,
  DEFAULT_LOUNGE_MIN_TIME,
  OpeningHoursRange,
} from 'modules/lounges/components';
import { TIME_INTERVAL } from 'modules/lounges/components/LoungeInteractiveProductSummary';

import { OpeningHours } from '../../../types';
import { roundUpToNearestMinutes } from '../../../utils/dateUtils';

function getOpeningHoursOptions(openingHours: OpeningHours[], dayIndex: number) {
  const range: OpeningHoursRange[] = [];
  const todayOpeningHours = openingHours[dayIndex]?.intervals;
  const todayOpeningTime = todayOpeningHours[0]?.startTime;
  const todayClosingTime = todayOpeningHours.at(-1)?.endTime;

  if (!todayOpeningTime || !todayOpeningHours || !todayClosingTime) {
    console.error('Opening hours are not available');
    return [];
  }

  const roundedTodayOpeningTime = roundUpToNearestMinutes(todayOpeningTime, TIME_INTERVAL);

  const todayOpeningHour = roundedTodayOpeningTime.split(':').map(Number)?.[0];
  const todayClosingHour = todayClosingTime.split(':').map(Number)?.[0];

  const previousDay = dayIndex - 1 < 0 ? openingHours.at(-1) : openingHours?.[dayIndex - 1];

  // get the last interval of the previous day
  const yesterdayLastInterval = previousDay?.intervals?.[previousDay.intervals.length - 1];

  if (yesterdayLastInterval) {
    const [yesterdayClosingHour] = yesterdayLastInterval.endTime.split(':').map(Number);

    // if the last interval of the previous day is open after midnight
    if (yesterdayClosingHour > 0 && yesterdayClosingHour < todayOpeningHour) {
      range.push({
        minTime: DEFAULT_LOUNGE_MIN_TIME,
        maxTime: yesterdayLastInterval.endTime,
      });
    }
  }

  range.push({
    minTime: roundedTodayOpeningTime || DEFAULT_LOUNGE_MIN_TIME,
    maxTime: todayOpeningHour < todayClosingHour ? todayClosingTime : DEFAULT_LOUNGE_MAX_TIME,
  });

  return ElementsSDK.generateRangeTimeOptions(range, TIME_INTERVAL);
}

export function useOpeningTimesOptions({
  isEditable,
  openingHours,
  entryDate,
  setIsTimeUnavailable,
}: {
  isEditable: boolean;
  openingHours: OpeningHours[];
  entryDate: string | undefined;
  setIsTimeUnavailable: (value: boolean) => void;
}) {
  const openingTimesOptions = useMemo(() => {
    // if the lounge is not editable, we don't need to show the opening times options
    if (!isEditable) {
      return [];
    }

    if (openingHours?.length && entryDate) {
      const searchedDateOfWeek = dateToWeekIndex(entryDate);
      const options = getOpeningHoursOptions(openingHours, searchedDateOfWeek);

      if (options?.length) {
        return options;
      }
    }

    return ElementsSDK.generateTimeOptions(
      DEFAULT_LOUNGE_MIN_TIME,
      DEFAULT_LOUNGE_MAX_TIME,
      TIME_INTERVAL,
    );
  }, [isEditable, openingHours, entryDate]);

  useEffect(() => {
    if (isEditable && entryDate && openingTimesOptions?.length) {
      const time = entryDate.split('T')[1];

      if (time) {
        const isTimeUnavailable = openingTimesOptions.some((option) => {
          return option.value === time && option.disabled;
        });

        setIsTimeUnavailable(isTimeUnavailable);
      }
    }
  }, [setIsTimeUnavailable, isEditable, entryDate, openingTimesOptions]);

  return openingTimesOptions;
}
