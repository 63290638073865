import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { yiluTracking } from 'utils';

import { LoungeInteractiveProductSummaryProps } from 'modules/lounges/components';
import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { MAX_LOUNGE_GUESTS } from '../../../utils/constants';
import { getActualLoungePrice } from './index';

const useLoungeProductSummaryProps = (
  currentLounge: SearchItem | undefined,
  paymentFormRef: React.RefObject<HTMLElement>,
  pricingOption: string,
) => {
  const { t } = useTranslation();

  const loungeProductSummaryProps = useMemo(() => {
    if (!currentLounge) {
      return null;
    }

    const {
      name,
      price,
      currency,
      convertedLocalDate,
      travellersCount,
      location,
      providerId,
      termsUrl,
      airportCoordinates,
    } = getProductDetails(currentLounge);

    let providerName = 'Yilu Travel Services GmbH - Lounge Service';

    if (isSpHolidayExtras(providerId)) {
      providerName = 'Holiday Extras';
    }

    const _props: LoungeInteractiveProductSummaryProps = {
      price,
      currency,
      name,
      entryDate: convertedLocalDate,
      travellersCount,
      isEstimatedPriceActive: false,
      location,
      isEditable: false,
      maxTravellersCount: MAX_LOUNGE_GUESTS,
      airportCoordinates,
      termsDetails: {
        termsUrl,
        productDetails: {
          providerId,
          providerName,
        },
      },
      onSubmit: () => {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );

          yiluTracking.sendGAEvent({
            event: 'pip_cta_clicked',
            category: 'lounge-pip',
            label: 'lounge_pip_cta_clicked',
          });
        }
      },
      isSubmitButtonDisabled: false,
      submitBtnLabel: t('Pay now'),
    };

    const isHolidayExtra = isSpHolidayExtras(providerId);

    if (!isHolidayExtra) {
      const actualPrice = getActualLoungePrice(currentLounge, pricingOption);

      if (actualPrice?.price) {
        _props.price = actualPrice.price;
      }

      if (actualPrice?.currency) {
        _props.currency = actualPrice.currency;
      }
    }

    return _props;
  }, [currentLounge, paymentFormRef, t, pricingOption]);

  return loungeProductSummaryProps;
};

export default useLoungeProductSummaryProps;
