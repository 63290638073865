/**
 * # Current Product Feature (Selectors)
 *
 *
 */
import { createSelector } from 'reselect';

import { RootState } from 'store/types';

export const reducerName = 'currentProduct';

/**
 * Get current product related data
 *
 * @param state - root application state
 */
const selectCurrentProductState = (state: RootState) => state[reducerName];

/**
 * Get the current product
 */
export const selectCurrentProduct = createSelector(
  [selectCurrentProductState],
  (currentProductState) => currentProductState.data,
);

/**
 * Get the potential error of the current product
 */
export const selectCurrentProductError = createSelector(
  [selectCurrentProductState],
  (currentProductState) => currentProductState.error,
);

/**
 * Get the loading status of the current product
 */
export const selectIsCurrentProductLoading = createSelector(
  [selectCurrentProductState],
  (currentProduct) => currentProduct.loading,
);
