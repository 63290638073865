import { CreateBookingRequest, CreateBookingResponse } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback } from 'react';

import { getVariables } from 'utils/yiluEnv';
import { getRESTClient } from 'utils/yiluSdk';

type useCreateBookingParamsType = {
  providerId: string;
  bookingIntentId: string;
  paymentProviderId: string;
  userId: string;
  onPaymentResults: (response: CreateBookingResponse) => void;
  isUsingPaymentIntentApi: boolean;
  isAmpEnabled?: boolean;
  touchPointId?: string;
};

export const useCreateBooking = ({
  providerId,
  bookingIntentId,
  paymentProviderId,
  userId,
  onPaymentResults,
  isUsingPaymentIntentApi = false,
  isAmpEnabled,
  touchPointId,
}: useCreateBookingParamsType) => {
  const createBooking = useCallback(
    async (stripeSetup: stripe.PaymentIntentResponse | stripe.SetupIntentResponse) => {
      const yiluEnv = getVariables();
      const intent = isUsingPaymentIntentApi
        ? (stripeSetup as stripe.PaymentIntentResponse).paymentIntent
        : (stripeSetup as stripe.SetupIntentResponse).setupIntent;

      if (stripeSetup && !stripeSetup.error && intent && intent.payment_method) {
        if (isAmpEnabled) {
          try {
            const ampBookingRequest: CreateBookingRequest['requestBody'] = {
              userId,
              bookingIntentId,
              paymentDetails: {
                tokenId: intent.payment_method,
                provider: paymentProviderId,
              },
              touchPoint: touchPointId,
            };
            const response = await axios.post<CreateBookingResponse>(
              `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/bookings`,
              ampBookingRequest,
            );

            if (!(response.status === 201 && response.data.id)) {
              throw new Error('Missing payment response id');
            }

            onPaymentResults({
              id: response.data.id,
            });
          } catch (error) {
            throw new Error('Error creating booking');
          }
        } else {
          const client = getRESTClient();

          const createBookingResponse = await client.createBooking({
            serviceProvider: providerId,
            bookingIntentId,
            userId,
            paymentDetails: {
              tokenId: intent.payment_method,
              provider: paymentProviderId,
            },
          });

          if (!createBookingResponse.body) {
            throw new Error('Missing payment response body');
          }

          onPaymentResults({
            id: createBookingResponse.body.id,
          });
        }
      } else if (stripeSetup.error) {
        throw new Error(
          stripeSetup.error.decline_code || stripeSetup.error.code || stripeSetup.error.type,
        );
      } else {
        throw new Error('Unknown error while creating a booking!');
      }
    },
    [
      touchPointId,
      isUsingPaymentIntentApi,
      providerId,
      bookingIntentId,
      userId,
      paymentProviderId,
      onPaymentResults,
      isAmpEnabled,
    ],
  );

  return { createBooking };
};
