import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { paramConverters } from 'utils';
import {
  isPaymentInformationPage,
  isProductDetailsPage,
  isTravellerInformationPage,
} from 'utils/headerUtils';
import { getProductDetailPageQueryParams, getProductFromUrl } from 'utils/paramConverters';

import { PRODUCT_ROUTE } from 'router/routes';

import './styles.scss';

type ProgressBarProps = {
  detailsStepLabel?: string;
  personalInfoStepLabel?: string;
  paymentStepLabel?: string;
};

function useProgressClickEvents(product: string) {
  const navigate = useNavigate();
  const location = useLocation();

  if (product === PRODUCT_ROUTE.TICKETS) {
    return {
      pdpOnClick: undefined,
      tipOnClick: undefined,
    };
  }

  const generateUrlForDetail = (product: string) => {
    switch (product) {
      case PRODUCT_ROUTE.SWISS_TRAVEL_PASS:
        return '/search';
      case PRODUCT_ROUTE.RIDES:
        return '/results';
      default:
        return '/product';
    }
  };

  const pdpOnClick = () => {
    navigate({
      pathname: `/${product}${generateUrlForDetail(product)}`,
      search: paramConverters.getURLSearchQuery(getProductDetailPageQueryParams(location)),
    });
    if (product === PRODUCT_ROUTE.RIDES) {
      // we would need a reload because we have session state and sometimes it doesn't
      // get updated.
      navigate(0);
    }
  };

  const tipOnClick = () => {
    navigate({
      pathname: `/${product}/booking/passengers`,
      search: paramConverters.getURLSearchQuery(getProductDetailPageQueryParams(location)),
    });
  };
  return { pdpOnClick, tipOnClick };
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  detailsStepLabel,
  personalInfoStepLabel,
  paymentStepLabel,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const product = getProductFromUrl(pathname)!;

  const detailsStep = detailsStepLabel || (t('header.progressStep.detail') as string);
  const personalInfoStep =
    personalInfoStepLabel || (t('header.progressStep.personalInfo') as string);
  const paymentStep = paymentStepLabel || (t('header.progressStep.payment') as string);
  const { pdpOnClick, tipOnClick } = useProgressClickEvents(product);

  const getPIPStepState = () => {
    if (isTravellerInformationPage(pathname)) {
      return ElementsSDK.StepStates.current;
    } else {
      return isProductDetailsPage(pathname)
        ? ElementsSDK.StepStates.inactive
        : ElementsSDK.StepStates.active;
    }
  };

  return (
    <div
      className={clsx('header-progressBar', {
        'long-first': t('header.progressStep.detail')?.length > 7,
      })}
    >
      <ElementsSDK.Progress>
        <ElementsSDK.ProgressStep
          stepState={
            isProductDetailsPage(pathname)
              ? ElementsSDK.StepStates.current
              : ElementsSDK.StepStates.active
          }
          title={detailsStep}
          index={1}
          as="a"
          onClick={pdpOnClick}
        />
        <ElementsSDK.ProgressStep
          stepState={getPIPStepState()}
          title={personalInfoStep}
          index={2}
          as="a"
          onClick={tipOnClick}
        />
        <ElementsSDK.ProgressStep
          stepState={
            isPaymentInformationPage(pathname)
              ? ElementsSDK.StepStates.current
              : ElementsSDK.StepStates.inactive
          }
          title={paymentStep}
          index={3}
        />
        <ElementsSDK.ProgressStep
          stepState={ElementsSDK.StepStates.inactive}
          title={t('header.progressStep.confirmation')}
          isCheck
        />
      </ElementsSDK.Progress>
    </div>
  );
};

export default ProgressBar;
