import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import PageHeading from 'components/PageHeading';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { RideInteractiveProductSummary } from 'modules/rides/components';

import { PaymentInputContextProvider } from '../../../../context';
import { usePaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { isPageReady, paymentInformationFormProps, rideProductInteractiveSummaryProps } =
    usePaymentInputPage();
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');
  const { t } = useTranslation();

  return (
    <div className="rides-pip">
      {isPageReady ? (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar detailsStepLabel={t('rides.detailsStepLabel')} />
                <PageHeading />
                {!isDesktop && rideProductInteractiveSummaryProps && (
                  <div className="content--mobile">
                    <RideInteractiveProductSummary {...rideProductInteractiveSummaryProps} />
                  </div>
                )}
                {paymentInformationFormProps && (
                  <PaymentInformation {...paymentInformationFormProps} />
                )}
              </div>
              {isDesktop && rideProductInteractiveSummaryProps && (
                <div className="content-interactive-summary--desktop">
                  <RideInteractiveProductSummary {...rideProductInteractiveSummaryProps} />
                </div>
              )}
            </div>
          </div>
        </PaymentInputContextProvider>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default PaymentInputPage;
