import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type TermsProps = {
  termsUrl: string;
  className?: string;
  opensNewtab?: boolean;
};

const Terms: React.FC<TermsProps> = ({ termsUrl, className, opensNewtab }) => {
  const { t } = useTranslation();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const toggleTermsOpenState = useCallback(
    () => setIsTermsOpen(!isTermsOpen),
    [isTermsOpen, setIsTermsOpen],
  );

  const componentClass = `yilu-payment-bcp-terms`;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsTermsOpen(!isTermsOpen);
      }
    },
    [isTermsOpen, setIsTermsOpen],
  );

  const openNewTab = () => {
    window.open(termsUrl, '_blank');
  };

  useEffect(() => {
    const scrollToComponent = (): void => {
      document.getElementsByClassName(componentClass)?.[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    };
    isTermsOpen && scrollToComponent();
  }, [isTermsOpen, componentClass]);

  return (
    <ElementsSDK.Link
      className={clsx(
        {
          [componentClass]: true,
          [`${componentClass}--open`]: isTermsOpen,
        },
        className,
      )}
      onClick={opensNewtab ? openNewTab : toggleTermsOpenState}
      onKeyDown={onKeyDown as any}
      tabIndex={0}
    >
      <section className={`${componentClass}__header`}>
        <aside className={`${componentClass}__label-group`}>
          <ElementsSDK.Icon.Info />
          <span className={`${componentClass}__label`}>{t('Terms & Conditions')}</span>
        </aside>
        <ElementsSDK.Icon.ArrowRightChevron
          className={clsx({
            [`${componentClass}__icon`]: true,
            [`${componentClass}__icon-chevron`]: true,
            [`${componentClass}__icon--rotate`]: isTermsOpen,
          })}
        />
      </section>
      <section
        className={clsx({
          [`${componentClass}__content`]: true,
          [`${componentClass}__content--open`]: isTermsOpen,
        })}
      >
        <iframe
          src={termsUrl}
          className={`${componentClass}__iframe`}
          title="Terms"
          referrerPolicy="origin"
        />
      </section>
    </ElementsSDK.Link>
  );
};

export default Terms;
