/**
 * # Experience Booking Confirmation
 *
 *
 */
import { ExperienceBooking } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStateHeader, Container, EmailReminder, ProviderInfo, Terms } from '../components';
import { useGetBooking } from '../hooks/useGetBooking';
import '../styles.scss';
import ExperienceBookingInfo from './ExperienceBookingInfo';
import { ExperienceBookingConfirmationProps } from './types';

export const ExperienceBookingConfirmation: React.FC<ExperienceBookingConfirmationProps> = ({
  bookingId,
  onError,
  onResponse,
}) => {
  const { t } = useTranslation();

  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
    isAmpEnabled: false,
  });

  const experienceBooking = booking as ExperienceBooking | null;

  return (
    <>
      <BookingStateHeader
        bookingState={experienceBooking ? experienceBooking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.headerTitle'),
            subtitle: t('bcp.headerExperienceSubtitle'),
          },
        }}
      />
      <Container>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-payment-bcp-formLoadingIndicator__loader" />
        ) : (
          <>
            {experienceBooking && experienceBooking.product.data && (
              <>
                <ProviderInfo
                  logoUrl={experienceBooking.product.data.eventDetails.logoUrl}
                  name={experienceBooking.serviceProvider}
                />
                <ElementsSDK.Divider />
                <div className="yilu-payment-bcp-booking-info">
                  <ExperienceBookingInfo booking={experienceBooking} />
                </div>
                <EmailReminder withDivider />
                <Terms opensNewtab termsUrl={experienceBooking.product.data.termsUrl} />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
