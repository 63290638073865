import { BookingIntentFieldType } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import Header from '../components/Header';
import { ReservationInformationFormContentProps } from '../components/types';
import { getValidationRules, isLoyaltyIdValid } from '../utils/fieldValidation';
import './styles.scss';

const ExperienceReservationInformationForm: React.FC<ReservationInformationFormContentProps> = ({
  fields,
  control,
  trigger,
  setValue,
}) => {
  const { t } = useTranslation();
  const envVariables = yiluEnv.getVariables();

  const getAndSetSessionStorageValueInitially = (key: string): string => {
    const savedItem = sessionStorage.getItem(key);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && parsedSavedItem !== '') {
      setValue(key, parsedSavedItem);
    }
    return parsedSavedItem;
  };

  return (
    <div className="yilu-ExperienceReservationForm">
      <Header heading={t('heading')} className="yilu-ExperienceReservationForm__header" />
      {fields.map((field) => {
        if (field.type === BookingIntentFieldType.PHONE_NUMBER) {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.PhoneInputField
                    dataTestId="customer-phone"
                    label={t(field.name)}
                    className="yilu-ExperienceReservationForm__field"
                    onChange={(value) => {
                      onChange(value);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    phoneRef={ref}
                    errorMessage={error?.message && t(error.message)}
                    defaultCountry={envVariables?.PHONE_COUNTRY_CODE || 'DE'}
                  />
                );
              }}
            />
          );
        } else if (field.type === BookingIntentFieldType.EMAIL) {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.Select
                    isEmailDropdown
                    showDropdownIcon={false}
                    dataTestId="experience-reservation-email"
                    label={t(field.name)}
                    name={name}
                    className="yilu-ExperienceReservationForm__field"
                    onInputChange={(e) => {
                      onChange(e);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    onSelect={(selectedValue: unknown) => {
                      onChange(selectedValue);
                      isTouched && trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.name === 'loyaltyId') {
          return (
            <Controller
              key={field.name}
              rules={{ validate: isLoyaltyIdValid }}
              name={field.name}
              control={control}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.TextInput
                    value={value || getAndSetSessionStorageValueInitially(name)}
                    label={t('tip.reservation.form.field.loyaltyId')}
                    placeholder={t('tip.reservation.form.field.loyaltyId.placeholder')}
                    name={name}
                    className="yilu-ExperienceReservationForm__field"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      isTouched && trigger(name);
                      setValue(e.target.value);
                      sessionStorage.setItem(name, JSON.stringify(e.target.value));
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        }

        return (
          <Controller
            key={field.name}
            name={field.name}
            control={control}
            rules={getValidationRules(field)}
            render={({
              field: { onChange, onBlur, name, ref },
              fieldState: { isTouched, error },
            }) => {
              return (
                <ElementsSDK.InputField.TextInput
                  label={t(field.name)}
                  name={name}
                  className="yilu-ExperienceReservationForm__field"
                  onChange={(e) => {
                    onChange(e);
                    isTouched && trigger(name);
                  }}
                  onBlur={() => {
                    onBlur();
                    trigger(name);
                  }}
                  inputRef={ref}
                  errorMessage={error?.message && t(error.message)}
                />
              );
            }}
          />
        );
      })}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ExperienceReservationInformationForm;
