/**
 * # Parking Booking Info
 *
 *
 */
import { ParkingBooking } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatCurrency, formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { resolveTimeToPoiText } from '../../../../modules/parking/utils/distanceUtils';
import { getCustomerDetails } from '../../utils';
import './styles.scss';

export type ParkingBookingInfoProps = {
  booking: ParkingBooking;
};

const ParkingBookingInfo: React.FC<ParkingBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  const bookingData = booking.product.data!;
  const { transferTimeByShuttle } = bookingData.parkingDetails;

  const walkingDetails = bookingData.parkingDetails.productDetails?.walkingDistance;

  const address = bookingData.parkingDetails.location.address || 'POI';
  const timeToPOI =
    resolveTimeToPoiText(t, address, transferTimeByShuttle, walkingDetails) || address;

  const isParkVia =
    booking?.serviceProvider === 'PARK_VIA' || booking?.serviceProvider === 'Park Via';

  const description = bookingData.parkingDetails.productDetails?.description;

  const { totalPrice } = bookingData.paymentDetails;
  const { amount, currency } = totalPrice;

  const paymentMethod =
    'paymentMethod' in bookingData.paymentDetails
      ? bookingData.paymentDetails.paymentMethod
      : undefined;

  const paymentDetailsText = `${formatCurrency(amount / 100, currency)} ${
    paymentMethod ? `${t('charged to')} ${paymentMethod}` : ''
  }`;

  return (
    <div className="yilu-Parking-Booking__ConfirmationPage">
      <ElementsSDK.Typography
        variant="h2"
        className="yilu-Parking-Booking__ConfirmationPage__SummaryLabel"
      >
        {t('bcp.parking.summary.label')}
      </ElementsSDK.Typography>
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.parkingDetails.name]}
        subText={t('bcp.parking')}
        icon={<ElementsSDK.Icon.Parking />}
      />
      {address && (
        <ElementsSDK.ContentBox
          mainTexts={[isParkVia ? address : timeToPOI]}
          subText={t('bcp.location')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={[formatLocalDate(bookingData.arrivalDate, DateFormat.DATE_WITH_TIME)]}
        subText={t('bcp.enterParking')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[formatLocalDate(bookingData.departureDate, DateFormat.DATE_WITH_TIME)]}
        subText={t('bcp.exitParking')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={getCustomerDetails(bookingData.customerDetails)}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[paymentDetailsText]}
        subText={t('bcp.paymentDetails')}
        icon={<ElementsSDK.Icon.Card />}
      />
      {description && (
        <>
          <ElementsSDK.Divider />
          <ElementsSDK.Typography variant="h2" className="yilu-Parking_AdditionalInfo__Header">
            {t('Description')}
          </ElementsSDK.Typography>
          <ElementsSDK.Typography
            variant="p1"
            className="yilu-Parking__DescriptionContent"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ParkingBookingInfo;
