import { BookingIntentField } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoungeFields } from 'components/TravellerInformationForm/Lounge/LoungeFields';
import { PersonalInfoCategory } from 'components/TravellerInformationForm/Lounge/PersonalInfoCategory';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import { ReservationInformationFormContentProps } from 'components/TravellerInformationForm/components/types';

import './styles.scss';

const HA_PRIMARY_GUEST_FIELDS = [
  LoungeFieldName.TITLE,
  LoungeFieldName.FIRST_NAME,
  LoungeFieldName.LAST_NAME,
  LoungeFieldName.EMAIL,
  LoungeFieldName.MOBILE_PHONE,
];

const HA_BILLING_FIELDS = [
  LoungeFieldName.ADDRESS,
  LoungeFieldName.POST_CODE,
  LoungeFieldName.TOWN,
  LoungeFieldName.COUNTRY,
];

type BillingAddressCategoryProps = Pick<
  ReservationInformationFormContentProps,
  'control' | 'fields' | 'trigger' | 'setValue' | 'watch'
>;

const BillingAddressCategory = (props: BillingAddressCategoryProps) => {
  const { t } = useTranslation();
  const sessionKey = 'loungeForm.billingAddress';
  const isExpanded = sessionStorage.getItem(sessionKey) === 'true';
  const [expanded, setExpanded] = React.useState(isExpanded);

  const { fields, setValue } = props;

  // Clear all values when the accordion is collapsed
  const clearValues = useCallback(() => {
    fields.forEach((field) => {
      setValue(field.name, '');
    });
  }, [fields, setValue]);

  useEffect(() => {
    if (!expanded) {
      clearValues();
    }
  }, [expanded, clearValues]);

  useEffect(() => {
    sessionStorage.setItem(sessionKey, JSON.stringify(expanded));
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="yilu-LoungeReservationForm__BillingAddress">
      <button
        type="button"
        className="yilu-LoungeReservationForm__BillingAddress-label"
        onClick={toggleExpanded}
      >
        <ElementsSDK.Icon.PlusCircle />
        <div>
          <ElementsSDK.Typography bold>
            {t('tip.loungeForm.billingAddress.label')}
          </ElementsSDK.Typography>
          <div>
            <ElementsSDK.Typography>
              {t('tip.loungeForm.billingAddress.description')}
            </ElementsSDK.Typography>
          </div>
        </div>
        <ElementsSDK.Icon.ArrowDownChevron
          className={clsx(
            'yilu-LoungeReservationForm__BillingAddress-icon',
            expanded && 'yilu-LoungeReservationForm__BillingAddress-icon-rotate',
          )}
        />
      </button>

      <div
        className={clsx('yilu-LoungeReservationForm__BillingAddress-fields', {
          'yilu-LoungeReservationForm__BillingAddress-fields--expanded': expanded,
        })}
      >
        {expanded && <LoungeFields {...props} fields={fields} />}
      </div>
    </div>
  );
};

export const HolidayExtrasForm = ({
  control,
  trigger,
  watch,
  setValue,
  fields,
}: ReservationInformationFormContentProps) => {
  const personalFieldsToRender = (fields as BookingIntentField[]).filter(
    (field: BookingIntentField | ProductConsumerAttributeDto) =>
      HA_PRIMARY_GUEST_FIELDS.includes(field.name as LoungeFieldName),
  );

  const billingFieldsToRender = (fields as BookingIntentField[]).filter(
    (field: BookingIntentField | ProductConsumerAttributeDto) =>
      HA_BILLING_FIELDS.includes(field.name as LoungeFieldName),
  );

  const hasBillingFields = !!billingFieldsToRender?.length;

  return (
    <>
      <PersonalInfoCategory
        fields={personalFieldsToRender}
        control={control}
        trigger={trigger}
        watch={watch}
        setValue={setValue}
        showDivider={hasBillingFields}
      />
      {hasBillingFields && (
        <BillingAddressCategory
          fields={billingFieldsToRender}
          control={control}
          trigger={trigger}
          watch={watch}
          setValue={setValue}
        />
      )}
    </>
  );
};
