import axios, { AxiosResponse } from 'axios';

import { getVariables } from 'utils/yiluEnv';

import {
  GetAppleWalletResponse,
  GetGoogleWalletResponse,
  GetWalletRequest,
} from 'components/BookingConfirmation/components/SaveQRToWalletButton/utils/types';

export enum LoungeWalletResourcePath {
  GOOGLE_WALLET = '/wallet/google-pass-link/lounge/:bookingId',
  APPLE_WALLET = '/wallet/apple-pass/lounge/:bookingId',
}

export function requestLoungeWallet<T>(
  resourcePath: LoungeWalletResourcePath,
  { bookingId, isAmp, axiosConfig }: GetWalletRequest,
): Promise<AxiosResponse<T>> {
  const yiluEnv = getVariables();

  if (!bookingId) {
    throw new Error('bookingId is required');
  }

  const urlString =
    isAmp && yiluEnv?.YILU_AMP_BACKEND_URL
      ? yiluEnv.YILU_AMP_BACKEND_URL
      : yiluEnv.YILU_BACKEND_URL;

  if (!urlString) {
    throw new Error('YILU_AMP_BACKEND_URL | YILU_BACKEND_URL is required');
  }

  const url = new URL(urlString);

  url.pathname = resourcePath.replace(':bookingId', bookingId);
  url.searchParams.append('token', yiluEnv.YILU_API_TOKEN);

  if (isAmp) {
    url.searchParams.append('amp', '1');
  }

  return axios.get(url.toString(), axiosConfig);
}

export async function getLoungeGoogleWalletLink(walletRequest: GetWalletRequest) {
  return requestLoungeWallet<GetGoogleWalletResponse>(
    LoungeWalletResourcePath.GOOGLE_WALLET,
    walletRequest,
  );
}

export async function getLoungeAppleWalletPass(walletRequest: GetWalletRequest) {
  return requestLoungeWallet<GetAppleWalletResponse>(LoungeWalletResourcePath.APPLE_WALLET, {
    ...walletRequest,
    axiosConfig: { responseType: 'blob' },
  });
}
