import {
  AddShoppingCartItemRequest,
  AddShoppingCartItemResponse,
  CreateBookingIntentRequest,
  CreateBookingIntentResponse,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback } from 'react';

import { getVariables } from 'utils/yiluEnv';
import { getCurrentLanguage } from 'utils/yiluSdk';

export const useAmpCalls = () => {
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_MEILI_CATALOG_ID } = getVariables();

  const emptyShoppingCart = useCallback(
    async (shoppingCartId: string) => {
      try {
        return axios.delete(
          `${YILU_AMP_BACKEND_URL}/shopping-cart/v1/shopping-carts/${shoppingCartId}/items`,
        );
      } catch (error) {
        console.error(
          `Failed to empty the shopping cart with id: ${shoppingCartId}. Error: ${error}`,
        );
      }
    },
    [YILU_AMP_BACKEND_URL],
  );

  const addItemToShoppingCart = useCallback(
    async (shoppingCartId: string, searchResultId: string) => {
      try {
        // add item to shopping cart
        return await axios.post<AddShoppingCartItemResponse>(
          `${YILU_AMP_BACKEND_URL}/shopping-cart/v1/shopping-carts/${shoppingCartId}/item`,
          {
            storeId: YILU_AMP_STORE_ID,
            catalogId: YILU_AMP_MEILI_CATALOG_ID,
            locale: getCurrentLanguage(),
            productId: searchResultId,
            selectedPriceId: 'default',
            quantity: 1,
          } as AddShoppingCartItemRequest['requestBody'],
        );
      } catch (error) {
        console.log('Error adding item to the shopping cart', error);
      }
    },
    [YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_MEILI_CATALOG_ID],
  );

  const createBookingIntent = useCallback(
    async (shoppingCartId: string) => {
      try {
        const bookingIntent = await axios.post<CreateBookingIntentResponse>(
          `${YILU_AMP_BACKEND_URL}/booking/v1/booking-intents`,
          {
            shoppingCartId,
          } as CreateBookingIntentRequest['requestBody'],
        );
        return { bookingIntentId: bookingIntent.data.id };
      } catch (error) {
        console.log('Error creating Booking Intent', error);
      }
    },
    [YILU_AMP_BACKEND_URL],
  );

  return { addItemToShoppingCart, createBookingIntent, emptyShoppingCart };
};
