/**
 * # AirportTransfer Booking Info
 *
 *
 */
import { BusBooking, UnifiedLocation } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { getCustomerDetails } from '../../utils';

export type AirportTransferBookingInfoProps = {
  booking: BusBooking;
};

const AirportTransferBookingInfo: React.FC<AirportTransferBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bookingData = booking.product.data!;

  const originAddress = bookingData.origin && (bookingData.origin as UnifiedLocation).plainAddress;
  const destinationAddress =
    bookingData.destination && (bookingData.destination as UnifiedLocation).plainAddress;

  const originTimezone =
    bookingData.origin &&
    tzlookup(
      (bookingData.origin as UnifiedLocation).latitude,
      (bookingData.origin as UnifiedLocation).longitude,
    );
  const destinationTimezone =
    bookingData.destination &&
    tzlookup(
      (bookingData.destination as UnifiedLocation).latitude,
      (bookingData.destination as UnifiedLocation).longitude,
    );

  return (
    <>
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[
          formatLocalDate(
            bookingData.departureTime,
            DateFormat.FULL_TEXT_WITH_DATE,
            originTimezone,
          ),
        ]}
        subText={t('bcp.departureDate')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      {originAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[originAddress]}
          subText={t('bcp.departs')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      <ElementsSDK.ContentBox
        mainTexts={getCustomerDetails(bookingData.customerDetails)}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
      {destinationAddress && destinationAddress && (
        <>
          <ElementsSDK.Divider />
          <ElementsSDK.Itinerary
            originHighlight={originAddress}
            originDepartureTime={bookingData.departureTime}
            destinationHighlight={destinationAddress}
            destinationArrivalTime={bookingData.arrivalTime}
            displayingTimezone={destinationTimezone}
            showHeader={false}
            icon={<ElementsSDK.Icon.Bus />}
          />
        </>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default AirportTransferBookingInfo;
