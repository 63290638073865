export const EXPERIENCE_SESSION_BOOKING_KEY = 'ExperienceSessionBooking';

export enum sessionBookingState {
  RESERVED = 'RESERVED',
  PAID = 'PAID',
}

export const setSessionBookingState = (bookingSessionId: string, state: sessionBookingState) => {
  window.sessionStorage.setItem(`${EXPERIENCE_SESSION_BOOKING_KEY}-${bookingSessionId}`, state);
};

export const getSessionBookingState = (bookingSessionId: string) => {
  return window.sessionStorage.getItem(`${EXPERIENCE_SESSION_BOOKING_KEY}-${bookingSessionId}`);
};
