/**
 * # Lounge Booking Confirmation Hook
 *
 *
 */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils';

import { LoungeBookingConfirmationProps } from '../../../../components/BookingConfirmation';

/**
 * Retrieve data for the lounge booking confirmation (page)
 */
export const useBookingConfirmation = () => {
  const location = useLocation();

  const bookingConfirmationPageQueryParams = useMemo(() => {
    return paramConverters.getBookingConfirmationPageQueryParams(location);
  }, [location]);

  const bookingConfirmationPageProps = useMemo(() => {
    const bookingConfirmationPageProps: LoungeBookingConfirmationProps = {
      bookingId: bookingConfirmationPageQueryParams.bookingId,
      onError(error) {
        throw error;
      },
      isAmpEnabled: true,
      onResponse() {
        yiluTracking.sendGAEvent({
          event: 'page_loaded',
          category: 'lounge-bcp',
          label: 'lounge_bcp_loaded',
        });
      },
    };
    return bookingConfirmationPageProps;
  }, [bookingConfirmationPageQueryParams]);

  return {
    bookingConfirmationPageProps,
  };
};
