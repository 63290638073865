import clsx from 'clsx';
import React from 'react';

import './styles.scss';
import { ContainerSize } from './types';

export type ContainerProps = {
  size?: ContainerSize;
  className?: string;
  children?: React.ReactNode;
};

const Container: React.FC<ContainerProps> = ({
  size = ContainerSize.MEDIUM,
  children,
  className,
}) => {
  const componentClass = 'yilu-payment-bcp-container';
  const classes = clsx(
    componentClass,
    {
      [`${componentClass}--medium`]: size === ContainerSize.MEDIUM,
      [`${componentClass}--large`]: size === ContainerSize.LARGE,
    },
    className,
  );

  return <div className={classes}>{children}</div>;
};

export default Container;
