import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import clsx from 'clsx';
import React from 'react';

import './styles.scss';

export type ProviderInfoProps = {
  logoUrl?: string;
  name?: string;
  vehicleClass?: string;
  className?: string;
};

const ProviderInfo: React.FC<ProviderInfoProps> = ({ logoUrl, name, vehicleClass, className }) => {
  const componentClass = 'yilu-payment-bcp-provider-info';
  const classes = clsx(componentClass, className);

  return (
    <section data-testid={componentClass} className={classes}>
      <div className={`${componentClass}__inner`}>
        {logoUrl && (
          <img className={`${componentClass}__img-logo`} src={logoUrl} alt={'provider logo'} />
        )}
        {name && <ElementsSDK.Typography variant="h4">{name}</ElementsSDK.Typography>}
        {vehicleClass && (
          <ElementsSDK.Typography variant="h3" className={`${componentClass}__vehicle-class`}>
            {vehicleClass}
          </ElementsSDK.Typography>
        )}
      </div>
    </section>
  );
};

export default ProviderInfo;
