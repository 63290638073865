import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';

import { PaymentInputContextProvider } from 'context';

import InteractiveSummaryLayout from 'components/Layout/InteractiveSummaryLayout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import PageHeading from 'components/PageHeading';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { LoungeInteractiveProductSummary } from '../../components';
import { useLoungePaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { isPageReady, paymentInformationFormProps, productSummaryProps } =
    useLoungePaymentInputPage();
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');

  return (
    <div className="lounges-pip">
      {isPageReady ? (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar />
                <PageHeading />
                {productSummaryProps && !isDesktop && (
                  <InteractiveSummaryLayout>
                    <LoungeInteractiveProductSummary {...productSummaryProps} />
                  </InteractiveSummaryLayout>
                )}
                {paymentInformationFormProps && (
                  <PaymentInformation {...paymentInformationFormProps} />
                )}
              </div>
              {productSummaryProps && isDesktop && (
                <InteractiveSummaryLayout>
                  <LoungeInteractiveProductSummary {...productSummaryProps} />
                </InteractiveSummaryLayout>
              )}
            </div>
          </div>
        </PaymentInputContextProvider>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default PaymentInputPage;
