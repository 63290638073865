import { SearchItem } from '@yiluhub/yilu-amp-types';

import {
  isBookingConfirmationPage,
  isPaymentInformationPage,
  isProductDetailsPage,
  isSearchInputPage,
  isSearchResultsPage,
  isTravellerInformationPage,
} from 'utils/headerUtils';
import { getProductFromUrl } from 'utils/paramConverters';

/**
 * # Tracking
 *
 * Definition of tracking events.
 */
const isDev = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';

/**
 * Send an search tracking event for capturing a selected index.
 *
 * @param selectedId - selected result id
 * @param results  - list of options
 * @param category - category of the search. Important: needs to use underscore
 */
export const sendSelectedIndexSearchEvent = (
  selectedId: string,
  results: SearchItem[],
  category: string,
) => {
  const selectedIndex = results.findIndex((result) => {
    return result.id === selectedId;
  });

  sendGAEvent({
    event: 'srp_result_clicked',
    category,
    label: `${category}_result_clicked`,
    value: selectedIndex,
  });
};

/**
 * Send a payment successful tracking event.
 *
 * @param event  - event action
 * @param category  - event category
 * @param entityType  - type of the entity
 */
export const sendPaymentSuccessfulEvent = (reservationId: string) => {
  sendGAEvent({
    event: 'payment_successful',
    category: 'payment',
    label: 'payment_completed',
    value: reservationId,
  });
};

export function sendLocaleGaEvent(event: string): void {
  const { pathname } = window.location;

  const product = getProductFromUrl(pathname);
  let page = '';

  if (isPaymentInformationPage(pathname)) {
    page = 'PIP';
  } else if (isProductDetailsPage(pathname)) {
    page = 'PDP';
  } else if (isTravellerInformationPage(pathname)) {
    page = 'TIP';
  } else if (isBookingConfirmationPage(pathname)) {
    page = 'BCP';
  } else if (isSearchInputPage(pathname)) {
    page = 'SIP';
  } else if (isSearchResultsPage(pathname)) {
    page = 'SRP';
  }

  const category = `${product}-${page}`;

  sendGAEvent({
    event: `${event}-click`,
    category: category,
    label: `${category}-locale-dropdown`,
  });
}

/**
 * Send a ga tracking event.
 *
 * @param config.event    - event name
 * @param config.category - category
 * @param config.label    - label of the entity
 * @param config.value    - value of of the event
 */
export const sendGAEvent = ({
  event,
  category,
  label,
  value,
}: {
  event: string;
  category: string;
  label?: string;
  value?: string | number;
}) => {
  if (window.gtag) {
    if (isDev) {
      console.log(`[GA event]: sending event ${event}, category: ${category}, label: ${label}`);
    }
    window.gtag('event', event, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
