import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { AddShoppingCartItemRequest } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormContent from './components/FormContent';
import {
  UseReservationParams,
  useReservation,
  useReservationError,
  useReservationErrorModal,
} from './hooks';
import './styles.scss';

export type TravellerInformationFormProps<T extends AMP_VERTICALS | 'EXPERIENCE'> = {
  type: T | 'EXPERIENCE';
  shoppingCartItemRequestBody?: AddShoppingCartItemRequest['requestBody'];
  productName?: string;
  isSBBCarrier?: boolean;
  iata?: string;
} & Pick<
  UseReservationParams<T>,
  | 'createBookingIntentRequestBody'
  | 'onUpdateBookingIntentSuccess'
  | 'fieldValues'
  | 'onUpdateBookingIntentError'
  | 'onInitBookingIntentError'
> &
  React.RefAttributes<HTMLFormElement> & {
    ref: React.RefObject<HTMLFormElement>;
    onReservationFormFieldChange?: (name: string | undefined, value: unknown) => void;
    onUpdateBookingIntentRequestStateChange?(isFormLoading: boolean): void;
  };

export const TravellerInformationForm = React.forwardRef<
  HTMLFormElement,
  TravellerInformationFormProps<AMP_VERTICALS>
>(
  (
    {
      type,
      productName,
      iata,
      shoppingCartItemRequestBody,
      createBookingIntentRequestBody,
      fieldValues,
      isSBBCarrier,
      onReservationFormFieldChange,
      onUpdateBookingIntentRequestStateChange,
      onUpdateBookingIntentSuccess,
      onUpdateBookingIntentError,
      onInitBookingIntentError,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);

    // we use a ref in order to get the current value in the asynchronous function
    const [isModalConfirmed, setIsModalConfirmed] = useState(false);
    const isModalConfirmedRef = useRef(false);
    useEffect(() => {
      isModalConfirmedRef.current = isModalConfirmed;
    }, [isModalConfirmed]);

    const { handleSubmit, trigger, control, watch, setValue, getValues } = useForm();
    const { formError, setFormError } = useReservationError();
    const { onErrorModalOk, isErrorModalVisible } = useReservationErrorModal({
      formError,
      setFormError,
    });

    const { fields, bookingIntent, handleFormSubmit, warningModalData } = useReservation({
      type,
      iata,
      shoppingCartItemRequestBody,
      createBookingIntentRequestBody,
      onUpdateBookingIntentSuccess,
      onUpdateBookingIntentRequestStateChange,
      onUpdateBookingIntentError,
      onInitBookingIntentError,
      formError,
      setFormError,
      setIsModalVisible: setIsWarningModalVisible,
      isModalConfirmedRef,
      setIsModalConfirmed,
      getValues,
    });

    useEffect(() => {
      if (!onReservationFormFieldChange) return;

      const subscription = watch((formState, { name }) => {
        name && onReservationFormFieldChange(name, formState[name]);
      });
      return () => subscription.unsubscribe();
    }, [watch, onReservationFormFieldChange]);

    const onWarningModalConfirm = () => {
      setIsWarningModalVisible(false);
      setIsModalConfirmed(true);
      handleSubmit(handleFormSubmit)();
    };

    const showFormLoadingIndicator = !bookingIntent;

    return (
      <form
        id="yilu-ReservationForm"
        className="yilu-ReservationForm"
        onSubmit={handleSubmit(handleFormSubmit)}
        ref={ref}
      >
        {showFormLoadingIndicator ? (
          <ElementsSDK.FormLoadingIndicator />
        ) : (
          <FormContent
            type={type}
            fields={fields}
            control={control}
            trigger={trigger}
            setValue={setValue}
            watch={watch}
            fieldValues={fieldValues}
            productName={productName}
            isSBBCarrier={isSBBCarrier}
            providerId={createBookingIntentRequestBody.serviceProvider}
            iata={iata}
          />
        )}
        <ElementsSDK.Modal
          size={ElementsSDK.ModalSize.SMALL}
          isContentCentered
          isVisible={isErrorModalVisible}
          title={t('somethingWentWrong')}
          type={ElementsSDK.ModalType.ERROR}
          onConfirm={onErrorModalOk}
        >
          <ElementsSDK.Typography variant="p1">{t('somethingWentWrongMsg')}</ElementsSDK.Typography>
        </ElementsSDK.Modal>

        {warningModalData && (
          <ElementsSDK.Modal
            isContentCentered
            size={ElementsSDK.ModalSize.SMALL}
            isVisible={isWarningModalVisible}
            Icon={ElementsSDK.Icon.InfoOutlined}
            onConfirm={onWarningModalConfirm}
            onCancel={() => setIsWarningModalVisible(false)}
            title={t('tip.warning.modal.title')}
            confirmText={t('tip.warning.modal.confirm')}
            cancelText={t('tip.warning.modal.cancel')}
            {...warningModalData.props}
          >
            {warningModalData.children}
          </ElementsSDK.Modal>
        )}
        <input type="submit" value="submit" style={{ display: 'none' }} />
      </form>
    );
  },
);
