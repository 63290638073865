import React from 'react';

import { BookingConfirmation } from 'components/BookingConfirmation';

import { useBookingConfirmation } from './hooks';

const BookingConfirmationPage: React.FC = () => {
  const { bookingConfirmationPageProps } = useBookingConfirmation();

  return (
    <div className="bcp">
      <div className="form-container-wrapper">
        <div className="container form-container">
          <div className="content">
            <BookingConfirmation.Parking {...bookingConfirmationPageProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationPage;
