import { createContext } from 'react';

// NOTE: These are all postMessage eventTypes that the Embedded TI can send.
export enum EmbeddedEventType {
  EMBEDDED_TI_LOADED = 'EMBEDDED_TI_LOADED',
  EMBEDDED_TI_RESIZE = 'EMBEDDED_TI_RESIZE',
  EMBEDDED_TI_EXIT = 'EMBEDDED_TI_EXIT',
  EMBEDDED_TI_SHOPPING_CART_UPDATED = 'EMBEDDED_TI_SHOPPING_CART_UPDATED',
}

export type EmbeddedTIContextType = {
  sendPostMessage: (eventType: EmbeddedEventType, data: any) => void;
};

export const EmbeddedTIContext = createContext<EmbeddedTIContextType | undefined>(undefined);
