import { OwnerInfo } from '@yiluhub/network-sdk';

export const getCustomerDetails = (customerDetails: OwnerInfo) => {
  let details: string[] = [];
  if (!customerDetails) {
    return details;
  }

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    carPlate,
    phoneNumberPrefix,
    phoneNumberSuffix,
  } = customerDetails;

  if (firstName || lastName) {
    details = [...details, `${firstName && `${firstName} `}${lastName}`];
  }

  if (email) {
    details = [...details, email];
  }

  if (phoneNumber && phoneNumber !== 'null') {
    details = [...details, phoneNumber];
  }

  if ((!phoneNumber || phoneNumber === 'null') && phoneNumberPrefix && phoneNumberSuffix) {
    details = [...details, `+${phoneNumberPrefix}${phoneNumberSuffix}`];
  }

  if (carPlate) {
    details = [...details, carPlate];
  }

  return details;
};
