import { AMP_VERTICALS, YILU_FEATURE } from 'applicationConstants';

import { yiluEnv } from 'utils';

import {
  useEnterprisePartnerConfigurationContext,
  useIsFeatureFlagEnabled,
  useIsVerticalEnabled,
  useUserProfileContext,
} from 'context';

import routes from './routes';

export const useTIRouterProps = (initialPath?: string) => {
  // start tracking
  const {
    userProfileState: {
      data: {
        consent: { statistics },
      },
    },
  } = useUserProfileContext();
  const {
    enterprisePartnerConfigurationState: { enabledVerticals },
  } = useEnterprisePartnerConfigurationContext();
  const isShowTrackingConsentEnabled = useIsFeatureFlagEnabled(YILU_FEATURE.SHOW_TRACKING_CONSENT);
  const startTracking = isShowTrackingConsentEnabled && statistics;
  const yiluEnvVariables = yiluEnv.getVariables();

  // default path
  const verticalToStartingRouteMap: Record<AMP_VERTICALS, string> = {
    [AMP_VERTICALS.AIRPORT_TRANSFERS]: routes.AIRPORT_TRANSFER_SEARCH,
    [AMP_VERTICALS.AIRPORT_TRANSFER_PASSES]: routes.SWISS_TRAVEL_PASS_SEARCH,
    [AMP_VERTICALS.RIDES]: routes.RIDES_SEARCH,
    [AMP_VERTICALS.PARKING]: routes.PARKING_SEARCH,
    [AMP_VERTICALS.LOUNGES]: routes.LOUNGES_SEARCH,
    [AMP_VERTICALS.CAR_RENTAL]: routes.MEILI_CAR_RENTAL,
  };
  const firstFoundEnabledVertical = Object.keys(enabledVerticals || {})[0] as AMP_VERTICALS;
  const defaultPath =
    initialPath ||
    (firstFoundEnabledVertical && verticalToStartingRouteMap[firstFoundEnabledVertical]) ||
    routes.BASE;

  // enabled products
  const isHighPassEnabled = useIsFeatureFlagEnabled(YILU_FEATURE.SHOW_HIGHPASS_FAST_TRACK);
  const isMeiliEnabled = Boolean(yiluEnvVariables.YILU_MEILI_PTID);
  const isEmbeddedEnabled = yiluEnvVariables.YILU_EMBEDDED_TI === 'true';
  const isLandingPageEnabled = yiluEnvVariables.YILU_LANDING_PAGE_ENABLED === 'true';
  const hasEnabledExperienceServiceProviders = Boolean(yiluEnvVariables.CORAS_PARTNER_ID);
  const hasEnabledLoungeServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.LOUNGES);
  const hasEnabledParkingServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.PARKING);
  const hasEnabledBusServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.AIRPORT_TRANSFERS);
  const hasEnabledRideServiceProviders = useIsVerticalEnabled(AMP_VERTICALS.RIDES);

  return {
    defaultPath,
    startTracking,
    isHighPassEnabled,
    hasEnabledExperienceServiceProviders,
    hasEnabledLoungeServiceProviders,
    hasEnabledParkingServiceProviders,
    hasEnabledBusServiceProviders,
    hasEnabledRideServiceProviders,
    isMeiliEnabled,
    isEmbeddedEnabled,
    isLandingPageEnabled,
  };
};
