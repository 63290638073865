import { combineReducers } from '@reduxjs/toolkit';

import currentProduct from './features/CurrentProduct';
import loungeProduct from './features/LoungeProduct';

const rootReducer = combineReducers({
  currentProduct,
  loungeProduct,
});

export default rootReducer;
