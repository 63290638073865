import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { EmbeddedTIContextProvider, LegalModalContextProvider } from 'context';

import Layout from 'components/Layout';
import ScrollBehavior from 'components/ScrollBehavior';

import Analytics from 'widgets/Analytics';
import GlobalDefaults from 'widgets/GlobalDefaults';

import * as Page from 'modules';

import RedirectPageOnCondition from './RedirectPageOnCondition';
import routes from './routes';
import { useTIRouterProps } from './useTIRouterProps';

export type RouterProps = {
  initialPath?: string;
};

const Router: React.FC<RouterProps> = ({ initialPath }) => {
  const {
    defaultPath,
    startTracking,
    hasEnabledExperienceServiceProviders,
    hasEnabledLoungeServiceProviders,
    hasEnabledParkingServiceProviders,
    hasEnabledBusServiceProviders,
    hasEnabledRideServiceProviders,
    isHighPassEnabled,
    isEmbeddedEnabled,
    isMeiliEnabled,
    isLandingPageEnabled,
  } = useTIRouterProps(initialPath);

  return (
    <BrowserRouter>
      <ScrollBehavior />
      <GlobalDefaults>
        {startTracking && <Analytics />}
        {isEmbeddedEnabled ? (
          <EmbeddedTIContextProvider>
            <Routes>
              <Route
                path={routes.MEILI_CAR_RENTAL}
                element={
                  <RedirectPageOnCondition
                    condition={isMeiliEnabled}
                    redirectPath={defaultPath}
                    PageElement={<Page.CarRentalPage />}
                  />
                }
              />
            </Routes>
          </EmbeddedTIContextProvider>
        ) : (
          <LegalModalContextProvider>
            <Routes>
              <Route path={routes.BASE} element={<Layout />}>
                {/* AIRPORT TRANSFER */}
                <Route
                  path={routes.AIRPORT_TRANSFER_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferSearchResultsPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_PDP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferProductDetailsPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.AIRPORT_TRANSFER_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.AirportTransferBookingConfirmationPage />}
                    />
                  }
                />
                {/* RIDES */}
                <Route
                  path={routes.RIDES_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.RIDES_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesSearchResultsPage />}
                    />
                  }
                />
                <Route
                  path={routes.RIDES_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.RIDES_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.RIDES_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledRideServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.RidesBookingConfirmationPage />}
                    />
                  }
                />
                {/* PARKING */}
                <Route
                  path={routes.PARKING_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingSearchResultsPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_PDP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingProductDetailsPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.PARKING_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledParkingServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ParkingBookingConfirmationPage />}
                    />
                  }
                />
                {/* LOUNGES */}
                <Route
                  path={routes.LOUNGES_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_SEARCH_RESULTS}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesSearchResultsPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_PDP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesProductDetailsPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.LOUNGES_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledLoungeServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.LoungesBookingConfirmationPage />}
                    />
                  }
                />
                {/* EXPERIENCES */}
                <Route
                  path={routes.EXPERIENCES_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledExperienceServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ExperiencesSearchPage />}
                    />
                  }
                />
                <Route
                  path={routes.EXPERIENCES_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledExperienceServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ExperiencesTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.EXPERIENCES_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledExperienceServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ExperiencesPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.EXPERIENCES_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledExperienceServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.ExperiencesBookingConfirmationPage />}
                    />
                  }
                />
                {/* SWISS TRAVEL */}
                <Route
                  path={routes.SWISS_TRAVEL_PASS_SEARCH}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBSearchInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.SWISS_TRAVEL_PASS_TIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBTravellerInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.SWISS_TRAVEL_PASS_PIP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBPaymentInputPage />}
                    />
                  }
                />
                <Route
                  path={routes.SWISS_TRAVEL_PASS_BCP}
                  element={
                    <RedirectPageOnCondition
                      condition={hasEnabledBusServiceProviders}
                      redirectPath={defaultPath}
                      PageElement={<Page.SBBBookingConfirmationPage />}
                    />
                  }
                />
                {/* HIGHPASS */}
                <Route
                  path={routes.HIGHPASS_FAST_TRACK}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassFastTrackPage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_LOUNGES}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassLoungesPage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_VIP_SERVICE}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassVipServicePage />}
                    />
                  }
                />
                <Route
                  path={routes.HIGHPASS_MEET_GREET}
                  element={
                    <RedirectPageOnCondition
                      condition={isHighPassEnabled}
                      redirectPath={defaultPath}
                      PageElement={<Page.HighPassMeetAndGreetPage />}
                    />
                  }
                />

                {/* BASE ROUTE */}
                <Route
                  index
                  element={
                    isEmbeddedEnabled ? null : (
                      <RedirectPageOnCondition
                        condition={isLandingPageEnabled}
                        redirectPath={defaultPath}
                        PageElement={<Page.LandingPage />}
                      />
                    )
                  }
                />
              </Route>
              <Route path={'*'} element={<Navigate to={defaultPath} />} />
            </Routes>
          </LegalModalContextProvider>
        )}
      </GlobalDefaults>
    </BrowserRouter>
  );
};

export default Router;
