import { BookingIntentField, BookingIntentFieldType } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { getYiluConfig } from '@yiluhub/ui-utilities';
import React, { FC } from 'react';
import { Control, Controller, FieldValues, SetFieldValue, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import CategoryBox from 'components/TravellerInformationForm/components/CategoryBox';
import Header from 'components/TravellerInformationForm/components/Header';
import { useSalutations } from 'components/TravellerInformationForm/utils/salutaions';

import { ReservationInformationFormContentProps } from '../components/types';
import {
  PhoneInputType,
  getDefaultCountryValue,
  getPhoneNumberFieldValues,
  getValidationRules,
  isLoyaltyIdValid,
} from '../utils';
import { getSortedCountriesAlpha3Options } from '../utils/countries';
import './styles.scss';
import {
  getParkingFieldsToRender,
  getParkingSalutationValidationRules,
} from './utils/parkingUtils';

enum PARK_AERO_BILLING_ADDRESS_FIELDS {
  STREET = 'street',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  COUNTRY = 'country',
}

export type RenderFieldsProps = {
  control: Control;
  trigger: UseFormTrigger<FieldValues>;
  setValue: SetFieldValue<any>;
  fieldsToRender: BookingIntentField[];
};

const ParkingFields: FC<RenderFieldsProps> = ({ fieldsToRender, control, trigger, setValue }) => {
  const { t } = useTranslation();
  const { locale } = getYiluConfig();
  const envVariables = yiluEnv.getVariables();

  const getAndSetSessionStorageValueInitially = (key: string): string => {
    const savedItem = sessionStorage.getItem(key);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && parsedSavedItem !== '') {
      setValue(key, parsedSavedItem);
    }
    return parsedSavedItem;
  };

  const salutationOptions: ElementsSDK.SelectOption[] = [
    { value: 'MR', label: t(`tip.parking.reservation.form.field.salutation.option.mr`) },
    { value: 'MRS', label: t(`tip.parking.reservation.form.field.salutation.option.mrs`) },
  ];

  const { salutationSelectOptions, getDefaultSalutationValue } = useSalutations(salutationOptions);

  return (
    <>
      {fieldsToRender.map((field) => {
        if (field.type === BookingIntentFieldType.PHONE_NUMBER) {
          return (
            <Controller
              key={field.name}
              name={field.name.replace('.', '_')}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.PhoneInputField
                    dataTestId="customer-phone"
                    codeValue={value?.code || getPhoneNumberFieldValues(PhoneInputType.CODE, name)}
                    phoneValue={
                      value?.number || getPhoneNumberFieldValues(PhoneInputType.NUMBER, name)
                    }
                    label={t(field.name)}
                    className="yilu-ParkingReservationForm__field"
                    onChange={(value) => {
                      sessionStorage.setItem(name, JSON.stringify(value));
                      setValue(name, value);
                      onChange(value);
                      isTouched && trigger(name);
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    phoneRef={ref}
                    errorMessage={error?.message && t(error.message)}
                    defaultCountry={envVariables?.PHONE_COUNTRY_CODE || 'DE'}
                  />
                );
              }}
            />
          );
        } else if (field.name === 'salutation') {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getParkingSalutationValidationRules(field)}
              render={({ field: { onChange, name }, fieldState: { error } }) => {
                return (
                  <ElementsSDK.InputField.Select
                    dataTestId="salutation-select"
                    options={salutationSelectOptions}
                    defaultOption={getDefaultSalutationValue(name)}
                    label={t('tip.parking.reservation.form.field.salutation.label')}
                    placeholder={t('tip.parking.reservation.form.field.salutation.placeholder')}
                    className="yilu-ParkingReservationForm__field"
                    onSelect={(selectedValue: unknown) => {
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                      setValue(selectedValue);
                      onChange(selectedValue);
                    }}
                    disabledTyping={true}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.name === 'country') {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              rules={getValidationRules(field)}
              render={({ field: { onChange, name }, fieldState: { error } }) => {
                return (
                  <ElementsSDK.InputField.Select
                    dataTestId="country-select"
                    defaultOption={getDefaultCountryValue(name, locale as 'en' | 'de')}
                    options={getSortedCountriesAlpha3Options(locale as 'en' | 'de')}
                    label={t('tip.parking.reservation.form.field.country.label')}
                    placeholder={t('tip.parking.reservation.form.field.country.placeholder')}
                    className="yilu-ParkingReservationForm__field"
                    onSelect={(selectedValue: unknown) => {
                      onChange(selectedValue);
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                      setValue(selectedValue);
                    }}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.name === 'loyaltyId') {
          return (
            <Controller
              key={field.name}
              rules={{ validate: isLoyaltyIdValid }}
              name={field.name}
              control={control}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.TextInput
                    value={value || getAndSetSessionStorageValueInitially(name)}
                    label={t('tip.reservation.form.field.loyaltyId')}
                    placeholder={t('tip.reservation.form.field.loyaltyId.placeholder')}
                    name={name}
                    className="yilu-ParkingReservationForm__field"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      isTouched && trigger(name);
                      setValue(e.target.value);
                      sessionStorage.setItem(name, JSON.stringify(e.target.value));
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        } else if (field.type === BookingIntentFieldType.EMAIL) {
          return (
            <Controller
              key={field.name}
              name={field.name.replace('.', '_')}
              control={control}
              rules={getValidationRules(field)}
              render={({
                field: { onChange, onBlur, name, ref, value },
                fieldState: { isTouched, error },
              }) => {
                return (
                  <ElementsSDK.InputField.Select
                    isEmailDropdown
                    showDropdownIcon={false}
                    dataTestId="parking-reservation-email"
                    label={t(field.name)}
                    value={value || getAndSetSessionStorageValueInitially(name)}
                    name={name}
                    className="yilu-ParkingReservationForm__field"
                    onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e);
                      isTouched && trigger(name);
                      setValue(e.target.value);
                      sessionStorage.setItem(name, JSON.stringify(e.target.value));
                    }}
                    onSelect={(selectedValue: unknown) => {
                      onChange(selectedValue);
                      isTouched && trigger(name);
                      setValue(selectedValue);
                      sessionStorage.setItem(name, JSON.stringify(selectedValue));
                    }}
                    onBlur={() => {
                      onBlur();
                      trigger(name);
                    }}
                    inputRef={ref}
                    errorMessage={error?.message && t(error.message)}
                  />
                );
              }}
            />
          );
        }
        return (
          <Controller
            key={field.name}
            name={field.name.replace('.', '_')}
            control={control}
            rules={getValidationRules(field)}
            render={({
              field: { onChange, onBlur, name, ref, value },
              fieldState: { isTouched, error },
            }) => {
              return (
                <ElementsSDK.InputField.TextInput
                  label={t(field.name)}
                  value={value || getAndSetSessionStorageValueInitially(name)}
                  name={name}
                  className="yilu-ParkingReservationForm__field"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e);
                    isTouched && trigger(name);
                    setValue(e.target.value);
                    sessionStorage.setItem(name, JSON.stringify(e.target.value));
                  }}
                  onBlur={() => {
                    onBlur();
                    trigger(name);
                  }}
                  inputRef={ref}
                  errorMessage={error?.message && t(error.message)}
                />
              );
            }}
          />
        );
      })}
    </>
  );
};

const ParkingForm: FC<RenderFieldsProps> = ({ fieldsToRender, control, trigger, setValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header heading={t('heading')} className="yilu-ParkingReservationForm__header" />
      <ParkingFields
        fieldsToRender={fieldsToRender}
        control={control}
        trigger={trigger}
        setValue={setValue}
      />
    </>
  );
};

const ParkAeroForm: FC<RenderFieldsProps> = ({ fieldsToRender, control, trigger, setValue }) => {
  const { t } = useTranslation();

  const billingFields = Object.values(PARK_AERO_BILLING_ADDRESS_FIELDS);
  const customerFieldsToRender = fieldsToRender.filter(
    (field) => !billingFields.includes(field.name as PARK_AERO_BILLING_ADDRESS_FIELDS),
  );

  const billingAddressFieldsToRender = fieldsToRender.filter((field) =>
    billingFields.includes(field.name as PARK_AERO_BILLING_ADDRESS_FIELDS),
  );

  return (
    <div className="yilu-ParkingReservationForm">
      {customerFieldsToRender && (
        <CategoryBox
          category={t('tip.reservation.form.category.customerInformation')}
          showDivider={billingAddressFieldsToRender?.length > 0}
        >
          <ParkingFields
            fieldsToRender={customerFieldsToRender}
            control={control}
            trigger={trigger}
            setValue={setValue}
          />
        </CategoryBox>
      )}
      {billingAddressFieldsToRender && (
        <CategoryBox
          category={t('tip.reservation.form.category.billingAddress')}
          description={t('tip.reservation.form.category.billingAddress.description')}
        >
          <ParkingFields
            fieldsToRender={billingAddressFieldsToRender}
            control={control}
            trigger={trigger}
            setValue={setValue}
          />
        </CategoryBox>
      )}
    </div>
  );
};

/*
 * NOTE: Replacing '.' with '_' on field names to avoid grouping the fields upon registration
 *       from react-hook-form
 *
 *       field.name.replace('.', '_')
 *       customer.firstName -> customer_firstName
 *
 *       OnSubmit will reverse the string replace to match the bookingIntent request keys.
 */
const ParkingReservationInformationForm: React.FC<ReservationInformationFormContentProps> = ({
  providerId,
  fields,
  control,
  trigger,
  setValue,
}) => {
  const fieldsToRender = getParkingFieldsToRender(fields, providerId);

  const ParkingFormComponent: FC<RenderFieldsProps> =
    providerId === 'PARK_AERO' ? ParkAeroForm : ParkingForm;

  return (
    <div className="yilu-ParkingReservationForm">
      <ParkingFormComponent
        control={control}
        trigger={trigger}
        setValue={setValue}
        fieldsToRender={fieldsToRender}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ParkingReservationInformationForm;
