import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePaymentInputContext } from '../../context';
import ErrorMessage from '../PaymentInformation/ErrorMessage';
import TermsLink from '../TermsLink';
import './styles.scss';

export type TermsCheckProps = {
  showTermsOnModal?: boolean;
  termsUrl: string;
  productDetails?: {
    providerId: string;
    providerName?: string;
    locationName?: string;
  };
};

const TermsCheck: FC<TermsCheckProps> = ({ showTermsOnModal, termsUrl, productDetails }) => {
  const { t } = useTranslation();
  const { isTermsAccepted, onTermsAcceptedChange, hasFormTriggered } = usePaymentInputContext();

  const { isVisible: isTermsVisible, onShow, onHide } = ElementsSDK.useModal(false);
  const [showYiluTerms, setShowYiluTerms] = useState(false);
  const [showProviderTerms, setShowProviderTerms] = useState(false);

  useEffect(() => {
    onTermsAcceptedChange(isTermsAccepted);
  }, [isTermsAccepted, onTermsAcceptedChange]);

  return (
    <>
      <ElementsSDK.Checkbox
        testid="id-yilu-checkbox"
        onChange={onTermsAcceptedChange}
        className="yilu-terms__checkbox"
        size="small"
        text={
          <TermsLink
            onProviderTermsClick={(event: MouseEvent) => {
              event.stopPropagation();
              setShowYiluTerms(false);
              setShowProviderTerms(true);
              onShow();
            }}
            onYiluTermsClick={(event: MouseEvent) => {
              event.stopPropagation();
              setShowYiluTerms(true);
              setShowProviderTerms(false);
              onShow();
            }}
            providerName={productDetails?.providerName || productDetails?.providerId || ''}
            providerLocation={productDetails?.locationName}
            showTermsOnModal={!!showTermsOnModal}
            termsUrl={termsUrl}
          />
        }
      />
      {hasFormTriggered && !isTermsAccepted && <ErrorMessage errorMessage={t('acceptTerms')} />}
      <ElementsSDK.Modal
        size={ElementsSDK.ModalSize.MEDIUM}
        isVisible={isTermsVisible}
        onConfirm={onHide}
        isResponsiveModeEnabled
        onClickOutside={onHide}
      >
        {showYiluTerms && <div dangerouslySetInnerHTML={{ __html: t('terms.of.use') }} />}
        {showProviderTerms && (
          <iframe
            className="yilu-payment-payment-form__iframe"
            src={termsUrl}
            title={t('Terms & Conditions title')}
          />
        )}
      </ElementsSDK.Modal>
    </>
  );
};

export default TermsCheck;
