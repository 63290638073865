/**
 * # Experience Booking Info
 *
 *
 */
import { ExperienceBooking } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatCurrency, formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomerDetails } from '../../utils';

const getExperienceAddress = (location: { city: string; address: string }) => {
  const { address, city } = location;
  const addressChunks = [];

  address && addressChunks.push(address);
  city && addressChunks.push(city);

  return addressChunks.join(', ');
};

export type ExperienceBookingInfoProps = {
  booking: ExperienceBooking;
};

const ExperienceBookingInfo: React.FC<ExperienceBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bookingData = booking.product.data!;

  const { amount, currency } = bookingData.paymentDetails.totalPrice;

  return (
    <>
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[formatLocalDate(bookingData.startDate, DateFormat.DATE_WITH_TIME)]}
        subText={t('bcp.dateAndTime')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[getExperienceAddress(bookingData.eventDetails.location)]}
        subText={t('bcp.location')}
        icon={<ElementsSDK.Icon.Pin />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[formatCurrency(amount / 100, currency)]}
        subText={t('bcp.price')}
        icon={<ElementsSDK.Icon.Tag />}
      />
      <ElementsSDK.ContentBox
        mainTexts={getCustomerDetails(bookingData.customerDetails)}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
    </>
  );
};

export default ExperienceBookingInfo;
