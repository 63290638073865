import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage }) => {
  return (
    <ElementsSDK.Typography isError className="yilu-payment-information__error-message">
      {errorMessage}
    </ElementsSDK.Typography>
  );
};

interface ErrorMessageProps {
  errorMessage: string;
}

export default ErrorMessage;
