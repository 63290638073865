import clsx from 'clsx';
import React, { FC, useState } from 'react';

import { yiluTracking } from 'utils/index';

import { getLoungeAppleWalletPass } from 'components/BookingConfirmation/components/SaveQRToWalletButton/utils/wallet.endpoints';

import AppleWalletDeSVG from './assets/apple-de.svg';
import AppleWalletEnSVG from './assets/apple-en.svg';
import './styles.scss';

export type AppleLangType = 'EN' | 'DE';

export type AppleWalletButtonProps = {
  lang: AppleLangType;
  bookingId: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

const ButtonImages = {
  EN: AppleWalletEnSVG,
  DE: AppleWalletDeSVG,
};

export type AppleWalletButtonAnchorProps = {
  lang: AppleLangType;
  onClick: () => void;
  disabled?: boolean;
};
export const AppleWalletButtonAnchor: FC<AppleWalletButtonAnchorProps> = ({
  lang,
  onClick,
  disabled,
}) => {
  const WalletButton: string = ButtonImages?.[lang as AppleLangType] || ButtonImages.EN;

  return (
    <div className={clsx('yilu-reservation-bcp__apple-wallet-button-wrap')}>
      <button
        type={'button'}
        className={clsx('yilu-reservation-bcp__apple-wallet-button')}
        tabIndex={0}
        onClick={() => !disabled && onClick()}
        aria-disabled={disabled}
        data-testid="yilu-reservation-bcp__apple-wallet-button"
      >
        <img src={WalletButton} alt="Apple Wallet Button" />
      </button>
    </div>
  );
};

const AppleWalletButton = ({ lang = 'EN', bookingId, ...rest }: AppleWalletButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onClick = () => {
    if (isDownloading) return;
    setIsDownloading(true);

    getLoungeAppleWalletPass({
      bookingId,
      isAmp: true,
    })
      .then((response) => {
        if (!response.data || !response?.headers || !response?.headers['content-disposition']) {
          throw new Error('No body or body headers found in response.');
        }

        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          (response.headers['content-disposition'] as string)
            .split('filename=')[1]
            .replace(/"/g, ''),
        );
        document.body.appendChild(link);

        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);

        yiluTracking.sendGAEvent({
          event: 'bcp-apple-wallet-add',
          category: `lounge-bcp`,
          label: `lounge-bcp-Awallet-add`,
        });
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  if (hasError) {
    return null;
  }

  const props = {
    ...rest,
    onClick,
    disabled: isDownloading,
    lang,
  };

  return <AppleWalletButtonAnchor {...props} />;
};

export default AppleWalletButton;
