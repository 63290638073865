/**
 * # Airport Transfer Product Details Page Hooks
 *
 *
 */
import { UiUtilities } from '@yiluhub/ui-sdk-react';
import {
  GetProductByRefinedSearchRequest,
  GetProductByRefinedSearchResponse,
  SearchItem,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCurrentProduct } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tzlookup from 'tz-lookup';

import { yiluEnv } from 'utils';
import { getURLSearchQuery } from 'utils/paramConverters';
import { sendGAEvent } from 'utils/tracking';

import routes from 'router/routes';

import {
  AirportTransferProductDetailsProps,
  AirportTransferProductSummaryProps,
} from 'modules/airport-transfer/components';
import { getAirportTransferProductData } from 'modules/airport-transfer/utils/getAmpAirportTransferData';

import { getAirportTransferProductDetailsPageQueryParams } from '../../utils/query-params';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');
/**
 * Retrieve data for the airport transfer product details page.
 */
export const useProductDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newPrice, setNewPrice] = useState<number>(0);
  const [searchedProduct, setSearchedProduct] = useState<SearchItem>();

  const airportTransferProductDetailsPageQueryParams = useMemo(() => {
    return getAirportTransferProductDetailsPageQueryParams(location);
  }, [location]);

  const { currentProduct } = useCurrentProduct(
    airportTransferProductDetailsPageQueryParams.searchResultID,
  );

  const currentAirportTransfer = getAirportTransferProductData(currentProduct);

  const isPageReady = Boolean(currentAirportTransfer);

  const sendPDPGAEvent = () => {
    sendGAEvent({
      event: 'pdp_cta_clicked',
      category: 'airport-transfer-pdp',
      label: 'airport_transfer_pdp_cta_clicked',
    });
  };

  const getAvailability = useCallback(
    (value: number) => {
      const { YILU_AMP_STORE_ID, YILU_AMP_BACKEND_URL } = yiluEnv.getVariables();
      const checkPromise = axios.get<GetProductByRefinedSearchResponse>(
        `${YILU_AMP_BACKEND_URL}` +
          `/product/v1/products/search-results/${currentAirportTransfer.id}/refined-search`,
        {
          params: {
            storeId: YILU_AMP_STORE_ID,
            numberOfTravelers: value,
          } as GetProductByRefinedSearchRequest['parameters']['query'],
        },
      );

      return checkPromise;
    },
    [currentAirportTransfer],
  );

  const onPriceChangeConfirm = useCallback(() => {
    const travellerInformationPageQueryParams = {
      searchResultID: searchedProduct?.id,
    };
    sendPDPGAEvent();
    navigate({
      pathname: routes.AIRPORT_TRANSFER_TIP,
      search: getURLSearchQuery(travellerInformationPageQueryParams),
    });
  }, [searchedProduct, navigate]);

  const verifyProductPrice = useCallback(() => {
    if (!loading) {
      setLoading(true);
      try {
        const product = getAvailability(currentAirportTransfer.travellersCount);

        product
          .then((res) => {
            const data = res.data ? res.data : undefined;
            if (!data) {
              setShowError(true);
              return false;
            }

            if (data.item.priceOptions[0].price.amount / 100 === currentAirportTransfer.price) {
              const travellerInformationPageQueryParams = {
                searchResultID: airportTransferProductDetailsPageQueryParams.searchResultID,
              };

              sendPDPGAEvent();
              navigate({
                pathname: routes.AIRPORT_TRANSFER_TIP,
                search: getURLSearchQuery(travellerInformationPageQueryParams),
              });
            } else {
              setSearchedProduct(data);
              setNewPrice(data.item.priceOptions[0].price.amount / 100);
              setIsModalVisible(true);
            }
          })
          .catch((e) => {
            setShowError(true);
            console.warn(e);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
        console.warn(e);
      }
    }
  }, [
    currentAirportTransfer,
    getAvailability,
    loading,
    navigate,
    airportTransferProductDetailsPageQueryParams,
  ]);

  const onTravellerChange = useCallback(
    (value: number) => {
      if (currentAirportTransfer?.travellersCount === value) {
        return false;
      }

      if (!loading) {
        setLoading(true);

        try {
          getAvailability(value)
            .then((res) => {
              const data = res.data ? res.data : undefined;
              if (!data) {
                setShowError(true);
                setLoading(false);
                return false;
              }

              const updatePDPQueryParams = {
                ...airportTransferProductDetailsPageQueryParams,
                searchResultID: data.id,
              };

              navigate(
                {
                  pathname: routes.AIRPORT_TRANSFER_PDP,
                  search: getURLSearchQuery(updatePDPQueryParams),
                },
                { replace: true },
              );

              setLoading(false);
              setShowError(false);
            })
            .catch((e) => {
              setShowError(true);
              console.warn(e);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (e) {
          setLoading(false);
          console.warn(e);
        }
      }
    },
    [
      currentAirportTransfer,
      loading,
      airportTransferProductDetailsPageQueryParams,
      navigate,
      getAvailability,
    ],
  );

  const airportTransferProductDetailsProps = useMemo(() => {
    if (!currentAirportTransfer) {
      return null;
    }

    const changes = currentAirportTransfer.interconnectionTransfersCount || 0;
    let transportComment;

    if (changes === 0) {
      transportComment = t('direct');
    }

    if (changes === 1) {
      transportComment = t('1 change');
    }

    if (changes > 1) {
      transportComment = t('{{interconnectionTransfersCount}} changes', {
        changesCount: changes,
      });
    }

    const _props: AirportTransferProductDetailsProps = {
      originDepartureTime: currentAirportTransfer.departureDateTime,
      originHighlight: currentAirportTransfer.origin.name || undefined,
      originAddress: currentAirportTransfer.origin.address || undefined,
      transportComment,
      destinationArrivalTime: currentAirportTransfer.arrivalDateTime,
      destinationHighlight: currentAirportTransfer.destination.name || undefined,
      destinationAddress: currentAirportTransfer.destination.address || undefined,
      displayingTimezone: tzlookup(
        currentAirportTransfer.origin.lat as number,
        currentAirportTransfer.origin.long as number,
      ),
      carrierName: currentAirportTransfer.carrierName || '',
      carrierLogoUrl: currentAirportTransfer.carrierLogoUrl || '',
      carrierLuggagePolicyUrl: currentAirportTransfer.carrierLuggagePolicyUrl || '',
    };

    return _props;
  }, [currentAirportTransfer, t]);

  const productPriceModalProps = useMemo(() => {
    const _props = {
      isModalVisible,
      oldPrice: currentProduct ? currentProduct.item.defaultPrice.price.amount / 100 : 0,
      newPrice,
      currency: currentProduct ? currentProduct.item.defaultPrice.price.currency : 'EUR',
      onPriceChangeConfirm,
      onCancel: () => {
        navigate(-1);
      },
    };
    return _props;
  }, [isModalVisible, currentProduct, newPrice, onPriceChangeConfirm, navigate]);

  const airportTransferProductSummaryProps = useMemo(() => {
    if (!currentAirportTransfer) {
      return null;
    }

    const _props: AirportTransferProductSummaryProps = {
      originName: currentAirportTransfer.origin.name!,
      destinationName: currentAirportTransfer.destination.name!,
      departureDateTime: currentAirportTransfer.departureDateTime,
      arrivalDateTime: currentAirportTransfer.arrivalDateTime,
      displayingTimezone: tzlookup(
        currentAirportTransfer.destination.lat as number,
        currentAirportTransfer.destination.long as number,
      ),
      price: currentAirportTransfer.price,
      currency: currentAirportTransfer.currency,
      travellersCount: currentAirportTransfer.travellersCount,
      carrierName: currentAirportTransfer.carrierName || '',
      interconnectionTransfersCount: currentAirportTransfer.interconnectionTransfersCount || 0,
      isLoading: loading,
      showError: showError,
      isDisabled: showError || loading,
      onTravellersCountChange: onTravellerChange,
      onClick() {
        verifyProductPrice();
      },
    };
    return _props;
  }, [currentAirportTransfer, loading, onTravellerChange, showError, verifyProductPrice]);

  const getTranslationForHeadingAndSubheading = () => {
    const currentDateInCEST = dayjs().tz('Europe/Berlin').format(UiUtilities.DateFormat.SHORT_DATE);
    let subheadingTranslation = 'bvgBanner.subheading.currentDate';
    // search is for a date in future
    if (
      dayjs(
        dayjs(currentAirportTransfer?.departureDateTime).format(UiUtilities.DateFormat.SHORT_DATE),
      ).isAfter(currentDateInCEST)
    ) {
      subheadingTranslation = 'bvgBanner.subheading.futureDate';
    }
    return subheadingTranslation;
  };

  return {
    isPageReady,
    productPriceModalProps,
    airportTransferProductDetailsProps,
    airportTransferProductSummaryProps,
    getTranslationForHeadingAndSubheading,
  };
};
