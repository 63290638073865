import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCustomerDetails } from '../../utils';
import { SwissTravelBooking } from '../types';

export type SBBBookingInfoProps = {
  booking: SwissTravelBooking;
};

const SBBBookingInfo: React.FC<SBBBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bookingData = booking.product.data!;

  return (
    <>
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[UiUtilities.formatLocalDate(bookingData.departureTime, 'dddd D MMMM YYYY')]}
        subText={t('bcp.sbb.startDate')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      <ElementsSDK.ContentBox
        mainTexts={getCustomerDetails(bookingData.customerDetails)}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[`${bookingData.passInfo.duration.toString()} ${t('sbb.travel_pass_days')}`]}
        subText={t('bcp.sbb.duration')}
        icon={<ElementsSDK.Icon.Time />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[
          bookingData.passInfo.fareClass === 1
            ? t('sbb.card_first_class_text')
            : t('sbb.card_second_class_text'),
        ]}
        subText={t('bcp.sbb.fare_class.text')}
        icon={<ElementsSDK.Icon.Lounge />}
      />

      <ElementsSDK.ContentBox
        mainTexts={[`${bookingData.numberOfPassengers}`]}
        subText={t('bcp.sbb.no_of_passengers.text')}
        icon={<ElementsSDK.Icon.Person />}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SBBBookingInfo;
