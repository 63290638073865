import {
  BookingIntent,
  BookingIntentField,
  BookingIntentProduct,
  CreateBookingIntentRequest,
  Response,
  UpdateBookingIntentRequest,
} from '@yiluhub/network-sdk';
import {
  AddShoppingCartItemRequest,
  AddShoppingCartItemResponse,
  BookingIntentDto,
  CreateBookingIntentResponse,
  CreateShoppingCartResponse,
  GetBookingIntentResponse,
  PatchBookingIntentRequest,
  PriceOption,
  CreateBookingIntentRequest as ampCreateBookingIntentRequest,
} from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { VariantId, integrateABTesting } from 'utils/abTest';
import { extractAttributeFromBookingIntent } from 'utils/ampUtils';
import { getVariables } from 'utils/yiluEnv';
import { getRESTClient } from 'utils/yiluSdk';

import { AirlineGroup } from 'components/TravellerInformationForm/Lounge/LHGForm';
import { LoungeFieldName } from 'components/TravellerInformationForm/Lounge/types';
import { getAirlineModal } from 'components/TravellerInformationForm/utils/modal/getAirlineModal';
import { getFrankfurtLoungeModal } from 'components/TravellerInformationForm/utils/modal/getFrankfurtLoungeModal';
import { getLoungePEModal } from 'components/TravellerInformationForm/utils/modal/getLoungePEModal';

import {
  AMP_LOUNGE_PRICING_OPTION,
  FRANKFURT_LOUNGE_ID,
  LOUNGES_WITH_WARNING,
} from 'modules/lounges/utils/constants';
import { isSpLHG } from 'modules/lounges/utils/getProductDetails';

import {
  EMAIL_NAME,
  IS_ECONOMY_PREMIUM,
  PHONE_NUMBER_NAME,
  getBookingIntentFieldsPayload,
  getFieldsDefinitions,
} from '../utils';
import { FormError } from './types';

export type onUpdateBookingIntentSuccessData = {
  created: string;
  providerId: string;
  bookingIntentId: string;
  type: AMP_VERTICALS | 'EXPERIENCE';
  price: number;
  currency: string;
  product: BookingIntentProduct<any>; // NOTE: fix during the network-sdk deco
  fields: BookingIntentField[];
  owner: {
    email?: string;
    phoneNumber?: string;
  };
  shoppingCartId?: string;
};

export type UseReservationParams<T extends AMP_VERTICALS | 'EXPERIENCE'> = {
  type: T;
  iata?: string;
  shoppingCartItemRequestBody?: AddShoppingCartItemRequest['requestBody'];
  createBookingIntentRequestBody: CreateBookingIntentRequest<any>; // NOTE: fix during the network-sdk deco
  bookingIntent?: BookingIntent;
  fieldValues?: any;
  onUpdateBookingIntentError(error: Error): void;
  onUpdateBookingIntentSuccess(data: onUpdateBookingIntentSuccessData): unknown;
  onUpdateBookingIntentRequestStateChange?(isLoading: boolean): unknown;
  onInitBookingIntentError?: (error: Error) => unknown;
  formError: FormError;
  setFormError: React.Dispatch<React.SetStateAction<FormError>>;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getValues: UseFormGetValues<FieldValues>;
  isModalConfirmedRef: React.MutableRefObject<boolean>;
  setIsModalConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
};

export type WarningModalType = {
  props?: {
    title: string;
    confirmText: string;
    cancelText: string;
  };
  children: React.ReactNode;
};

export const useReservation = ({
  type,
  iata,
  shoppingCartItemRequestBody,
  createBookingIntentRequestBody,
  onUpdateBookingIntentError,
  onUpdateBookingIntentSuccess,
  onUpdateBookingIntentRequestStateChange,
  onInitBookingIntentError,
  formError,
  setFormError,
  getValues,
  setIsModalVisible,
  isModalConfirmedRef,
  setIsModalConfirmed,
}: UseReservationParams<AMP_VERTICALS | 'EXPERIENCE'>) => {
  const { t } = useTranslation();
  // internal bookingIntent
  const [bookingIntent, setBookingIntent] = useState<BookingIntent | BookingIntentDto | null>(null);
  const [shoppingCartInternal, setShoppingCartInternal] = useState<string | undefined>(undefined);
  const [shoppingCartItemInternal, setShoppingCartItemInternal] = useState<string | undefined>(
    undefined,
  );
  const [isCreatingBookingIntent, setIsCreatingBookingIntent] = useState(false);
  const [isUpdatingBookingIntent, setIsUpdatingBookingIntent] = useState(false);

  const [warningModalProps, setWarningModalProps] = useState<WarningModalType | undefined>();
  const yiluEnv = getVariables();

  const isAmpEnabled = !Boolean(type === 'EXPERIENCE');

  /**
   * Create the booking intent if it doesn't exist
   */
  useEffect(() => {
    const createShoppingCart = async (): Promise<string> => {
      try {
        const response = await axios.post<CreateShoppingCartResponse>(
          `${yiluEnv.YILU_AMP_BACKEND_URL}/shopping-cart/v1/shopping-carts`,
        );
        const body = response.data;

        return body.id;
      } catch (error) {
        throw new Error('Error creating shopping cart');
      }
    };

    const addItemToShoppingCart = async (shoppingCartId: string): Promise<string> => {
      try {
        const response = await axios.post<AddShoppingCartItemResponse>(
          `${yiluEnv.YILU_AMP_BACKEND_URL}/shopping-cart/v1/shopping-carts/${shoppingCartId}/item`,
          shoppingCartItemRequestBody,
        );
        const body = response.data;

        return body.id;
      } catch (error) {
        throw new Error('Error adding item to shopping cart');
      }
    };

    const createBookingIntent = async (shoppingCartId: string) => {
      try {
        const bookingIntent = await axios.post<CreateBookingIntentResponse>(
          `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/booking-intents`,
          {
            shoppingCartId,
          } as ampCreateBookingIntentRequest['requestBody'],
        );
        return bookingIntent.data;
      } catch (error) {
        throw new Error('Error creating booking intent');
      }
    };

    if (bookingIntent || formError.hasError) {
      return;
    }

    if (isAmpEnabled) {
      if (!shoppingCartInternal && !isCreatingBookingIntent) {
        (async () => {
          setIsCreatingBookingIntent(true);
          try {
            const shoppingCartId = await createShoppingCart();
            const shoppingCartItemId = await addItemToShoppingCart(shoppingCartId);
            const bookingIntent = await createBookingIntent(shoppingCartId);
            setBookingIntent(bookingIntent);
            setShoppingCartInternal(shoppingCartId);
            setShoppingCartItemInternal(shoppingCartItemId);
          } catch (error: any) {
            setFormError({
              hasError: true,
              type: 'API',
              name: error.name,
              message: error.message,
            });
            if (typeof onInitBookingIntentError === 'function') {
              onInitBookingIntentError(error);
            }
          } finally {
            setIsCreatingBookingIntent(false);
          }
        })();
      }
    } else {
      const client = getRESTClient();
      client
        .createBookingIntent(createBookingIntentRequestBody as any) // NOTE: fix during the network-sdk deco
        .then((response) => {
          if (!response.body) {
            throw Error('Missing response body after creating a booking intent');
          }
          return response.body.id;
        })
        .then((bookingIntentId) => client.getBookingIntent({ bookingIntentId }))
        .then((response) => {
          if (!response.body) {
            throw Error('Missing response body of retrieving a booking intent');
          }
          setBookingIntent(response.body);
        })
        .catch((error) => {
          setFormError({
            type: 'API',
            hasError: true,
            name: error.name,
            message: error.message,
          });
          if (typeof onInitBookingIntentError === 'function') {
            onInitBookingIntentError(error);
          }
        });
    }
  }, [
    bookingIntent,
    shoppingCartInternal,
    isCreatingBookingIntent,
    createBookingIntentRequestBody,
    isAmpEnabled,
    onInitBookingIntentError,
    formError,
    setFormError,
    shoppingCartItemRequestBody,
    yiluEnv.YILU_AMP_BACKEND_URL,
  ]);

  /**
   * Notify the sdk consumer that the update bookingIntent request either started or finished
   */
  useEffect(() => {
    if (typeof onUpdateBookingIntentRequestStateChange === 'function') {
      onUpdateBookingIntentRequestStateChange(isUpdatingBookingIntent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatingBookingIntent]);

  /**
   * Update the booking intent with provided information
   */
  const handleFormSubmit = useCallback(
    (data: FieldValues) => {
      // for some reason bookingIntent doesn't get populated so therefore using the prop directly.
      if (!bookingIntent) {
        return;
      }
      const isPremiumEconomy = getValues(IS_ECONOMY_PREMIUM);
      const isAirlineGroupLufthansa =
        getValues(LoungeFieldName.AIRLINE_GROUP) === AirlineGroup.LUFTHANSA;
      const isOtherAirlineGroup = getValues(LoungeFieldName.AIRLINE_GROUP) === AirlineGroup.OTHER;

      const abTestingCookie = integrateABTesting.getCookie();
      const isVariantA = !abTestingCookie || abTestingCookie === VariantId.VARIANT_A;

      const isLufthansaTicketModalVisible =
        (isAirlineGroupLufthansa || isOtherAirlineGroup) &&
        iata &&
        LOUNGES_WITH_WARNING.includes(iata) &&
        isVariantA;

      const isFrankfurtModalVisible =
        (bookingIntent as BookingIntentDto).items[0].product.productId === FRANKFURT_LOUNGE_ID;

      let modalProps = null;

      //get modal content
      if (isPremiumEconomy) {
        modalProps = getLoungePEModal({ t });
      } else if (isLufthansaTicketModalVisible) {
        modalProps = getAirlineModal({ t });
      } else if (isFrankfurtModalVisible) {
        modalProps = getFrankfurtLoungeModal({ t });
      }

      //show modal if needed
      if (modalProps && !isModalConfirmedRef.current) {
        setWarningModalProps(modalProps);
        setIsModalVisible(true);
        return;
      }

      setIsUpdatingBookingIntent(true);

      const customerFields = {} as any;
      Object.keys(data).forEach((key) => {
        if (type === AMP_VERTICALS.PARKING) {
          // denormalise keys (used only for Parking)
          customerFields[key.replace('_', '.')] = data[key];
        } else {
          customerFields[key] = data[key];
        }
      });

      // firstName isn't required for LHG Lounges on the FE but it is on the BE
      if (
        type === AMP_VERTICALS.LOUNGES &&
        isSpLHG(createBookingIntentRequestBody.serviceProvider)
      ) {
        customerFields['firstName'] = 'n-a';
      }

      const bookingIntentFieldsPayload = getBookingIntentFieldsPayload(
        customerFields,
        bookingIntent,
        isAmpEnabled,
      );

      let updateBookingIntentCall:
        | Promise<{ body: BookingIntent }>
        | Promise<Response<BookingIntent>>
        | undefined;

      if (isAmpEnabled) {
        // update and then use get call.
        updateBookingIntentCall = axios
          .patch(`${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/booking-intents/${bookingIntent.id}`, {
            fields: bookingIntentFieldsPayload,
          } as PatchBookingIntentRequest['requestBody'])
          .then(async () => {
            // Call the PUT endpoint in order to update the selected price option in the shopping cart
            // As of now there is only dynamic pricing in Lounges
            if (type === AMP_VERTICALS.LOUNGES) {
              const priceOptionName = isOtherAirlineGroup
                ? AMP_LOUNGE_PRICING_OPTION.OTHER_AIRLINE
                : isPremiumEconomy
                ? AMP_LOUNGE_PRICING_OPTION.ECONOMY_PREMIUM
                : AMP_LOUNGE_PRICING_OPTION.DEFAULT;
              const selectedPriceOption = (
                bookingIntent as BookingIntentDto
              ).items[0].product.priceOptions.find(
                (option: PriceOption) => option.name === priceOptionName,
              );
              await axios.put(
                `${yiluEnv.YILU_AMP_BACKEND_URL}/shopping-cart/v1/shopping-carts/${shoppingCartInternal}/items/${shoppingCartItemInternal}`,
                {
                  selectedPriceId: selectedPriceOption?.id,
                },
                {
                  params: {
                    'store-id': yiluEnv.YILU_AMP_STORE_ID,
                  },
                },
              );
            }

            // Proceed with the GET call to retrieve the updated booking intent
            const res = await fetch(
              `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/booking-intents/${bookingIntent.id}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            );
            // map CreateBookingIntentResponse to BookingIntent
            return new Promise(async (resolve) => {
              const ampBookingIntent: GetBookingIntentResponse = await res.json();
              const mappedBookingIntent = {
                id: ampBookingIntent.id,
                fields: ampBookingIntent.travellerInfo,
                createdAt: new Date(),
                serviceProvider: extractAttributeFromBookingIntent(
                  ampBookingIntent,
                  'service_provider',
                ),
                price: {
                  amount: ampBookingIntent.items[0].price.amount,
                  currency: ampBookingIntent.items[0].price.currency,
                },
              };
              resolve({ body: mappedBookingIntent as any as BookingIntent });
            });
          })
          .catch((err: any) => {
            console.error('Unable to update booking intent', err);
          }) as Promise<{ body: BookingIntent }> | undefined;
      } else {
        const client = getRESTClient();
        const bookingIntentPayload: UpdateBookingIntentRequest = {
          bookingIntentId: bookingIntent.id,
          serviceProvider: (bookingIntent as BookingIntent).serviceProvider,
          fields: getBookingIntentFieldsPayload(
            customerFields,
            bookingIntent,
            isAmpEnabled,
          ) as BookingIntentField[],
        };
        updateBookingIntentCall = client.updateBookingIntent(bookingIntentPayload).then(() => {
          return client.getBookingIntent({
            bookingIntentId: bookingIntent.id,
          });
        }) as Promise<Response<BookingIntent>>;
      }
      updateBookingIntentCall &&
        updateBookingIntentCall
          .then((response) => {
            if (!response.body) {
              throw new Error('Missing booking intent response load');
            }
            const bookingIntent: BookingIntent = response.body;
            const owner: onUpdateBookingIntentSuccessData['owner'] = {};
            const bookingFields = bookingIntent.fields;
            bookingFields.forEach((field) => {
              if (field.name === EMAIL_NAME) {
                owner.email = field.value as string;
              }
              if (field.name === PHONE_NUMBER_NAME) {
                owner.phoneNumber = field.value as string;
              }
            });

            const reservationResponse = {
              created: bookingIntent.createdAt,
              providerId: bookingIntent.serviceProvider,
              bookingIntentId: bookingIntent.id,
              type,
              price: bookingIntent.price.amount,
              currency: bookingIntent.price.currency,
              product: createBookingIntentRequestBody.product,
              owner,
              fields: bookingIntent.fields,
              shoppingCartId: shoppingCartInternal,
            };
            if (typeof onUpdateBookingIntentSuccess === 'function') {
              onUpdateBookingIntentSuccess(reservationResponse);
            }
          })
          .catch((error) => {
            setFormError({
              type: 'API',
              hasError: true,
              name: error.name,
              message: error.message,
            });
            if (typeof onUpdateBookingIntentError === 'function') {
              onUpdateBookingIntentError(error);
            }
          })
          .finally(() => {
            setIsUpdatingBookingIntent(false);
            setIsModalConfirmed(false);
          });
    },
    [
      shoppingCartInternal,
      shoppingCartItemInternal,
      bookingIntent,
      createBookingIntentRequestBody,
      onUpdateBookingIntentError,
      onUpdateBookingIntentSuccess,
      yiluEnv.YILU_AMP_STORE_ID,
      yiluEnv.YILU_AMP_BACKEND_URL,
      iata,
      isAmpEnabled,
      setIsModalVisible,
      isModalConfirmedRef,
      setIsModalConfirmed,
      setFormError,
      getValues,
      t,
      type,
    ],
  );
  return {
    fields: getFieldsDefinitions(bookingIntent, isAmpEnabled),
    bookingIntent,
    handleFormSubmit,
    warningModalData: warningModalProps,
  };
};
