/**
 * # Parking Booking Confirmation
 *
 *
 */
import { ParkingBooking } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import { BookingStateHeader, Container, Terms } from '../components';
import { useGetBooking } from '../hooks/useGetBooking';
import '../styles.scss';
import ParkingBookingInfo from './ParkingBookingInfo';
import { ParkingBookingConfirmationProps } from './types';

export const ParkingBookingConfirmation: React.FC<ParkingBookingConfirmationProps> = ({
  bookingId,
  onError,
  onResponse,
}) => {
  const { t } = useTranslation();
  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
    isAmpEnabled: true,
  });

  const parkingBooking = booking as ParkingBooking | null;

  const isParkVia =
    parkingBooking?.serviceProvider === 'PARK_VIA' ||
    parkingBooking?.serviceProvider === 'Park Via' ||
    parkingBooking?.serviceProvider === 'ParkVia';

  const showParkAeroPrintButton =
    yiluEnv.getVariables().YILU_SHOW_PARK_AERO_BCP_PRINT_BUTTON === 'true';

  const getEmailCheck = (parkingBooking: ParkingBooking) => {
    let provider = '';

    if (isParkVia) {
      provider = 'ParkVia';
    } else {
      //The address from Park Aero will always be the Airport's name
      provider = parkingBooking.product.data?.parkingDetails.location.address || 'POI';
    }

    return t('parking.bcp.successMessage', {
      email: parkingBooking?.product.data?.customerDetails.email,
      provider: provider,
    });
  };

  return (
    <>
      <BookingStateHeader
        bookingState={parkingBooking ? parkingBooking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.headerTitle'),
            subtitle: t('bcp.parking.headerSubtitle'),
          },
        }}
      />
      <Container>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-payment-bcp-formLoadingIndicator__loader" />
        ) : (
          <>
            {parkingBooking && parkingBooking.product.data && (
              <>
                <ElementsSDK.Message
                  className="yilu-payment-bcp-success__message"
                  type={ElementsSDK.MessageType.SUCCESS}
                  content={getEmailCheck(parkingBooking)}
                />
                {(showParkAeroPrintButton || isParkVia) && (
                  <ElementsSDK.Button
                    className="yilu-payment-bcp-save-confirmation_button"
                    Icon={isParkVia ? ElementsSDK.Icon.LinkOut : ElementsSDK.Icon.Download}
                    iconPosition={
                      isParkVia
                        ? ElementsSDK.ButtonIconPositions.RIGHT
                        : ElementsSDK.ButtonIconPositions.LEFT
                    }
                    onClick={() => {
                      if (isParkVia) {
                        window.open(parkingBooking.product.data?.bookingConfirmationUrl, '_blank');
                      } else {
                        window.print();
                      }
                    }}
                  >
                    {!isParkVia ? (
                      <>{t('parking.bcp.save.confirmation.label')}</>
                    ) : (
                      <>{t('parking.bcp.view.voucher.label')}</>
                    )}
                  </ElementsSDK.Button>
                )}
                <ElementsSDK.Divider />
                <div className="yilu-payment-bcp-booking-info">
                  <ParkingBookingInfo booking={parkingBooking} />
                </div>
                <Terms opensNewtab termsUrl={parkingBooking.product.data.parkingDetails.termsUrl} />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
