/**
 * # Parking Payment Input Page Hooks
 *
 *
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { paramConverters, yiluEnv, yiluTracking } from 'utils';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { ExperienceProductSummaryProps } from 'modules/experiences/components';

import { getExperiencePaymentInputPageQueryParams } from '../../utils/query-params';
import {
  getSessionBookingState,
  sessionBookingState,
  setSessionBookingState,
} from '../../utils/sessionUtilities';

/**
 * Retrieve data for the experience payment input page.
 */
export const useExperiencePaymentInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isPageReady, setIsPageReady] = useState(false);

  const experiencePaymentInputPageQueryParams = useMemo(() => {
    return getExperiencePaymentInputPageQueryParams(location);
  }, [location]);

  useEffect(() => {
    const { product } = experiencePaymentInputPageQueryParams;
    if (getSessionBookingState(product.bookingSessionId) !== sessionBookingState.RESERVED) {
      return navigate(
        {
          pathname: routes.EXPERIENCES_SEARCH,
        },
        { replace: true },
      );
    }
    setIsPageReady(true);
  }, [experiencePaymentInputPageQueryParams, navigate]);

  const paymentFormRef = useRef<HTMLFormElement>(null);

  const paymentInformationFormProps = useMemo(() => {
    const { reservation, product } = experiencePaymentInputPageQueryParams;
    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      productDetails: {
        trackingId: 'tickets',
        providerId: reservation.providerId,
        price: reservation.price,
        currency: reservation.currency,
      },
      paymentDetails: {
        userId: experiencePaymentInputPageQueryParams.userId as string,
        bookingIntentId: reservation.bookingIntentId,
        touchPointId: experiencePaymentInputPageQueryParams.touchpoint as string,
      },
      isAmpEnabled: false,
      isUsingPaymentIntentApi: true,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        yiluTracking.sendPaymentSuccessfulEvent(reservation.bookingIntentId);
        setSessionBookingState(product.bookingSessionId, sessionBookingState.PAID);

        navigate(
          {
            pathname: routes.EXPERIENCES_BCP,
            search: paramConverters.getURLSearchQuery(bookingConfirmationPageQueryParams),
          },
          { replace: true },
        );
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [experiencePaymentInputPageQueryParams, navigate]);

  const experienceProductSummaryProps = useMemo(() => {
    const { reservation, product } = experiencePaymentInputPageQueryParams;
    const termsUrl = getCorasTermsAndConditionsURL(
      experiencePaymentInputPageQueryParams.locale as string,
    );

    const _props: ExperienceProductSummaryProps = {
      price: reservation.price,
      currency: reservation.currency,
      eventDateTime: product.startDateTime,
      eventName: product.name,
      travellersCount: product.totalTicketsNumber,
      carrierName: 'Coras',
      carrierLogoUrl: 'https://coras.io/images/favicons/apple-touch-icon-57x57.png',
      termsDetails: {
        termsUrl,
        productDetails: {
          providerId: reservation.providerId,
        },
      },
      onClick() {
        if (paymentFormRef && paymentFormRef.current) {
          const paymentForm = paymentFormRef.current;
          paymentForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
        }
      },
      isDisabled: false,
      submitBtnLabel: t('Pay now'),
    };
    return _props;
  }, [experiencePaymentInputPageQueryParams, paymentFormRef, t]);

  return {
    paymentInformationFormProps,
    experienceProductSummaryProps,
    isPageReady,
  };
};

/**
 * Retrieve the terms and conditions URL from the Coras experience service provider.
 *
 * @param locale - language identifier
 */
function getCorasTermsAndConditionsURL(locale: string) {
  const yiluVariables = yiluEnv.getVariables();
  const baseUrl = `https://${yiluVariables.CORAS_PARTNER_ID}.coras.io`;

  let termsUrl = `${baseUrl}/ie/en/terms`;

  if (locale === 'de') {
    termsUrl = `${baseUrl}/de/de/terms`;
  }

  return termsUrl;
}
