/**
 * # Lounge Search Results
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { dateToWeekIndex } from '@yiluhub/ui-utilities';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchResultCard, SearchResultNotFound } from 'components';

import { isHolidayExtrasAvailable } from 'modules/lounges/utils/availability';
import { filterLoungeSearchResults } from 'modules/lounges/utils/filterLoungeSearchResults';
import { isSearchItemHolidayExtras } from 'modules/lounges/utils/getProductDetails';

import { LoungeSearchResultCard } from '../LoungeSearchResultCard';
import { useLoungeSearchResultsLoader } from './hooks';
import { LoungeSearchResultsProps } from './types';

const PLACEHOLDER_COUNT = 3;

export const LoungeSearchResults: React.FC<LoungeSearchResultsProps> = ({
  searchParams,
  onSearchResults,
  onSearchResultClick,
  onError,
  onLoading,
}) => {
  const { t } = useTranslation();
  const { isLoading, pageNumber, totalPages, setLastElement, searchResults } =
    useLoungeSearchResultsLoader({
      searchParams,
      onSearchResults,
      onError,
      onLoading,
    });
  const searchedDateOfWeek = dateToWeekIndex(searchParams.entryDate);
  const filteredResults = filterLoungeSearchResults(searchResults, searchParams);

  const travellersCount =
    searchParams.adults + (searchParams.children || 0) + (searchParams.infants || 0);

  const showSameDayBookingsMessage = filteredResults.some((result) => {
    return isSearchItemHolidayExtras(result) && !isHolidayExtrasAvailable(result.item.startDate);
  });

  return (
    <>
      {!isLoading && !filteredResults.length && (
        <SearchResultNotFound
          title={t('Lounges no results found')}
          message={t('Lounges no results found message')}
        />
      )}
      {!isLoading && !!filteredResults?.length && showSameDayBookingsMessage && (
        <ElementsSDK.Message
          type={ElementsSDK.MessageType.INFORMATIVE}
          content={t('Lounges.sameDayBookings')}
        ></ElementsSDK.Message>
      )}
      {isLoading &&
        pageNumber === 0 &&
        Array(PLACEHOLDER_COUNT)
          .fill(0)
          .map((_, index) => <SearchResultCard asPlaceholder key={index} />)}
      <section>
        {filteredResults.map((searchResult: SearchItem, index: number) => {
          const isLastElement =
            index === filteredResults.length - 1 && !isLoading && pageNumber < totalPages - 1;
          return (
            <LoungeSearchResultCard
              cardRef={isLastElement ? setLastElement : undefined}
              key={searchResult.id}
              data={searchResult}
              searchedDateOfWeek={searchedDateOfWeek}
              searchedTravellersCount={travellersCount}
              onClick={onSearchResultClick}
            />
          );
        })}
        {isLoading && pageNumber !== 0 && <SearchResultCard asPlaceholder />}
      </section>
    </>
  );
};
