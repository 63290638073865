/**
 * # Use Lounge Product
 * Store used in Lounges PDP when redirecting from QR codes.
 * ex. https://simpletrip.travelintegration.com/lounges/product?loungeId=10
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLanguage } from 'utils/yiluSdk';

import {
  loadAmpLoungeProduct,
  selectIsLoungeProductLoading,
  selectLoungeProduct,
  selectLoungeProductError,
} from 'store/features/LoungeProduct';

/**
 * Use lounge product hook
 * Fetches lounge product data for PDP deeplinking and walk-ins
 *
 * @param loungeId - lounge product identifier
 * @param travellersCount
 * @param entryDate - date of entry
 * @param coordinates
 */
export function useLoungeProduct(
  loungeId?: string,
  travellersCount?: {
    adults?: number;
    children?: number;
    infants?: number;
  },
  entryDate?: string,
  coordinates?: ElementsSDK.Coordinates,
) {
  const dispatch = useDispatch();
  const selectedLoungeProduct = useSelector(selectLoungeProduct);
  const selectedLoungeProductId = (selectedLoungeProduct as SearchItem)?.item.productId;
  const loungeProduct =
    selectedLoungeProduct && selectedLoungeProductId === loungeId
      ? selectedLoungeProduct
      : undefined;
  const isLoungeProductLoading = useSelector(selectIsLoungeProductLoading);
  const loungeProductError = useSelector(selectLoungeProductError);

  useEffect(() => {
    if (loungeProduct || !loungeId) {
      return;
    }
    dispatch(
      loadAmpLoungeProduct(loungeId, getCurrentLanguage(), travellersCount, entryDate, coordinates),
    );
  }, [loungeProduct, loungeId, dispatch, travellersCount, entryDate, coordinates]);

  return {
    loungeProduct,
    isLoungeProductLoading,
    loungeProductError,
  };
}
