/**
 * # Booking Confirmation (Exports)
 *
 *
 */
import { AirportTransferBookingConfirmation } from './AirportTransfer';
import { ExperienceBookingConfirmation } from './Experience';
import { LoungeBookingConfirmation } from './Lounge';
import { ParkingBookingConfirmation } from './Parking';
import { RidesBookingConfirmation } from './Rides';

export * from './Lounge/types';
export * from './Parking/types';
export * from './Experience/types';
export * from './Rides/types';
export * from './AirportTransfer/types';

export const BookingConfirmation = {
  AirportTransfer: AirportTransferBookingConfirmation,
  Rides: RidesBookingConfirmation,
  Parking: ParkingBookingConfirmation,
  Lounge: LoungeBookingConfirmation,
  Experience: ExperienceBookingConfirmation,
};
