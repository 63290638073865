import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const ExperiencesBottomBanner: React.FC<{ partnerId: string }> = ({ partnerId }) => {
  const { t } = useTranslation();

  return (
    <ElementsSDK.BottomBanner className="experiences-bottom-banner">
      <span
        dangerouslySetInnerHTML={{ __html: t(`experiences.bottom.banner.text.${partnerId}`) }}
      />
      <span>
        <ElementsSDK.Link
          href={t(`experiences.bottom.banner.link.href.${partnerId}`)}
          hasDefaultIcon
          iconOnRight
          inline
          target="_blank"
        >
          {t(`experiences.bottom.banner.link.text`)}
        </ElementsSDK.Link>
      </span>
    </ElementsSDK.BottomBanner>
  );
};

export default ExperiencesBottomBanner;
