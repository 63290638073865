/**
 * # Rides Booking Info
 *
 *
 */
import { RideBooking, UnifiedLocation } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat, formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tzlookup from 'tz-lookup';

import { getCustomerDetails } from '../../utils';
import './styles.scss';

export type RidesBookingInfoProps = {
  booking: RideBooking;
};

const RidesBookingInfo: React.FC<RidesBookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bookingData = booking.product.data!;

  const originAddress = bookingData.origin && (bookingData.origin as UnifiedLocation).plainAddress;
  const destinationAddress =
    bookingData.destination && (bookingData.destination as UnifiedLocation).plainAddress;
  const travelClass = bookingData?.rideDetails?.category;
  const originTimezone =
    bookingData.origin &&
    tzlookup(
      (bookingData.origin as UnifiedLocation).latitude,
      (bookingData.origin as UnifiedLocation).longitude,
    );

  return (
    <>
      <ElementsSDK.ContentBox
        mainTexts={[bookingData.bookingId]}
        subText={t('bcp.bookingId')}
        icon={<ElementsSDK.Icon.Receipt />}
      />
      <ElementsSDK.ContentBox
        mainTexts={[
          formatLocalDate(bookingData.departureTime, DateFormat.DATE_WITH_TIME, originTimezone),
        ]}
        subText={t('bcp.dateOfPickUp')}
        icon={<ElementsSDK.Icon.Calendar />}
      />
      {originAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[originAddress]}
          subText={t('bcp.departure')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      {destinationAddress && (
        <ElementsSDK.ContentBox
          mainTexts={[destinationAddress]}
          subText={t('bcp.arrival')}
          icon={<ElementsSDK.Icon.Pin />}
        />
      )}
      {travelClass && (
        <ElementsSDK.ContentBox
          mainTexts={[travelClass]}
          className="bcp-travel-class"
          subText={t('bcp.travelClass')}
          icon={<ElementsSDK.Icon.Taxi />}
        />
      )}
      {bookingData.rideDetails.notesToDriver &&
        bookingData.rideDetails.notesToDriver !== 'null' && (
          <ElementsSDK.ContentBox
            mainTexts={[bookingData.rideDetails.notesToDriver]}
            subText={t('bcp.messageToDriver')}
            icon={<ElementsSDK.Icon.Note />}
          />
        )}
      <ElementsSDK.ContentBox
        mainTexts={getCustomerDetails(bookingData.customerDetails)}
        subText={t('bcp.mainTraveller')}
        icon={<ElementsSDK.Icon.Person />}
      />
    </>
  );
};

export default RidesBookingInfo;
