import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { GetProductsRequest, GetProductsResponse, SearchItem } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import spacetime from 'spacetime';
import tzlookup from 'tz-lookup';

import { yiluEnv } from 'utils';
import { getCurrentLanguage } from 'utils/yiluSdk';

const DEFAULT_PAGE_SIZE = 10;

export type UseParkingSearchResultsLoaderParams = {
  searchParams: {
    entryDate: string;
    entryTime: string;
    exitDate: string;
    exitTime: string;
    coordinates: ElementsSDK.Coordinates;
    airportIataCode: string;
  };
  onSearchResults?: (results: SearchItem[]) => unknown;
  onError?: (error: Error) => unknown;
  pageSize?: number;
};

export function useParkingSearchResultsLoader({
  searchParams,
  onSearchResults,
  onError,
  pageSize = DEFAULT_PAGE_SIZE,
}: UseParkingSearchResultsLoaderParams) {
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchID, setSearchID] = useState<string>();
  const [refreshPagination, setRefreshPagination] = useState(false);

  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_PARKING_VERTICAL_ID } =
    yiluEnv.getVariables();

  const { coordinates, entryDate, entryTime, exitDate, exitTime, airportIataCode } = searchParams;

  const dayjs = UiUtilities.getDayjs();

  const fetchParking = useCallback(
    async (pageNumber: number) => {
      try {
        const timezone = tzlookup(coordinates.latitude, coordinates.longitude);
        const request: GetProductsRequest['query'] = {
          storeId: YILU_AMP_STORE_ID,
          verticalId: YILU_AMP_PARKING_VERTICAL_ID,
          airport: airportIataCode,
          pageNumber,
          pageSize,
          searchID,
          startAt: dayjs(spacetime(entryDate, timezone).time(entryTime).epoch)
            .toISOString()
            .replace(/.\d+Z$/g, 'Z'),
          endAt: dayjs(spacetime(exitDate, timezone).time(exitTime).epoch)
            .toISOString()
            .replace(/.\d+Z$/g, 'Z'),
          locale: getCurrentLanguage(),
        };
        const response = await axios.get<GetProductsResponse>(
          `${YILU_AMP_BACKEND_URL}/product/v1/products`,
          {
            params: request,
          },
        );
        const body = response.data;
        const newSearchResults = body.items;
        const responsePage = body.page;

        const totalPages = Math.ceil(responsePage.total / responsePage.pageSize);
        setTotalPages(totalPages);
        setSearchID(body.searchID);

        if (onSearchResults) {
          onSearchResults(newSearchResults);
        }
        const oldSearchResults = searchResults;
        const combinedSearchResults = [...oldSearchResults, ...newSearchResults];
        setSearchResults(combinedSearchResults);
      } catch (error) {
        console.error('Error loading lounge search results', error);
      } finally {
        setIsLoading(false);
        setRefreshPagination(false);
      }
    },
    [
      coordinates,
      searchResults,
      onSearchResults,
      YILU_AMP_BACKEND_URL,
      YILU_AMP_STORE_ID,
      YILU_AMP_PARKING_VERTICAL_ID,
      airportIataCode,
      entryDate,
      entryTime,
      exitDate,
      exitTime,
      dayjs,
      searchID,
      pageSize,
    ],
  );

  const loadResults = async (pageNumber: number) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    await fetchParking(pageNumber);
    return;
  };

  const { setLastElement, pageNumber } = UiUtilities.useObserverPagination({
    loadResults,
    totalPages,
    refreshPagination,
  });

  useEffect(() => {
    setSearchResults([]);
    setRefreshPagination(true);
    setSearchID(undefined);
  }, [searchParams]);

  return {
    isLoading,
    searchResults,
    totalPages,
    pageNumber,
    setLastElement,
  };
}
