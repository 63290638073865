import { Booking, BookingState, Response } from '@yiluhub/network-sdk';
import { GetBookingByBookingIdResponse } from '@yiluhub/yilu-amp-types';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { getVariables } from 'utils/yiluEnv';
import { getRESTClient } from 'utils/yiluSdk';

const POLLING_DELAY_TIME = 2000;
const MAX_POLLING_NUMBER_OF_RETRIES = 15;

export type UseGetBookingParams = {
  bookingId: string;
  isAmpEnabled: boolean;
  onError?: (error: Error) => unknown;
  onResponse?: (booking: Booking) => unknown;
};

type BookingInput = { bookingId: string };

export function useGetBooking({
  bookingId,
  onError,
  onResponse,
  isAmpEnabled,
}: UseGetBookingParams) {
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const isMounted = useRef<boolean>(false);
  const poll = useRef<NodeJS.Timeout | null>(null);
  const yiluEnv = getVariables();

  let retryCount = 0;

  const stopPolling = () => {
    if (isMounted.current && poll.current) {
      clearTimeout(poll.current);
      poll.current = null;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const axiosClient = ({ bookingId }: BookingInput) => {
      return axios.get<GetBookingByBookingIdResponse>(
        `${yiluEnv.YILU_AMP_BACKEND_URL}/booking/v1/bookings/${bookingId}`,
        {
          data: {}, // send this blank to make content-type: application/json
        },
      );
    };
    const runPolling = () => {
      const timeoutId = setTimeout(() => {
        const restClient = getRESTClient();
        const client = isAmpEnabled
          ? axiosClient({ bookingId })
          : restClient.getBooking({ bookingId });

        client
          .then((response) => {
            const booking = isAmpEnabled
              ? (response as AxiosResponse<GetBookingByBookingIdResponse>).data
              : (response as Response<any>).body; // NOTE: will be removed on coras deco
            if (!booking) {
              throw new Error('Booking empty response');
            }

            const state = booking.state;

            retryCount++;
            setBooking(booking as any); // @TODO: fix typing
            onResponse && onResponse(booking as any); // @TODO: fix typing

            (state === BookingState.PENDING || state === BookingState.STARTED) &&
            retryCount <= MAX_POLLING_NUMBER_OF_RETRIES
              ? runPolling()
              : stopPolling();
          })
          .catch((error) => {
            onError && onError(error);
            stopPolling();
          });
      }, POLLING_DELAY_TIME + retryCount * 1000); // Delay starts at 3 seconds and increases by 1 second for each retry
      poll.current = timeoutId;
    };

    isMounted.current = true;
    setIsLoading(true);

    if (!poll.current) runPolling();

    return () => {
      isMounted.current = false;
      stopPolling();
    };
  }, [bookingId, onError, onResponse, retryCount, isAmpEnabled, yiluEnv.YILU_AMP_BACKEND_URL]);

  return {
    booking,
    isLoading,
  };
}
