import React from 'react';

import { SummaryLayout } from 'components/Layout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import PageHeading from 'components/PageHeading';
import ProgressBar from 'components/ProgressBar';
import { TravellerInformationForm } from 'components/TravellerInformationForm';

import { AirportTransferProductSummary } from 'modules/airport-transfer/components';

import { useTravellerInputPage } from './hooks';
import './styles.scss';

const TravellerInputPage: React.FC = () => {
  const { isPageReady, travellerInformationFormProps, airportTransferProductSummaryProps } =
    useTravellerInputPage();

  return (
    <div className="airport-transfer-tip">
      {isPageReady ? (
        <div className="form-container-wrapper">
          <div className="form-container container">
            <div className="content">
              <ProgressBar />
              <PageHeading />
              {travellerInformationFormProps && (
                <TravellerInformationForm {...travellerInformationFormProps} />
              )}
            </div>
            {airportTransferProductSummaryProps && (
              <SummaryLayout>
                <AirportTransferProductSummary {...airportTransferProductSummaryProps} />
              </SummaryLayout>
            )}
          </div>
        </div>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default TravellerInputPage;
