import { UiUtilities } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';
import { isLufthansaAirlineGroup } from 'components/TravellerInformationForm/Lounge/TravelInfo';

import { getProductDetails, isSpHolidayExtras } from 'modules/lounges/utils/getProductDetails';
import { obscurePriceParam } from 'modules/lounges/utils/obscure';
import { getSelectedPriceId } from 'modules/lounges/utils/pricing';
import { getLoungeTravellerInputPageQueryParams } from 'modules/lounges/utils/query-params';
import { getSessionEconomyPremium } from 'modules/lounges/utils/sessionUtilities';

import { PRICING_OPTION } from '../../../utils/constants';

enum ReservationFormFieldName {
  ECONOMY_PRICE_IDENTIFIER = 'isEconomyPremium',
  FLIGHT_CODE_IDENTIFIER = 'flightCode',
}

const useReservationInformationFormProps = (
  currentLounge: SearchItem | undefined,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  setIsReservationUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPricingOption: React.Dispatch<React.SetStateAction<PRICING_OPTION>>,
  pricingOption: PRICING_OPTION,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const yiluEnv = getVariables();

  const travellerInformationFormProps = useMemo(() => {
    if (!currentLounge) {
      return null;
    }

    const {
      iata,
      optionId,
      date,
      passengerCount,
      providerId,
      searchResultId,
      defaultPriceId,
      economyPremiumPriceId,
      otherAirlinePriceId,
      catalogId,
    } = getProductDetails(currentLounge);

    const isHolidayExtras = isSpHolidayExtras(providerId);

    const travellerInputPageQueryParams = getLoungeTravellerInputPageQueryParams(location);

    const _props: TravellerInformationFormProps<AMP_VERTICALS.LOUNGES> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.LOUNGES,
      iata,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId,
        locale: i18n.language,
        quantity: 1,
        selectedPriceId: getSelectedPriceId(
          isHolidayExtras ? PRICING_OPTION.NONE : pricingOption,
          defaultPriceId,
          economyPremiumPriceId,
          otherAirlinePriceId,
        ),
        searchResultId,
      },
      createBookingIntentRequestBody: {
        product: {
          optionId,
          arrivalTime: dayjs(date).format(UiUtilities.DateFormat.SHORT_DATE_WITH_FULL_TIME_ZULU),
          numberOfPassengers: passengerCount,
        },
        serviceProvider: providerId,
      },
      onUpdateBookingIntentSuccess(data) {
        const pricingParamObscured = obscurePriceParam('pricing');
        const pricingOptionParamObscured = obscurePriceParam(pricingOption);

        const loungePaymentInformationPageQueryParams = {
          searchResultID: searchResultId,
          loungeId: searchResultId ? undefined : optionId,
          bookingIntentId: data.bookingIntentId,
          shoppingCartId: data.shoppingCartId,
          singleProduct: travellerInputPageQueryParams.singleProduct,
          [pricingParamObscured]: pricingOptionParamObscured,
        };

        navigate({
          pathname: routes.LOUNGES_PIP,
          search: getURLSearchQuery(loungePaymentInformationPageQueryParams),
        });
      },
      onInitBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading) {
        setIsReservationUpdateLoading(isFormLoading);
      },
      onReservationFormFieldChange(name, value) {
        switch (name) {
          case ReservationFormFieldName.ECONOMY_PRICE_IDENTIFIER: {
            if (value) {
              setPricingOption(PRICING_OPTION.PREMIUM);
            } else {
              setPricingOption(PRICING_OPTION.LUFTHANSA);
            }
            break;
          }
          case ReservationFormFieldName.FLIGHT_CODE_IDENTIFIER: {
            const isEconomyPremium = getSessionEconomyPremium();
            if (isEconomyPremium) {
              setPricingOption(PRICING_OPTION.PREMIUM);
            } else if (isLufthansaAirlineGroup(value as string)) {
              setPricingOption(PRICING_OPTION.LUFTHANSA);
            } else {
              setPricingOption(PRICING_OPTION.OTHER);
            }
            break;
          }
          default: {
            break;
          }
        }
      },
    };
    return _props;
  }, [
    currentLounge,
    reservationFormRef,
    navigate,
    setIsReservationUpdateLoading,
    pricingOption,
    setPricingOption,
    location,
    yiluEnv.YILU_AMP_STORE_ID,
  ]);

  return travellerInformationFormProps;
};

export default useReservationInformationFormProps;
