/**
 * # Experiences Traveller Input Page Hooks
 *
 *
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { ExperienceProductSummaryProps } from 'modules/experiences/components';

import { getExperienceTravellerInputPageQueryParams } from '../../utils/query-params';
import {
  getSessionBookingState,
  sessionBookingState,
  setSessionBookingState,
} from '../../utils/sessionUtilities';

/**
 * Retrieve data for the experience traveller input page.
 */
export const useExperienceTravellerInputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const yiluEnv = getVariables();
  const [isPageReady, setIsPageReady] = useState(false);

  const experienceTravellerInputPageQueryParams = useMemo(() => {
    return getExperienceTravellerInputPageQueryParams(location);
  }, [location]);

  useEffect(() => {
    const { product } = experienceTravellerInputPageQueryParams;
    if (getSessionBookingState(product.bookingSessionId)) {
      return navigate(
        {
          pathname: routes.EXPERIENCES_SEARCH,
        },
        { replace: true },
      );
    }
    setIsPageReady(true);
  }, [experienceTravellerInputPageQueryParams, navigate]);

  const reservationFormRef = useRef<HTMLFormElement>(null);
  const [isReservationUpdateLoading, setIsReservationUpdateLoading] = useState(false);

  const travellerInformationFormProps = useMemo(() => {
    const { product } = experienceTravellerInputPageQueryParams;
    const _props: TravellerInformationFormProps<'EXPERIENCE'> = {
      ref: reservationFormRef,
      type: 'EXPERIENCE',
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId: 'TO_BE_DEFINED',
        locale: 'TO_BE_DEFINED',
        quantity: 1,
        selectedPriceId: 'TO_BE_DEFINED',
        searchResultId: 'TO_BE_DEFINED',
      },
      createBookingIntentRequestBody: {
        product: {
          name: product.name,
          description: product.description,
          reservationIds: [product.reservationId],
          bookingSessionId: product.bookingSessionId,
          location: product.location.plainAddress,
          startDateTime: product.startDateTime,
          fullDay: product.fullDay,
          duration: product.duration,
          imageUrl: product.imageUrl,
          ticketDeliveryMethod: product.ticketDeliveryMethod,
          expectedPrice: {
            amount: product.totalPrice * 100,
            currency: product.currency,
          },
        },
        serviceProvider: product.providerId,
      },
      onUpdateBookingIntentSuccess(data) {
        const { providerId, bookingIntentId, type, price, currency } = data;
        const paymentInformationPageQueryParams = {
          reservation: {
            providerId,
            bookingIntentId,
            type,
            price: price / 100,
            currency,
          },
          product,
        };

        setSessionBookingState(product.bookingSessionId, sessionBookingState.RESERVED);

        navigate(
          {
            pathname: routes.EXPERIENCES_PIP,
            search: getURLSearchQuery(paymentInformationPageQueryParams),
          },
          { replace: true },
        );
      },
      onInitBookingIntentError(error) {
        throw error;
      },
      onUpdateBookingIntentError(error) {
        throw error;
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading) {
        setIsReservationUpdateLoading(isFormLoading);
      },
    };
    return _props;
  }, [
    yiluEnv.YILU_AMP_STORE_ID,
    experienceTravellerInputPageQueryParams,
    navigate,
    setIsReservationUpdateLoading,
  ]);

  const experienceProductSummaryProps = useMemo(() => {
    const { product } = experienceTravellerInputPageQueryParams;

    const _props: ExperienceProductSummaryProps = {
      price: product.totalPrice,
      currency: product.currency,
      eventDateTime: product.startDateTime,
      eventName: product.name,
      travellersCount: product.totalTicketsNumber,
      carrierName: 'Coras',
      carrierLogoUrl: 'https://coras.io/images/favicons/apple-touch-icon-57x57.png',
      onClick() {
        if (reservationFormRef && reservationFormRef.current) {
          const reservationForm = reservationFormRef.current;
          reservationForm.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
        }
      },
      isLoading: isReservationUpdateLoading,
    };
    return _props;
  }, [experienceTravellerInputPageQueryParams, reservationFormRef, isReservationUpdateLoading]);

  return {
    travellerInformationFormProps,
    experienceProductSummaryProps,
    isPageReady,
  };
};
