import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type EmailReminderProps = {
  typographyVariant?: keyof typeof ElementsSDK.VariantMapping;
  withDivider?: boolean;
  className?: string;
  dearCustomer?: string;
  emailCheck?: string;
};

const EmailReminder: React.FC<EmailReminderProps> = ({
  withDivider,
  typographyVariant = 'p1',
  className,
  dearCustomer,
  emailCheck,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {withDivider && <ElementsSDK.Divider />}
      <ElementsSDK.Typography className="yilu-bcp_DearCustomer" variant={typographyVariant}>
        {dearCustomer || t('bcp.dearCustomer')}
      </ElementsSDK.Typography>
      <ElementsSDK.Typography className="yilu-bcp_EmailCheck" variant={typographyVariant}>
        {emailCheck || t('bcp.emailCheck')}
      </ElementsSDK.Typography>
    </div>
  );
};

export default EmailReminder;
