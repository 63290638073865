import type { Amenities } from '../types';

export const LOUNGES_POPULAR_AIRPORTS_PRIORITIZED = [
  'FRA',
  'MUC',
  'DUS',
  'JFK',
  'HAM',
  'HAJ',
  'LHR',
  'BOS',
  'DTW',
  'ZRH',
  'VIE',
  'GVA',
  'MXP',
  'EWR',
  'IAD',
  'CGN',
];

export const ECONOMY_PREMIUM_PRICE_OPTION_INDEX = 1;
export const OTHER_AIRLINE_PRICE_OPTION_INDEX = 2;

export enum AMP_LOUNGE_PRICING_OPTION {
  DEFAULT = 'DEFAULT',
  ECONOMY_PREMIUM = 'ECONOMY_PREMIUM',
  OTHER_AIRLINE = 'OTHER_AIRLINE',
}

export const MAX_LOUNGE_GUESTS = 6;
export const DEFAULT_LOUNGE_GUESTS = 1;

export const MAX_CHILDREN_LOUNGE_GUESTS = 5;

export enum PRICING_OPTION {
  NONE = 'none',
  LUFTHANSA = 'lufthansa',
  PREMIUM = 'premium',
  OTHER = 'other',
}

export const DEFAULT_LOUNGE_AMENITIES: Amenities[] = [
  {
    name: 'foodSnacks',
    exists: true,
  },
  {
    name: 'alcoholicDrinks',
    exists: true,
  },
];

export const JFK_LOUNGE_ID = '54';

export const LOUNGES_WITH_WARNING = ['BER', 'GVA', 'MXP'];

export const FRANKFURT_LOUNGE_ID = '9';
