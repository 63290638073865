/**
 * #
 *
 *
 */
import { useCallback, useState } from 'react';

/**
 * Load remote content and provide the results afterwards.
 *
 * NOTE: ensure that the CORS policy header of the remote resources are correctly set
 *
 * @param [src] - the content source that should be retrieved
 *
 * @returns Handler for accessing the content and its states
 */
export function useRemoteContent(src?: string, onError?: (error: Error) => unknown) {
  const [content, setContent] = useState<string | null>(null);
  const [isVisible, setVisibility] = useState(false);
  const [isPending, setPending] = useState(false);

  const loadContent = useCallback(() => {
    setPending(true);
    if (src) {
      fetch(src)
        .then((response) => response.text())
        .then((data) => {
          setPending(false);
          setContent(data);
        })
        .catch((error) => {
          if (typeof onError === 'function') {
            onError(error);
          } else {
            console.error('Failed fetching remote content', error);
          }
          setVisibility(false);
        });
    }
  }, [src, onError]);

  const handleToggleVisibility = useCallback(() => {
    setVisibility(!isVisible);
    if (!content) {
      loadContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, content]);

  const toggleVisibility = src ? handleToggleVisibility : undefined;

  return {
    isVisible,
    toggleVisibility,
    isPending,
    content,
  };
}
