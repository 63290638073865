import { CreateBookingResponse } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePaymentInputContext } from 'context/PaymentInput';

import ErrorMessage from 'components/PaymentInformation/ErrorMessage';
import PaymentModal from 'components/PaymentInformation/PaymentModal';

import { CreditCardForm } from './CreditCardForm';
import { PaymentMethodsSelector } from './PaymentMethodsSelector';
import { PaymentRequestInitializer } from './PaymentRequestInitializer';
import { SecurePayment } from './SecurePayment';
import { usePaymentInformation } from './hooks/usePaymentInformation';
import './styles.scss';

export interface PaymentInformationProps {
  ref: React.RefObject<HTMLFormElement>;
  productDetails: {
    trackingId: string; // NOTE: rename paymenthMethodTrackingId
    providerId: string; // NOTE: rename serviceProviderId
    price: number;
    actualPrice?: number; // NOTE: this can be merged with price.
    currency: string;
    providerName?: string;
    locationName?: string; // NOTE: required for ParkAero - merge with providerName and make it required / rename
    shoppingCartId?: string; // NOTE: can be switched to required with Coras deco
  };
  paymentDetails: {
    bookingIntentId: string; // NOTE: can be replaced with shoppingCartId create booking
    userId: string;
    shoppingCartId?: string; // NOTE: can be removed with Coras deco
    touchPointId?: string; // NOTE: can be switched to required with Coras deco
  };
  isUsingPaymentIntentApi: boolean; // NOTE: can be removed with Coras deco
  isAmpEnabled: boolean; // NOTE: can be removed with Coras deco
  onPayment: (response: CreateBookingResponse) => void;
  onError: (error: Error) => void;
  setIsFormValid?: (validityState: boolean) => void; // NOTE: can be removed
}

export const PaymentInformation = React.forwardRef<HTMLFormElement, PaymentInformationProps>(
  (
    {
      productDetails,
      paymentDetails,
      isUsingPaymentIntentApi = false,
      onPayment,
      onError,
      setIsFormValid,
      isAmpEnabled,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { hasFormTriggered, currentPaymentMethod, setCurrentPaymentMethod, paymentMethods } =
      usePaymentInputContext();
    const {
      clientSecret,
      createBookingError,
      handleOnOk,
      handlePaymentFailedError,
      isCreditCard,
      isFormLoading,
      isPaymentModalVisible,
      isPaymentRequestButton,
      paymentRequest,
      setIsCreateBookingLoading,
      stripe,
      handleOnSubmit,
    } = usePaymentInformation({
      productDetails,
      paymentDetails,
      isAmpEnabled,
      onError,
    });

    return (
      <form className="yilu-payment-information" ref={ref} onSubmit={handleOnSubmit}>
        {isFormLoading ? (
          <ElementsSDK.FormLoadingIndicator />
        ) : (
          <>
            <SecurePayment />
            <PaymentMethodsSelector
              currentPaymentMethod={currentPaymentMethod}
              paymentMethods={paymentMethods}
              onSelect={setCurrentPaymentMethod}
              trackingId={productDetails.trackingId}
            />
            {isCreditCard && stripe && (
              <CreditCardForm
                clientSecret={clientSecret}
                stripe={stripe}
                onPayment={onPayment}
                onError={handlePaymentFailedError}
                paymentDetails={paymentDetails}
                productDetails={productDetails}
                isUsingPaymentIntentApi={isUsingPaymentIntentApi}
                setIsFormValid={setIsFormValid}
                setIsCreateBookingLoading={setIsCreateBookingLoading}
                isAmpEnabled={isAmpEnabled}
                {...rest}
              />
            )}
            {isPaymentRequestButton && stripe && paymentRequest && (
              <PaymentRequestInitializer
                stripe={stripe}
                clientSecret={clientSecret}
                onPayment={onPayment}
                onError={handlePaymentFailedError}
                paymentDetails={paymentDetails}
                productDetails={productDetails}
                isUsingPaymentIntentApi={isUsingPaymentIntentApi}
                paymentRequest={paymentRequest}
                setIsCreateBookingLoading={setIsCreateBookingLoading}
                isAmpEnabled={isAmpEnabled}
              />
            )}
            {hasFormTriggered && !currentPaymentMethod && (
              <ErrorMessage errorMessage={t('Please select payment method')} />
            )}
            <PaymentModal
              isVisible={isPaymentModalVisible}
              errorMessage={createBookingError?.message}
              onConfirm={handleOnOk}
            />
          </>
        )}
      </form>
    );
  },
);
