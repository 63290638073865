import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { GetProductsRequest, GetProductsResponse, SearchItem } from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import { useEffect, useState } from 'react';
import spacetime from 'spacetime';
import tzlookup from 'tz-lookup';

import { yiluEnv } from 'utils';
import { getCurrentLanguage } from 'utils/yiluSdk';

const DEFAULT_PAGE_SIZE = 10;

export type UseRidesSearchResultsLoaderParams = {
  searchParams: {
    passengerCount: number;
    originAddressCoordinates: ElementsSDK.Coordinates;
    destinationAddressCoordinates: ElementsSDK.Coordinates;
    transferDate: string;
    transferTime: string;
  };
  onSearchResults?: (results: SearchItem[]) => unknown;
  onError?: (error: Error) => unknown;
  pageSize?: number;
};

export const useRidesSearchResultsLoader = ({
  searchParams,
  onSearchResults,
  onError,
  pageSize = DEFAULT_PAGE_SIZE,
}: UseRidesSearchResultsLoaderParams) => {
  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshPagination, setRefreshPagination] = useState(false);
  const [searchID, setSearchID] = useState<string>();
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_RIDES_VERTICAL_ID } =
    yiluEnv.getVariables();

  const getRemoteTransferDateTime = (origin: ElementsSDK.Coordinates): string => {
    const dayjs = UiUtilities.getDayjs();

    const transferTimezone = tzlookup(origin.latitude, origin.longitude);

    const transferSpace = spacetime(searchParams.transferDate, transferTimezone);
    const remoteTransferEpoch = transferSpace.time(searchParams.transferTime).epoch;
    const remoteTransferDateTime = dayjs(remoteTransferEpoch)
      .toISOString()
      .replace(/\.[0-9]{3}/, '');

    return remoteTransferDateTime;
  };

  const fetchRides = async () => {
    const originAddressCoordinates = searchParams.originAddressCoordinates;
    const destinationAddressCoordinates = searchParams.destinationAddressCoordinates;

    const transferDateTime = getRemoteTransferDateTime(originAddressCoordinates);

    const ridesRequest: GetProductsRequest['query'] = {
      storeId: YILU_AMP_STORE_ID,
      verticalId: YILU_AMP_RIDES_VERTICAL_ID,
      from: `${originAddressCoordinates.latitude},${originAddressCoordinates.longitude}`,
      to: `${destinationAddressCoordinates.latitude},${destinationAddressCoordinates.longitude}`,
      numberOfTravelers: searchParams.passengerCount,
      startAt: transferDateTime,
      searchID,
      pageSize,
      pageNumber,
      locale: getCurrentLanguage(),
      currency: 'EUR',
    };

    try {
      const response = await axios.get<GetProductsResponse>(
        `${YILU_AMP_BACKEND_URL}/product/v1/products`,
        {
          params: ridesRequest,
        },
      );
      const body = response.data;
      const newSearchResults = body.items;

      const responsePage = body.page;
      const totalPages = Math.ceil(responsePage.total / responsePage.pageSize);
      setTotalPages(totalPages);
      setSearchID(body.searchID);

      if (onSearchResults) {
        onSearchResults(newSearchResults);
      }

      setSearchResults([...searchResults, ...newSearchResults]);
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error as Error);
      }
      console.error('Error loading rides search results', error);
    } finally {
      setIsLoading(false);
      setRefreshPagination(false);
    }
  };

  const loadResults = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await fetchRides();
    } catch (error) {
      if (typeof onError === 'function') {
        onError(error as Error);
      }
      console.error('Error loading rides search results', error);
    } finally {
      setIsLoading(false);
      setRefreshPagination(false);
    }
  };

  const { setLastElement, pageNumber } = UiUtilities.useObserverPagination({
    loadResults,
    totalPages,
    refreshPagination,
    searchID,
  });

  useEffect(() => {
    setSearchResults([]);
    setRefreshPagination(true);
    setSearchID(undefined);
  }, [searchParams]);

  return {
    searchResults,
    isLoading,
    totalPages,
    pageNumber,
    setLastElement,
  };
};
