import { SearchItem } from '@yiluhub/yilu-amp-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paramConverters, yiluTracking } from 'utils/index';

import routes from 'router/routes';

import { PaymentInformationProps } from 'components/PaymentInformation';

import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';
import { ParkingPaymentInputPageQueryParams } from 'modules/parking/utils/query-params';

export function usePaymentInfoFormProps(
  currentParking: SearchItem | undefined,
  paymentFormRef: React.RefObject<HTMLFormElement>,
  parkingPaymentInputPageQueryParams: ParkingPaymentInputPageQueryParams,
) {
  const navigate = useNavigate();

  const paymentInformationFormProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    const parkingDetails = getParkingDetails(currentParking);

    const _props: PaymentInformationProps = {
      ref: paymentFormRef,
      productDetails: {
        trackingId: 'parking',
        providerId: parkingDetails.providerId,
        price: parkingDetails.price,
        currency: parkingDetails.currency,
        locationName: parkingDetails.address,
        providerName: parkingDetails.providerId === 'PARK_VIA' ? 'Park Via' : undefined,
        shoppingCartId: parkingPaymentInputPageQueryParams?.shoppingCartId,
      },
      paymentDetails: {
        bookingIntentId: parkingPaymentInputPageQueryParams.bookingIntentId,
        userId: parkingPaymentInputPageQueryParams.userId as string,
        touchPointId: parkingPaymentInputPageQueryParams.touchpoint as string,
      },
      isAmpEnabled: true,
      isUsingPaymentIntentApi: false,
      onPayment: (response) => {
        const bookingConfirmationPageQueryParams = {
          bookingId: response.id,
        };

        yiluTracking.sendPaymentSuccessfulEvent(parkingPaymentInputPageQueryParams.bookingIntentId);

        navigate({
          pathname: routes.PARKING_BCP,
          search: paramConverters.getURLSearchQuery(bookingConfirmationPageQueryParams),
        });
      },
      onError(error: Error) {
        throw error;
      },
    };
    return _props;
  }, [paymentFormRef, currentParking, parkingPaymentInputPageQueryParams, navigate]);
  return paymentInformationFormProps;
}
