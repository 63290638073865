/**
 * # Experience Product Summary
 *
 *
 */
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatLocalDate } from '@yiluhub/ui-utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductSummary from 'components/ProductSummary';

import { ExperienceProductSummaryProps } from './types';

export const ExperienceProductSummary: React.FC<ExperienceProductSummaryProps> = ({
  eventDateTime,
  eventName,
  travellersCount,
  carrierLogoUrl,
  carrierName,
  ...props
}) => {
  const { t } = useTranslation();

  const extendedView = (
    <>
      <ElementsSDK.Typography variant="h6" uppercase>
        {t('Summary')}
      </ElementsSDK.Typography>
      <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__Itinerary'}>
        <ElementsSDK.Typography variant="h4" className={'yilu-ProductSummary__Row'}>
          {formatLocalDate(eventDateTime, 'ddd, MMMM D')}
        </ElementsSDK.Typography>
        <div>
          <ol className={'yilu-ProductSummary__List'}>
            <li className={'yilu-ProductSummary__Row'}>
              <ElementsSDK.Typography size="medium" bold className={'yilu-ProductSummary__Column'}>
                {formatLocalDate(eventDateTime, 'HH:mm')}
              </ElementsSDK.Typography>
              <div className={'yilu-ProductSummary__ColumnAddress'}>{eventName}</div>
            </li>
          </ol>
        </div>
      </ElementsSDK.Typography>
      <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__Info'}>
        <ul>
          <li className={'yilu-ProductSummary__Row'}>
            <div className={'yilu-ProductSummary__Column yilu-ProductSummary__Minor'}>
              {t('Operator')}
            </div>
            <div className={'yilu-ProductSummary__OperatorLogoWrapper'}>
              <img
                className={'yilu-ProductSummary__OperatorLogo'}
                src={carrierLogoUrl}
                alt={t('Operator logo')}
              />
              <ElementsSDK.Typography size="medium" className={'yilu-ProductSummary__MinorOffset'}>
                {carrierName}
              </ElementsSDK.Typography>
            </div>
          </li>
        </ul>
      </ElementsSDK.Typography>
    </>
  );

  return (
    <ProductSummary extendedView={extendedView} {...props}>
      {travellersCount && (
        <div className={'yilu-ProductSummary__Row'}>
          <ElementsSDK.Typography
            size="medium"
            className={'yilu-ProductSummary__Minor'}
            testid="travellersCount"
          >
            {t('ticket.count', { travellersCount })}
          </ElementsSDK.Typography>
        </div>
      )}
    </ProductSummary>
  );
};
