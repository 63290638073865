import { EmbedFlow, createFlow } from '@yiluhub/embed-sdk';
import React, { useEffect } from 'react';

import { yiluEnv, yiluTracking } from 'utils';

import ExperiencesBottomBanner from '../../components/ExperiencesBottomBanner';
import { useExperienceSearchPage } from './hooks';
import './styles.scss';

const DEFAULT_PARTNER_ID = 'simpletrip';
enum PartnerId {
  LUFTHANSA = 'LH',
  SWISS = 'LX',
  AUSTRIAN = 'OS',
  BRUSSELS = 'SN',
  EUROWINGS = 'EW',
  DISCOVER = '4Y',
  AIRDOLOMITI = 'EN',
}

const ExperienceSearchPage: React.FC = () => {
  const { corasIframeParams, handleCorasIframeMessage } = useExperienceSearchPage();
  const yiluEnvVariables = yiluEnv.getVariables();
  const corasPartnerId = (yiluEnvVariables.CORAS_PARTNER_ID as string) || DEFAULT_PARTNER_ID;
  const partnerId = yiluEnvVariables.YILU_ENTERPRISE_PARTNER || 'LH';

  const experiencesIframe = document.getElementById('experiences-iframe');

  useEffect(() => {
    yiluTracking.sendGAEvent({
      event: 'page_loaded',
      category: 'experience',
      label: 'experience_loaded',
    });

    if (experiencesIframe) {
      experiencesIframe.innerHTML = '';
      createFlow(
        EmbedFlow.EXPERIENCES,
        corasPartnerId,
        '#experiences-iframe',
        corasIframeParams,
        handleCorasIframeMessage,
      );
    }
  }, [experiencesIframe, corasIframeParams, handleCorasIframeMessage, corasPartnerId]);

  return (
    <div className="experiences">
      <div className="experiences-iframe" id="experiences-iframe" />
      {partnerId !== PartnerId.SWISS && <ExperiencesBottomBanner partnerId={partnerId} />}
    </div>
  );
};

export default ExperienceSearchPage;
