/**
 * Validation Utils
 *
 */
import {
  BookingIntentField,
  BookingIntentFieldRequirement,
  BookingIntentFieldType,
} from '@yiluhub/network-sdk';
import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const REQUIRED_FIELD_ERROR_MESSAGE_KEY = 'tip.validation.required';
export const REQUIRED_RADIOBUTTON_FIELD_ERROR_MESSAGE_KEY = 'tip.validation.radiobutton.required';

/**
 * Flight Number validator
 *
 * @param input string to be validated
 *
 * @returns true if flight is valid, otherwise returns false
 */
const validateFlightNumber = (code: string): boolean => {
  const FLIGHT_VALIDATOR_REGEX = /^([A-Z\d]{2})(\s?\d{1,4})$/i;
  const match = code.match(FLIGHT_VALIDATOR_REGEX);

  if (match) {
    const lettersOrDigits = match[1];
    const digits = match[2].trim();

    if (
      lettersOrDigits.length === 2 &&
      digits.length >= 1 &&
      digits.length <= 4 &&
      !isNaN(parseInt(digits, 10))
    ) {
      return true;
    }
  }

  return false;
};

/**
 * Email validator
 *
 * @param input string to be validated
 *
 * @returns undefined if valid email, otherwise returns an error message key which u can translate
 */
export const isEmailValid = (input?: string | number | undefined): string | undefined => {
  const errorMessage = 'tip.validation.email';
  if (!input) return errorMessage;
  /* eslint max-len: 'off', no-useless-escape: 'off' */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(input).trim().toLowerCase()) ? undefined : errorMessage;
};

/**
 * Phone numbers are required and should be numeric
 * @param input
 */
export const isPhoneNumberValid =
  (isRequired: boolean) =>
  (input: { code?: string; number?: string }): string | undefined => {
    if (!input.code && !input.number) {
      return isRequired ? REQUIRED_FIELD_ERROR_MESSAGE_KEY : undefined;
    }
    if (!input.code || !input.number) return 'tip.validation.phonenumber';

    // E-164 format phone number validation
    return isValidPhoneNumber(`+${input.code}${input.number}`)
      ? undefined
      : 'tip.validation.phonenumber';
  };

/**
 * LoyaltyIds should have 15 characters and be only digits.
 * It's also not a required field.
 *
 * @param input
 */
export const isLoyaltyIdValid = (input?: string | undefined): string | undefined => {
  const errorMessage = 'tip.reservation.form.field.loyaltyId.error';
  const LOYALTY_ID_LENGTH = 15;
  if (!input || input.length === 0) return undefined;
  const trimmedInput = input?.trim();
  const re = /^[0-9]*$/;
  return trimmedInput.length === LOYALTY_ID_LENGTH && re.test(trimmedInput)
    ? undefined
    : errorMessage;
};

/**
 * This function maps fields with validators for generic dynamic fields
 * @param field
 */
export const getValidationRules = (field: BookingIntentField | ProductConsumerAttributeDto) => {
  const rules = {} as any;
  const isFieldRequired = field.requirement === BookingIntentFieldRequirement.MANDATORY;

  switch (field.type) {
    case BookingIntentFieldType.PHONE_NUMBER:
      rules.validate = isPhoneNumberValid(isFieldRequired);
      break;
    case BookingIntentFieldType.EMAIL:
      rules.validate = isEmailValid;
      break;
    default:
      break;
  }

  if (field.name === 'flightNumber') {
    rules.validate = isRidesFlightCodeValid;
  }
  if (isFieldRequired) {
    rules.required = REQUIRED_FIELD_ERROR_MESSAGE_KEY;
  }

  return rules;
};
/**
 * Booking code validator
 *
 * Optional, if entered it should be exactly 6 characters long.
 * Valid characters are A-Z and 1-9. Zero number is not allowed.
 * Must contain at least 1 capital letter. The positive lookahead (?=.*[A-Z]) is used for this.
 *
 * @param input string to be validated
 *
 * @returns undefined if valid booking code, otherwise returns an error message key
 */
export const isBookingCodeValid = (input?: string | number | undefined): string | undefined => {
  const errorMessage = 'tip.validation.booking.code';
  if (!input) return undefined;
  const re = /^(?=.*[A-Z])[A-Z1-9]{6}$/;
  return re.test(String(input)) ? undefined : errorMessage;
};

/**
 * Flight code validator
 *
 * Optional, if entered could be between 3 and 6 characters long.
 * Valid characters are A-Z and 0-9.
 * Must contain at least 1 capital letter. The positive lookahead (?=.*[A-Z]) is used for this.
 * Must start with one of the following prefixes (see PNR_PREFIXES list)
 *
 * @param input string to be validated
 * @param iata string used to validate the ticket prefix
 *
 * @returns undefined if valid booking code, otherwise returns an error message key
 */
export const isLoungeFlightCodeValid = (
  input?: string | number | undefined,
  iata?: string,
): string | undefined => {
  const defaultErrorMessage = 'tip.validation.flight.code';
  const brusselsErrorMessage = 'tip.validation.brussels.wrong.prefix';
  const noPrefixErrorMessage = 'tip.validation.booking.code.no.prefix';
  const FLIGHT_CODE_PREFIXES = [
    'A3',
    'AC',
    'CA',
    'AI',
    'NZ',
    'NH',
    'OZ',
    'AV',
    'CM',
    'OU',
    'MS',
    'ET',
    'BR',
    'LO',
    'SK',
    'ZH',
    'SQ',
    'SA',
    'TP',
    'TG',
    'TK',
    'UA',
    'LH',
    'OS',
    'SN',
    'LX',
    '4Y',
    'EN',
    'WK',
    'EW',
    'VL',
  ];

  if (!input) return undefined;
  if (!iata) return defaultErrorMessage;
  const inputString = String(input).trim().toUpperCase();
  if (
    ['BER', 'GVA', 'MXP'].includes(iata) &&
    FLIGHT_CODE_PREFIXES.filter((prefix) => inputString.startsWith(prefix)).length === 0
  ) {
    return noPrefixErrorMessage;
  } else if (iata === 'BRU' && !inputString.startsWith('SN')) {
    return brusselsErrorMessage;
  }
  return validateFlightNumber(inputString) ? undefined : defaultErrorMessage;
};

/**
 * Flight code validator for Rides TIP
 *
 * Optional, if entered could be between 3 and 6 characters long.
 * Valid characters are A-Z and 0-9.
 * Must contain at least 1 capital letter. The positive lookahead (?=.*[A-Z]) is used for this.
 * @param input string to be validated
 *
 * @returns undefined if valid booking code, otherwise returns an error message key
 */
export const isRidesFlightCodeValid = (input?: string | number | undefined): string | undefined => {
  const errorMessage = 'tip.validation.flightNumber';
  if (!input) return undefined;
  const inputString = String(input).trim();
  return validateFlightNumber(inputString) ? undefined : errorMessage;
};
