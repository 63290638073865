import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils';

export type BookingContactFormProps = {
  enterprisePartner: string;
  bookingNumber: string;
};

const BookingContactForm: React.FC<BookingContactFormProps> = ({
  enterprisePartner,
  bookingNumber,
}) => {
  const { t } = useTranslation();
  const envVariables = yiluEnv.getVariables();

  return (
    <div className="yilu-payment-bcp-contactForm-link">
      <ElementsSDK.Typography variant="p2">
        {t('transfers.bcp.contact_form.text')}
        <ElementsSDK.Link
          href={t(`transfers.bcp.contact_form_${envVariables?.YILU_ENTERPRISE_PARTNER || 'LH'}`, {
            carrier: enterprisePartner,
            bookingNumber: bookingNumber,
          })}
        >
          {t('transfers.bcp.contact_form_link.text')}
        </ElementsSDK.Link>
      </ElementsSDK.Typography>
    </div>
  );
};

export default BookingContactForm;
