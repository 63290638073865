import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';
import { getCurrentLanguage } from 'utils/yiluSdk';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { getParkingDetails } from 'modules/parking/utils/getParkingDetails';

const useReservationInformationFormProps = (
  currentParking: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  setIsReservationUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const navigate = useNavigate();
  const yiluEnv = getVariables();

  const travellerInformationFormProps = useMemo(() => {
    if (!currentParking) {
      return null;
    }

    const parkingDetails = getParkingDetails(currentParking);

    const _props: TravellerInformationFormProps<AMP_VERTICALS.PARKING> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.PARKING,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId: parkingDetails.catalogId,
        locale: getCurrentLanguage(),
        quantity: 1,
        searchResultId: parkingDetails.searchResultId,
        selectedPriceId: parkingDetails.selectedPriceId,
      },
      createBookingIntentRequestBody: {
        product: {
          marketId: parkingDetails.marketId || undefined,
          locationId: parkingDetails.locationId || undefined,
          listingId: parkingDetails.listingId || undefined,
          areaId: parkingDetails.areaId || undefined,
          iata: parkingDetails.iata!,
          productTypeCode: parkingDetails.productTypeCode,
          arrival: parkingDetails.arrival,
          departure: parkingDetails.departure,
          airportId: parkingDetails.airportId,
          productId: parkingDetails.productId,
          optionId: parkingDetails.optionId,
          expectedPrice: {
            amount: parkingDetails.price * 100,
            currency: parkingDetails.currency,
          },
        },
        serviceProvider: parkingDetails.providerId,
      },
      onUpdateBookingIntentSuccess(data) {
        const parkingPaymentInformationPageQueryParams = {
          searchResultID: parkingDetails.searchResultId,
          bookingIntentId: data.bookingIntentId,
          shoppingCartId: data.shoppingCartId,
        };

        navigate({
          pathname: routes.PARKING_PIP,
          search: getURLSearchQuery(parkingPaymentInformationPageQueryParams),
        });
      },
      onInitBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading) {
        setIsReservationUpdateLoading(isFormLoading);
      },
    };
    return _props;
  }, [
    yiluEnv.YILU_AMP_STORE_ID,
    currentParking,
    reservationFormRef,
    navigate,
    setIsReservationUpdateLoading,
  ]);

  return travellerInformationFormProps;
};

export default useReservationInformationFormProps;
