/**
 * # Current Product Feature
 *
 *
 */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetProductBySearchResultIdRequest,
  GetProductBySearchResultIdResponse,
  SearchItem,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';

import { yiluEnv } from 'utils';

import { AppThunk } from 'store/types';

import { reducerName } from './selectors';

interface CurrentProductState {
  data: SearchItem | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: CurrentProductState = {
  data: null,
  loading: false,
  error: null,
};

const currentProductSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    loadCurrentProductPending(state) {
      state.loading = true;
      state.error = null;
    },
    loadCurrentProductSuccess(state, action: PayloadAction<SearchItem>) {
      state.loading = false;
      state.data = action.payload;
    },
    loadCurrentProductFailed(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// action creators
export const { loadCurrentProductPending, loadCurrentProductSuccess, loadCurrentProductFailed } =
  currentProductSlice.actions;

export function loadAmpCurrentProduct(searchResultID: string): AppThunk {
  return async (dispatch) => {
    const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();
    dispatch(loadCurrentProductPending());
    try {
      const response = await axios.get<GetProductBySearchResultIdResponse>(
        `${YILU_AMP_BACKEND_URL}/product/v1/products/search-results/${searchResultID}`,
        {
          params: {
            storeId: YILU_AMP_STORE_ID,
          } as GetProductBySearchResultIdRequest['parameters']['query'],
        },
      );
      const body = response.data;
      if (!body) {
        throw new Error(`Missing data for search result: ${searchResultID}`);
      }

      dispatch(loadCurrentProductSuccess(body));
    } catch (error) {
      dispatch(loadCurrentProductFailed(error as Error));
    }
  };
}

export * from './selectors';

export default currentProductSlice.reducer;
