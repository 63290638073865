import { SearchItem } from '@yiluhub/yilu-amp-types';

export function filterLoungeSearchResults(
  searchResults: SearchItem[],
  searchParams: {
    entryDate: string;
    adults: number;
    children?: number;
    infants?: number;
    iata: string;
    fcle?: boolean;
  },
) {
  const filterByFirstClass = (result: SearchItem) => {
    return (
      result.item.productDetails[0]?.productDescription.productAttributes.find(
        (attribute) => attribute.key === 'category',
      )?.value === 'First Class Lounge'
    );
  };

  const _searchResults: Array<SearchItem> = searchResults;
  const loungeSearchResults = searchParams.fcle
    ? _searchResults.filter((result: SearchItem) => filterByFirstClass(result))
    : _searchResults.filter((result: SearchItem) => !filterByFirstClass(result));
  return loungeSearchResults;
}
