/**
 * # AirportTransfer Booking Confirmation
 *
 *
 */
import { BusBooking, BusBookingData } from '@yiluhub/network-sdk';
import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookingContactForm,
  BookingStateHeader,
  Container,
  ProviderInfo,
  Terms,
} from '../components';
import { useGetBooking } from '../hooks/useGetBooking';
import '../styles.scss';
import AirportTransferBookingInfo from './AirportTransferBookingInfo';
import SBBBookingInfo from './SBBBookingInfo';
import { AirportTransferBookingConfirmationProps, SwissTravelBooking } from './types';

const INTERNAL_CONTENT_PREFIX_URL =
  'https://provider-resources-staging-v2.s3.eu-central-1.amazonaws.com/distribusion/terms';

// eslint-disable-next-line max-len
export const AirportTransferBookingConfirmation: React.FC<
  AirportTransferBookingConfirmationProps
> = ({ bookingId, isAmpEnabled, onError, onResponse }) => {
  const { t } = useTranslation();

  const { booking, isLoading } = useGetBooking({
    bookingId,
    onError,
    onResponse,
    isAmpEnabled,
  });

  return (
    <>
      <BookingStateHeader
        bookingState={booking ? booking.state : undefined}
        headerStateText={{
          loading: {
            title: t('bcp.headerTitlePending'),
            subtitle: t('bcp.headerSubtitlePending'),
          },
          failed: {
            title: t('bcp.headerTitleFailed'),
            subtitle: t('bcp.headerSubtitleFailed'),
          },
          completed: {
            title: t('bcp.headerTitle'),
            subtitle: t('bcp.headerSubtitle'),
          },
        }}
      />
      <Container>
        {isLoading ? (
          <ElementsSDK.FormLoadingIndicator className="yilu-payment-bcp-formLoadingIndicator__loader" />
        ) : (
          <>
            {booking && booking.product.data && (
              <>
                <div className="yilu-payment-bcp-provider-info-airport-transfers-container">
                  <ProviderInfo
                    className="yilu-payment-bcp-provider-info-airport-transfers"
                    logoUrl={(booking.product.data as BusBookingData).busDetails.logoUrl}
                  />
                  {(booking.product.data as BusBookingData).ticketUrl && (
                    <ElementsSDK.DownloadButton
                      linkUrl={(booking.product.data as BusBookingData).ticketUrl as string}
                      label={t('bcp.downloadPDF')}
                    />
                  )}
                </div>
                <ElementsSDK.Divider />
                <div className="yilu-payment-bcp-booking-info">
                  {booking && booking.product.data && (booking.product.data as any).passInfo ? (
                    <SBBBookingInfo booking={booking as SwissTravelBooking} />
                  ) : (
                    <AirportTransferBookingInfo booking={booking as BusBooking} />
                  )}
                </div>
                <Terms
                  termsUrl={booking.product.data.termsUrl}
                  opensNewtab={
                    !booking.product.data.termsUrl?.startsWith(INTERNAL_CONTENT_PREFIX_URL)
                  }
                />
                <BookingContactForm
                  enterprisePartner={booking.enterprisePartner}
                  bookingNumber={booking.product.data.bookingId}
                />
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};
