import { LoungeBooking } from '@yiluhub/network-sdk';
import { useEffect, useState } from 'react';

import { yiluEnv } from 'utils/index';

import { HolidayExtraServiceProvider } from 'components/BookingConfirmation';

const DEFAULT_FAQ_TYPE_FORM_EN = 'PYMFNZzF';
const DEFAULT_FAQ_TYPE_FORM_DE = 'HJmEbrU2';

export function useFaqLink(loungeBooking: LoungeBooking | null, currentLanguage: string) {
  if (!loungeBooking || loungeBooking?.serviceProvider === HolidayExtraServiceProvider) {
    return null;
  }

  const yiluEnvVariables = yiluEnv.getVariables();

  let typeFormId: string | undefined;
  if (currentLanguage === 'en') {
    typeFormId = yiluEnvVariables?.FAQ_TYPE_FORM_EN || DEFAULT_FAQ_TYPE_FORM_EN;
  } else if (currentLanguage === 'de') {
    typeFormId = yiluEnvVariables?.FAQ_TYPE_FORM_DE || DEFAULT_FAQ_TYPE_FORM_DE;
  }

  if (!typeFormId) return null;

  const url = new URL(`https://form.typeform.com/to/${typeFormId}`);

  url.searchParams.set('typeform-medium', 'bcp');

  const airportIataCode = loungeBooking.product.data?.loungeDetails.iata;
  const loungeName = loungeBooking.product.data?.loungeDetails.name;
  const terminal = loungeBooking.product.data?.loungeDetails.terminal;
  const bookingId = loungeBooking.id;

  url.hash = `product_id=${loungeName}-${airportIataCode}-${terminal}&user_id=${bookingId}`;

  return url.toString();
}

export function useDetermineQrWalletVisibility(booking: LoungeBooking) {
  // @ts-ignore
  const hasQrCodeValues = !!booking.product.data?.qrCodeValues?.length;
  const [canShowQrWallet, setCanShowQrWallet] = useState(hasQrCodeValues);

  //temporary solution to stop 400 errors after booking has expired.
  useEffect(() => {
    const EXPIRES_IN_MIN = 15;
    const bookingExpireDate = new Date(
      new Date(booking.modifiedAt).getTime() + EXPIRES_IN_MIN * 60000,
    );

    const remainingTime = bookingExpireDate.getTime() - new Date().getTime();
    const timeoutDuration = remainingTime > 0 ? remainingTime : 0;

    const timeoutId = setTimeout(() => {
      setCanShowQrWallet(false);
      clearTimeout(timeoutId);
    }, timeoutDuration);

    return () => clearTimeout(timeoutId);
  }, [booking]);

  return {
    canShowQrWallet,
  };
}
