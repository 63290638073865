import { Typography } from '@yiluhub/ui-elements';
import React from 'react';

import './styles.scss';

const SwissCard: React.FC<SwissCardProps> = ({
  imageUrl,
  imageAlt,
  title,
  tableHeaderValues,
  tableValues,
}) => {
  return (
    <div className="swiss-card">
      <img src={imageUrl} alt={imageAlt} className="swiss-card-image" />
      <div className="card-text-container">
        <Typography variant="h3" bold={true} className="card-title">
          {title}
        </Typography>
        <div className="card-text-grid">
          {tableHeaderValues.map((value) => {
            return (
              <Typography key={value} variant="p1" className="table-header">
                {value}
              </Typography>
            );
          })}
          {tableValues.map((value) => {
            return (
              <Typography key={value} variant="p2">
                {value}
              </Typography>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export type SwissCardProps = {
  imageUrl: string;
  imageAlt: string;
  title: string;
  tableHeaderValues: string[];
  tableValues: string[];
};

export default SwissCard;
