/**
 * # Lounge Product Feature (Selectors)
 *
 *
 */
import { createSelector } from 'reselect';

import { RootState } from 'store/types';

export const reducerName = 'loungeProduct';

/**
 * Get lounge product related data
 *
 * @param state - root application state
 */
const selectLoungeProductState = (state: RootState) => state[reducerName];

/**
 * Get the lounge product
 */
export const selectLoungeProduct = createSelector(
  [selectLoungeProductState],
  (loungeProductState) => loungeProductState.data,
);

/**
 * Get the loading status of the lounge product
 */
export const selectIsLoungeProductLoading = createSelector(
  [selectLoungeProductState],
  (loungeProductState) => loungeProductState.loading,
);

/**
 * Get the potential error of the lounge product
 */
export const selectLoungeProductError = createSelector(
  [selectLoungeProductState],
  (loungeProductState) => loungeProductState.error,
);
