import {
  BookingIntentField,
  BookingIntentFieldRequirement,
  BookingIntentFieldType,
} from '@yiluhub/network-sdk';
import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';

/**
 * NOTE: ANY ADDITION TO THIS LIST SHOULD ALSO BE ADDED TO THE TRANSLATIONS FOR FIELD LABELS
 */
const expectedSortOrder = [
  'company',
  'salutation',
  'customer.givenNames',
  'firstName',
  'customer.familyName',
  'lastName',
  'customer.email',
  'email',
  'customer.diallingCode',
  'customer.telephone',
  'customer.mobile',
  'vehicle.registration',
  'customer.address',
  'street',
  'customer.town',
  'city',
  'customer.postcode',
  'zipCode',
  'customer.county',
  'country',
  'loyaltyId',
  'customer.passportNumber',
  'customer.specialRequest',
  'customer.invoiceRequested',
  'customer.companyName',
  'customer.vatNumber',
  'vehicle.make',
  'vehicle.model',
  'vehicle.colour',
  'journeyDetails.outboundFlightNumber',
  'journeyDetails.outboundFlightTime',
  'journeyDetails.returnFlightNumber',
  'journeyDetails.returnFlightTime',
  'journeyDetails.returningFrom',
  'title',
];

/**
 *  Sort the fields as per the expected ordered array and only return mandatory fields.
 *  NOTE: filter InfoFieldType.COUNTRY as a hack for ParkAero ( we set it manual on submit )
 * @param fields
 */
export const getParkingFieldsToRender = (
  fields: BookingIntentField[] | ProductConsumerAttributeDto[],
  providerId?: string,
) => {
  const requiredFields = (fields as BookingIntentField[]).filter((field) => {
    if (
      providerId === 'PARK_AERO' &&
      (field.name === 'salutation' ||
        field.name === 'company' ||
        field.name === 'country' ||
        field.name === 'loyaltyId')
    ) {
      return true;
    }

    return (
      field.requirement === BookingIntentFieldRequirement.MANDATORY &&
      field.type !== BookingIntentFieldType.COUNTRY
    );
  });

  return requiredFields.sort((a, b) => {
    return expectedSortOrder.indexOf(a.name) - expectedSortOrder.indexOf(b.name);
  });
};

export const getParkingSalutationValidationRules = (field: BookingIntentField) => {
  if (field.name === 'salutation') {
    return { required: 'tip.validation.required' };
  }
  return {
    required:
      field.requirement === 'OPTIONAL' ? 'tip.validation.required' : 'tip.validation.optional',
  };
};
