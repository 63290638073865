/**
 * # Experience Search Page Hooks
 *
 *
 */
import {
  CorasAddItemMessage,
  ExperienceType,
  ExperiencesParams,
  TheaterTicket,
  TourTicket,
} from '@yiluhub/embed-sdk';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { paramConverters, yiluEnv } from 'utils';
import { setSrpSession } from 'utils/searchSession';

import routes from 'router/routes';

import { useUserProfileContext } from 'context';

import { getExperienceSearchPageQueryParams } from '../../utils/query-params';

/**
 * Retrieve data for the experience search page.
 */
export const useExperienceSearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userProfileState: {
      data: {
        consent: { statistics },
      },
    },
  } = useUserProfileContext();

  const experienceSearchPageQueryParams = useMemo(() => {
    return getExperienceSearchPageQueryParams(location);
  }, [location]);

  const corasIframeParams = useMemo(() => {
    const yiluEnvVariables = yiluEnv.getVariables();

    const _props: ExperiencesParams = {
      lang: (experienceSearchPageQueryParams.locale as string) || 'en',
      airportCode: experienceSearchPageQueryParams.destinationIata,
      startDate: experienceSearchPageQueryParams.startDate,
      currency: experienceSearchPageQueryParams.currency
        ? experienceSearchPageQueryParams.currency.toUpperCase()
        : 'EUR',
      endDate: experienceSearchPageQueryParams.endDate,
      userId: uuidv4(),
      sessionId: uuidv4(),
      sandbox: yiluEnvVariables.YILU_API_PRODUCTION_ENVIRONMENT === 'false',
      tracking: statistics,
    };
    return _props;
  }, [experienceSearchPageQueryParams, statistics]);

  const handleCorasIframeMessage = useCallback(
    (message: CorasAddItemMessage) => {
      setSrpSession({
        lang: experienceSearchPageQueryParams.locale,
        destinationIata: experienceSearchPageQueryParams.destinationIata,
        startDate: experienceSearchPageQueryParams.startDate,
        currency: experienceSearchPageQueryParams.currency,
        endDate: experienceSearchPageQueryParams.endDate,
      });

      const isTheater = message.type === ExperienceType.THEATRE;

      let totalPriceFlat = 0;
      let totalTicketsNumber = 0;

      message.tickets.forEach((ticket) => {
        let priceFlat = 0;
        let ticketsNumber = 0;

        if (isTheater) {
          const theaterTicket = ticket as TheaterTicket;
          priceFlat = theaterTicket.basePrice + theaterTicket.bookingFee;
          ticketsNumber = 1;
        } else {
          const tourTicket = ticket as TourTicket;
          priceFlat = tourTicket.fullPrice * tourTicket.quantity;
          ticketsNumber = tourTicket.quantity;
        }

        totalPriceFlat += priceFlat;
        totalTicketsNumber += ticketsNumber;
      });

      const experienceTravellerInputPageQueryParams = {
        product: {
          ...message,
          totalPrice: totalPriceFlat / 100,
          totalTicketsNumber,
          userId: corasIframeParams.userId,
          type: 'EXPERIENCE',
          providerId: 'CORAS',
        },
      };

      navigate({
        pathname: routes.EXPERIENCES_TIP,
        search: paramConverters.getURLSearchQuery(experienceTravellerInputPageQueryParams),
      });
    },
    [experienceSearchPageQueryParams, corasIframeParams, navigate],
  );

  return {
    corasIframeParams,
    handleCorasIframeMessage,
  };
};
