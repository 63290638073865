import { SearchItem } from '@yiluhub/yilu-amp-types';
import { AMP_VERTICALS } from 'applicationConstants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';
import { getVariables } from 'utils/yiluEnv';
import { getCurrentLanguage } from 'utils/yiluSdk';

import routes from 'router/routes';

import { TravellerInformationFormProps } from 'components/TravellerInformationForm';

import { getAirportTransferProductData } from 'modules/airport-transfer/utils/getAmpAirportTransferData';

const useReservationInformationFormProps = (
  currentProduct: SearchItem | undefined | null,
  reservationFormRef: React.RefObject<HTMLFormElement>,
  setIsReservationUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const locale = getCurrentLanguage() || 'en';
  const navigate = useNavigate();

  const travellerInformationFormProps = useMemo(() => {
    if (!currentProduct) {
      return null;
    }
    const yiluEnv = getVariables();
    const {
      id,
      catalogId,
      travellersCount,
      arrivalDateTime,
      departureDateTime,
      currency,
      price,
      serviceProvider,
      searchResultId,
      priceOptionId,
    } = getAirportTransferProductData(currentProduct);

    const _props: TravellerInformationFormProps<AMP_VERTICALS.AIRPORT_TRANSFERS> = {
      ref: reservationFormRef,
      type: AMP_VERTICALS.AIRPORT_TRANSFERS,
      shoppingCartItemRequestBody: {
        storeId: yiluEnv.YILU_AMP_STORE_ID,
        catalogId: catalogId,
        locale: locale,
        quantity: 1,
        selectedPriceId: priceOptionId,
        searchResultId: searchResultId,
      },
      createBookingIntentRequestBody: {
        product: {
          optionId: id,
          arrivalTime: arrivalDateTime,
          departureTime: departureDateTime,
          currencyCode: currency,
          price: price,
          numberOfPassengers: travellersCount || 1,
          providerId: id,
        },
        serviceProvider: serviceProvider,
      },
      onUpdateBookingIntentSuccess(data) {
        const paymentInformationPageQueryParams = {
          searchResultID: searchResultId,
          bookingIntentId: data.bookingIntentId,
          shoppingCartId: data.shoppingCartId,
        };
        navigate({
          pathname: routes.AIRPORT_TRANSFER_PIP,
          search: getURLSearchQuery(paymentInformationPageQueryParams),
        });
      },
      onInitBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentError(error: Error) {
        throw error;
      },
      onUpdateBookingIntentRequestStateChange(isFormLoading: boolean) {
        setIsReservationUpdateLoading(isFormLoading);
      },
    };
    return _props;
  }, [currentProduct, reservationFormRef, navigate, setIsReservationUpdateLoading, locale]);

  return travellerInformationFormProps;
};

export default useReservationInformationFormProps;
