import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  isPaymentInformationPage,
  isProductDetailsPage,
  isTravellerInformationPage,
} from '../../utils/headerUtils';
import './styles.scss';

const PageHeading = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const render = (header: string) => (
    <ElementsSDK.Typography variant="h1" className="page-heading-text">
      {header}
    </ElementsSDK.Typography>
  );

  switch (true) {
    case isProductDetailsPage(pathname):
      return render(t('Header - Details'));
    case isTravellerInformationPage(pathname):
      return render(t('Header - Personal Info'));
    case isPaymentInformationPage(pathname):
      return render(t('Header - Payment'));
    default:
      return null;
  }
};

export default PageHeading;
