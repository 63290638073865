import {
  CreatePaymentIntentResponse,
  GetPaymentOptionsResponse,
  Response,
} from '@yiluhub/network-sdk';
import {
  CreateStripeSetupIntentResponse,
  GetPaymentOptionsResponse as ampGetPaymentOptionsResponse,
} from '@yiluhub/yilu-amp-types';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { yiluEnv } from 'utils';
import { getRESTClient } from 'utils/yiluSdk';

export const usePaymentSetup = (
  input: {
    providerId: string;
    price: number;
    currency: string;
    shoppingCartId?: string;
    actualPrice?: number;
  },
  bookingIntentId: string,
  isAmpEnabled: boolean,
  onError: (error: Error) => unknown,
) => {
  const [paymentOptions, setPaymentOptions] = useState<GetPaymentOptionsResponse>();
  const [clientSecret, setClientSecret] = useState<CreatePaymentIntentResponse>();
  const [network, setNetwork] = useState({ isFetching: false, isFetched: false });
  useEffect(() => {
    if (!network.isFetched && !network.isFetching) {
      setNetwork({ isFetching: true, isFetched: false });
      const client = getRESTClient();
      const yiluEnvVariables = yiluEnv.getVariables();
      const paymentApis = [];
      if (isAmpEnabled) {
        paymentApis.push(
          axios.get<ampGetPaymentOptionsResponse>(
            `${yiluEnvVariables.YILU_AMP_BACKEND_URL}/payment/v1/payments/options?shoppingCartId=${input.shoppingCartId}`,
          ),
        );
        paymentApis.push(
          axios.post<CreateStripeSetupIntentResponse>(
            `${yiluEnvVariables.YILU_AMP_BACKEND_URL}/payment/v1/payments/stripe/setup-intents`,
            {
              metadata: {
                providerId: input.providerId.toUpperCase(),
                price: input.actualPrice || input.price,
                currency: input.currency,
                bookingIntentId,
              },
            },
          ),
        );
      } else {
        paymentApis.push(
          client.getPaymentOptions({
            serviceProvider: input.providerId.toUpperCase(),
          }),
        );
        paymentApis.push(
          client.createPaymentIntent({
            providerId: input.providerId.toUpperCase(),
            price: input.actualPrice || input.price,
            currency: input.currency,
            bookingIntentId,
          }),
        );
      }
      /**
       *   The upper case is for backwards compatibility. the endpoint wants to change them to
       *   uppercase, they are returning uppercase as well as accepting only uppercase so for now
       *   we are simply changing here, after a while when all clients are upgraded, we can
       *   remove this call as the backend would be returning only Uppercase and we can simply
       *   pass it along
       */
      Promise.all([...paymentApis])
        .then((results) => {
          const [paymentOptionsResponse, paymentIntentResponse] = results as
            | [Response<GetPaymentOptionsResponse>, Response<CreatePaymentIntentResponse>]
            | [
                AxiosResponse<GetPaymentOptionsResponse>,
                AxiosResponse<CreateStripeSetupIntentResponse>,
              ];
          setPaymentOptions(
            isAmpEnabled
              ? (paymentOptionsResponse as AxiosResponse<GetPaymentOptionsResponse>).data
              : (paymentOptionsResponse as Response<GetPaymentOptionsResponse>).body,
          );
          setClientSecret(
            isAmpEnabled
              ? (paymentIntentResponse as AxiosResponse<CreateStripeSetupIntentResponse>).data
              : (paymentIntentResponse as Response<CreatePaymentIntentResponse>).body,
          );
        })
        .catch((error) => {
          if (typeof onError === 'function') {
            onError(error);
          } else {
            console.error('Could not setup payment intent', error);
          }
        })
        .finally(() => setNetwork({ isFetching: false, isFetched: true }));
    }
  }, [network, bookingIntentId, input, onError, isAmpEnabled]);

  return {
    paymentOptions,
    clientSecret,
  };
};
