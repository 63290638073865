/**
 * # Yilu SDK
 *
 *
 */
import { yiluEnv } from './';
import { ClientConfig, RESTClient } from '@yiluhub/network-sdk';
import { UiUtilities } from '@yiluhub/ui-sdk-react';
import dayjs from 'dayjs';

import 'dayjs/locale/de';
import i18n from './i18n';

const yiluEnvVariables = yiluEnv.getVariables();
const yiluConfig = UiUtilities.getYiluConfig() as ClientConfig & { restClient: RESTClient };

/**
 * Configure the localization used in the application
 *
 * @param [nextLocale] - optional locale identifier to change to next
 */
export function configureLocale(nextLocale?: string) {
  const browserLanguage = window.navigator.language;
  const browserLocale = browserLanguage.split('-')[0];

  // specify global locale identifier
  yiluConfig.locale = nextLocale || browserLocale;

  dayjs.locale(yiluConfig.locale);

  // specify the translation identifier for the integration
  i18n.changeLanguage(yiluConfig.locale);
}

/**
 * Configure the network client which is used in the application
 */
export function configureNetworkClient(clientConfig?: ClientConfig) {
  yiluConfig.restClient = new RESTClient({
    token: yiluEnvVariables.YILU_API_TOKEN,
    sandbox: yiluEnvVariables.YILU_API_PRODUCTION_ENVIRONMENT !== 'true',
    locale: yiluConfig.locale,
    ...clientConfig,
  });

  // NOTE: refer to the previous/used staging environment rather than the internally referenced v2
  if (yiluConfig.restClient.config.sandbox) {
    yiluConfig.restClient.config.sandbox = false;
    yiluConfig.restClient.config.baseUrl = 'https://routing.staging.yiluhub.com';
  }
}

/**
 * Get a REST client for the Yilu API.
 *
 * @param [restClientConfig] - configuration for a RESTClient of the Yilu JavaScript Network SDK
 *
 * @returns a RESTClient for communicating with the Yilu API
 */
export function getRESTClient(restClientConfig?: ClientConfig) {
  if (!window.yilu.network) {
    window.yilu.network = {
      RESTClient,
    };
  }

  let restClient = yiluConfig.restClient;
  if (restClientConfig) {
    const network = window.yilu.network;
    restClient = new network!.RESTClient(restClientConfig);
  }

  if (!restClient) {
    const accessor = 'window.yilu.config.restClient';
    const error = new Error(
      `Missing RESTClient. Please specify a global instance through "${accessor}" or pass a client
      configuration object to the invoked element!`,
    );
    alert(error.message);
    throw error;
  }

  return restClient;
}

export const getCurrentLanguage = () => i18n.language.toLowerCase();
