import {
  BookingIntentField,
  BookingIntentFieldRequirement,
  BookingIntentFieldType,
} from '@yiluhub/network-sdk';
import { ElementsSDK, UiUtilities } from '@yiluhub/ui-sdk-react';
import { ProductConsumerAttributeDto } from '@yiluhub/yilu-amp-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';

import Header from '../components/Header';
import { ReservationInformationFormContentProps } from '../components/types';
import {
  countryISO3166Map,
  findCountryAlpha2OptionByCode,
  getSortedCountriesAlpha2Options,
} from '../utils/countries';
import {
  REQUIRED_FIELD_ERROR_MESSAGE_KEY,
  getValidationRules,
  isLoyaltyIdValid,
} from '../utils/fieldValidation';
import { birthdayAutoFormatter } from './birthdayAutoFormatter';
import './styles.scss';

interface GroupedObject<T> {
  [key: number]: T[];
}

function groupBy<T>(arr: T[], keyGetter: (item: T) => number): GroupedObject<T> {
  return arr.reduce((result, item) => {
    const key = keyGetter(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {} as GroupedObject<T>);
}

enum SALUTATION_OPTION_VALUE {
  MR = 'mr',
  MRS = 'mrs',
}

export enum TRAVELLER_INFORMATION_FORM_FIELDS {
  TITLE = 'title',
  LOYALTY_ID = 'loyaltyId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  NATIONALITY = 'nationality',
  BIRTH_DATE = 'birthdate',
  EMAIL = 'email',
}

// eslint-disable-next-line max-len
const AirportTransferReservationInformationForm: React.FC<
  ReservationInformationFormContentProps
> = ({ fields, control, trigger, setValue, isSBBCarrier }) => {
  const { t } = useTranslation();
  const { locale } = UiUtilities.getYiluConfig();

  const [invalidCountryErrorMessage, setInvalidCountryErrorMessage] = useState<{
    [x: string]: string;
  }>({});

  const salutationSelectOptions: ElementsSDK.SelectOption[] = [
    {
      value: SALUTATION_OPTION_VALUE.MR,
      label: t('tip.airportTransfer.reservation.form.field.title.option.mr'),
    },
    {
      value: SALUTATION_OPTION_VALUE.MRS,
      label: t('tip.airportTransfer.reservation.form.field.title.option.mrs'),
    },
  ];

  const getDefaultSalutationValue = (fieldName: string): ElementsSDK.SelectOption | undefined => {
    const savedItem = sessionStorage.getItem(fieldName);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && salutationSelectOptions[0].value === parsedSavedItem) {
      return salutationSelectOptions[0];
    } else if (parsedSavedItem && salutationSelectOptions[1].value === parsedSavedItem) {
      return salutationSelectOptions[1];
    }
    return undefined;
  };

  const getSessionStorageValueForNationality = (fieldName: string) => {
    const savedItem = sessionStorage.getItem(fieldName);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;

    if ((parsedSavedItem === 'CH' || parsedSavedItem === 'LI') && isSBBCarrier) {
      if (!invalidCountryErrorMessage[parsedSavedItem]) {
        setInvalidCountryErrorMessage({
          ...invalidCountryErrorMessage,
          [parsedSavedItem]: 'tip.validation.invalid_country',
        });
      }
      return t('tip.validation.invalid_country');
    }
  };

  const getAndSetSessionStorageValueInitially = (key: string): string => {
    const savedItem = sessionStorage.getItem(key);
    const parsedSavedItem = savedItem ? JSON.parse(savedItem) : undefined;
    if (parsedSavedItem && parsedSavedItem !== '') {
      setValue(key, parsedSavedItem);
    }
    return parsedSavedItem;
  };

  const getNationalityValidation = (value?: string | undefined): string | undefined => {
    if (!value) return undefined;
    if ((value === 'CH' || value === 'LI') && isSBBCarrier) {
      return t('tip.validation.invalid_country');
    }
    return undefined;
  };

  //@ts-ignore
  const fieldsToRender = fields.filter((field) => {
    return (
      field.requirement === BookingIntentFieldRequirement.MANDATORY || field.name === 'loyaltyId_1'
    );
  });

  type fieldType = {
    field: BookingIntentField | ProductConsumerAttributeDto;
    parsedName: string;
    key: number;
  };

  const fieldWithTypes: fieldType[] = fieldsToRender
    .map((field: any) => {
      const [name, key] = field?.name?.split('_');
      return {
        ...field,
        parsedName: name as string,
        key: parseInt(key) as number,
      };
    })
    .sort((x: any, y: any) => (x.key < y.key ? 1 : x.key > y.key ? -1 : 0));
  const groupByKey: Record<string, any> = groupBy(fieldWithTypes, ({ key }) => key);
  const getDefaultCountryValue = (
    fieldName: string,
    language: 'en' | 'de',
  ): ElementsSDK.SelectOption | undefined => {
    const savedItem = sessionStorage.getItem(fieldName);
    const countryCode = savedItem ? JSON.parse(savedItem) : undefined;
    if (countryCode) {
      const countryMap: countryISO3166Map | undefined = findCountryAlpha2OptionByCode(countryCode);
      return countryMap ? { value: countryCode, label: countryMap.country[language] } : undefined;
    }
    return undefined;
  };

  const envVariables = yiluEnv.getVariables();

  return (
    <>
      {Object.keys(groupByKey).map((key) => {
        return (
          <div className="yilu-AirportTransferReservationForm" key={key}>
            <Header
              heading={
                key === '1' || typeof key !== 'number'
                  ? t('tip.airportTransfer.reservation.form.heading.mainPassenger')
                  : t('tip.airportTransfer.reservation.form.heading.passengerCount', {
                      count: Number(key),
                    })
              }
              className="yilu-AirportTransferReservationForm__header"
            />
            {groupByKey[key].map((field: any) => {
              if (field.type === BookingIntentFieldType.PHONE_NUMBER) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({
                      field: { onChange, onBlur, name, ref },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.PhoneInputField
                          dataTestId="customer-phone"
                          label={t(field.parsedName)}
                          className="yilu-AirportTransferReservationForm__field"
                          onChange={(value) => {
                            onChange(value);
                            isTouched && trigger(name);
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          phoneRef={ref}
                          errorMessage={error?.message && t(error.message)}
                          defaultCountry={envVariables?.PHONE_COUNTRY_CODE || 'DE'}
                        />
                      );
                    }}
                  />
                );
              }
              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.TITLE) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({ field: { onChange, name }, fieldState: { error } }) => {
                      return (
                        <ElementsSDK.InputField.Select
                          dataTestId="salutation-select"
                          options={salutationSelectOptions}
                          defaultOption={getDefaultSalutationValue(name)}
                          label={t('tip.airportTransfer.reservation.form.field.title.label')}
                          placeholder={t(
                            'tip.airportTransfer.reservation.form.field.title.placeholder',
                          )}
                          className="yilu-AirportTransferReservationForm__field"
                          onSelect={(selectedValue: unknown) => {
                            sessionStorage.setItem(name, JSON.stringify(selectedValue));
                            setValue(selectedValue);
                            onChange(selectedValue);
                          }}
                          disabledTyping
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );
              }
              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.LOYALTY_ID) {
                return (
                  <Controller
                    key={field.name}
                    rules={{ validate: isLoyaltyIdValid }}
                    name={t(field.name)}
                    control={control}
                    render={({
                      field: { onChange, onBlur, name, ref, value },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.TextInput
                          value={value || getAndSetSessionStorageValueInitially(name)}
                          label={t('tip.reservation.form.field.loyaltyId')}
                          placeholder={t('tip.reservation.form.field.loyaltyId.placeholder')}
                          name={name}
                          className="yilu-AirportTransferReservationForm__field"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e);
                            isTouched && trigger(name);
                            setValue(e.target.value);
                            sessionStorage.setItem(name, JSON.stringify(e.target.value));
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          inputRef={ref}
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );
              }
              if (
                field.type === BookingIntentFieldType.EMAIL ||
                field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.EMAIL
              ) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({
                      field: { onChange, onBlur, name, value, ref },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.Select
                          isEmailDropdown
                          showDropdownIcon={false}
                          dataTestId="airport-transfer-reservation-email"
                          label={t(field.parsedName)}
                          name={name}
                          value={value || getAndSetSessionStorageValueInitially(name)}
                          className="yilu-AirportTransferReservationForm__field"
                          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e);
                            isTouched && trigger(name);
                            setValue(e.target.value);
                            sessionStorage.setItem(name, JSON.stringify(e.target.value));
                          }}
                          onSelect={(selectedValue: unknown) => {
                            onChange(selectedValue);
                            isTouched && trigger(name);
                            setValue(selectedValue);
                            sessionStorage.setItem(name, JSON.stringify(selectedValue));
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          inputRef={ref}
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );
              }

              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.FIRST_NAME) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({
                      field: { onChange, onBlur, name, ref, value },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.TextInput
                          value={value || getAndSetSessionStorageValueInitially(name)}
                          label={t(field.parsedName)}
                          name={name}
                          className="yilu-AirportTransferReservationForm__field"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e);
                            isTouched && trigger(name);
                            setValue(e.target.value);
                            sessionStorage.setItem(name, JSON.stringify(e.target.value));
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          inputRef={ref}
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );
              }

              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.LAST_NAME) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({
                      field: { onChange, onBlur, name, ref, value },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.TextInput
                          value={value || getAndSetSessionStorageValueInitially(name)}
                          label={t(field.parsedName)}
                          name={name}
                          className="yilu-AirportTransferReservationForm__field"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e);
                            isTouched && trigger(name);
                            setValue(e.target.value);
                            sessionStorage.setItem(name, JSON.stringify(e.target.value));
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          inputRef={ref}
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );
              }

              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.NATIONALITY) {
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    control={control}
                    rules={{
                      required: REQUIRED_FIELD_ERROR_MESSAGE_KEY,
                      validate: getNationalityValidation,
                    }}
                    render={({ field: { onChange, name, ref }, fieldState: { error } }) => {
                      return (
                        <ElementsSDK.InputField.Select
                          dataTestId="country-select"
                          defaultOption={getDefaultCountryValue(name, locale as 'en' | 'de')}
                          options={getSortedCountriesAlpha2Options(locale as 'en' | 'de')}
                          label={t(field.parsedName)}
                          className="yilu-AirportTransferReservationForm__field"
                          onSelect={(selectedValue: unknown) => {
                            onChange(selectedValue);
                            setValue(selectedValue);
                            if (
                              (selectedValue === 'CH' || selectedValue === 'LI') &&
                              isSBBCarrier
                            ) {
                              setInvalidCountryErrorMessage({
                                ...invalidCountryErrorMessage,
                                ...{ [field.name]: 'tip.validation.invalid_country' },
                              });
                            } else {
                              setInvalidCountryErrorMessage({
                                ...invalidCountryErrorMessage,
                                [field.name]: '',
                              });
                            }
                            sessionStorage.setItem(name, JSON.stringify(selectedValue));
                          }}
                          inputRef={ref}
                          errorMessage={
                            (error?.message && t(error.message)) ||
                            t(invalidCountryErrorMessage[field.name]) ||
                            getSessionStorageValueForNationality(field.name)
                          }
                        />
                      );
                    }}
                  />
                );
              }

              if (field.parsedName === TRAVELLER_INFORMATION_FORM_FIELDS.BIRTH_DATE)
                return (
                  <Controller
                    key={field.name}
                    name={field.name}
                    defaultValue={''}
                    control={control}
                    rules={getValidationRules(field)}
                    render={({
                      field: { onChange, onBlur, name, value, ref },
                      fieldState: { isTouched, error },
                    }) => {
                      return (
                        <ElementsSDK.InputField.TextInput
                          label={t(field.parsedName)}
                          placeholder={'DD/MM/YYYY'}
                          name={name}
                          className="yilu-AirportTransferReservationForm__field"
                          onChange={(e) => {
                            const value = (e.target as HTMLInputElement).value;
                            const formatedValue = birthdayAutoFormatter(value);
                            onChange(formatedValue);
                            isTouched && trigger(name);
                            sessionStorage.setItem(name, JSON.stringify(formatedValue));
                          }}
                          onBlur={() => {
                            onBlur();
                            trigger(name);
                          }}
                          value={value || getAndSetSessionStorageValueInitially(name)}
                          inputRef={ref}
                          errorMessage={error?.message && t(error.message)}
                        />
                      );
                    }}
                  />
                );

              return (
                <Controller
                  key={field.name}
                  name={field.name}
                  control={control}
                  rules={getValidationRules(field)}
                  render={({
                    field: { onChange, onBlur, name, value, ref },
                    fieldState: { isTouched, error },
                  }) => {
                    return (
                      <ElementsSDK.InputField.TextInput
                        label={t(field.name)}
                        name={name}
                        value={value || getAndSetSessionStorageValueInitially(name)}
                        className="yilu-AirportTransferReservationForm__field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e);
                          isTouched && trigger(name);
                          setValue(e.target.value);
                          sessionStorage.setItem(name, JSON.stringify(e.target.value));
                        }}
                        onBlur={() => {
                          onBlur();
                          trigger(name);
                        }}
                        inputRef={ref}
                        errorMessage={error?.message && t(error.message)}
                      />
                    );
                  }}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default AirportTransferReservationInformationForm;
