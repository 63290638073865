import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { yiluEnv } from 'utils/index';
import { getCurrentLanguage } from 'utils/yiluSdk';

export type DownloadPDFButtonProps = {
  className?: string;
  bookingId: string;
};

const DownloadPDFButton: React.FC<DownloadPDFButtonProps> = ({ bookingId }) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (isDownloading) return;
    setIsDownloading(true);
    const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();

    const axiosClient = () => {
      return axios.get(`${YILU_AMP_BACKEND_URL}/bookings/${bookingId}/pdf`, {
        responseType: 'blob',
        headers: {
          'Accept-Language': currentLanguage,
        },
        data: {},
        params: { storeId: YILU_AMP_STORE_ID },
      });
    };

    axiosClient()
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data);
        link.download = `booking-${bookingId}.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [currentLanguage, bookingId, isDownloading]);

  return (
    <ElementsSDK.Button
      className="yilu-reservation-bcp__cta"
      Icon={ElementsSDK.Icon.Download}
      onClick={handleButtonClick}
    >
      {t('bcp.downloadPDF')}
    </ElementsSDK.Button>
  );
};

export default DownloadPDFButton;
