/**
 * # Use Current Product.
 *
 *
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadAmpCurrentProduct,
  selectCurrentProduct,
  selectCurrentProductError,
  selectIsCurrentProductLoading,
} from 'store/features/CurrentProduct';

/**
 * Use current product.
 *
 * @param searchResultId - search result identifier
 */
export function useCurrentProduct(searchResultId?: string) {
  const dispatch = useDispatch();
  const selectedCurrentProduct = useSelector(selectCurrentProduct);

  const currentProduct =
    selectedCurrentProduct && selectedCurrentProduct.id === searchResultId
      ? selectedCurrentProduct
      : null;
  const isCurrentProductLoading = useSelector(selectIsCurrentProductLoading);
  const currentProductError = useSelector(selectCurrentProductError);

  useEffect(() => {
    if (currentProduct || !searchResultId) {
      return;
    }

    dispatch(loadAmpCurrentProduct(searchResultId));
  }, [currentProduct, searchResultId, dispatch]);

  return {
    currentProduct,
    isCurrentProductLoading,
    currentProductError,
  };
}
